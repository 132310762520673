

<div class="d-sm-block d-md-block d-lg-none">
  <div *ngIf="isAuthorized != null">
    <!--<div class="row" [class.p40]="!isCabinet">
      <div class="col mb-4">
        <p class="course-text-mobile" [class.pr-5]="!isCabinet">
          {{'PRINCE2®_Foundation' | translate}}
        </p>
      </div>
    </div>-->
    <div class="row mb-2">
      <div class="col">
        <p class="mb-0 ptext-mobile">
          {{'PRINCE2®_is_a_best_practice' | translate}}
        </p>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <p class="mb-0 ptext-mobile">
          {{'By_the_end_of_the_course' | translate}}
        </p>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <p class="mb-0 ptext-mobile">
          {{'The_PRINCE2®_Foundation_Online' | translate}}
        </p>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <p class="mb-0 ptext-mobile">
          <strong>{{'PRINCE2®_Foundation_Online_Training_course' | translate}}</strong> {{'Has_a_duration' | translate}} <strong>{{'35_hours' | translate}}</strong> {{'however_you_will' | translate}} <strong>{{'an_access_to_the_course' | translate}}</strong> {{'For' |translate}} <strong>{{'2_months' | translate}}</strong> {{'from_the_day_of_booking' | translate}}.
        </p>
      </div>
    </div>
  </div>

  <div class="row mc-7" *ngIf="isAuthorized === true">
    <div class="col ">
      <p class="mb-3 course-text-mobile-bold">{{'Course_plan' | translate}}</p>
    </div>
    <div class="row mc-7">
      <div class="col" style="padding-left:30px;padding-right:30px;">
        <p class="mb-2 ptext-mobile">{{'The PRINCE2_Foundation_online_course_includes_the_following_topics' | translate}}</p>
        <ul class="" style="padding-left: 20px;">
          <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
            <strong>{{'PRINCE2_Principles' | translate}}</strong> {{'PRINCE2_project_management_follows_a_set_of_seven_principles' | translate}}
          </li>
          <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
            <strong>{{'PRINCE2_Processes' | translate}}</strong> {{'The_seven_PRINCE2_processes' | translate}}
          </li>
          <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
            <strong>{{'PRINCE2_Themes' | translate}}</strong> {{'These_seven_themes_need_to' | translate}}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row mc-7 flex-column" *ngIf="isAuthorized !== null">
    <div class="col ">
      <p class=" mb-3 course-text-mobile-bold">{{'The_Course_Includes_1' | translate}}</p>
      <p class="mb-2 ptext-mobile">{{'The_PRINCE2®_Foundation_Online_Training_course_has_the_following' | translate}}</p>
      <ul class="" style="padding-left: 20px;">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          <strong>{{'24/7_access_for_2_months' | translate}}</strong> {{'to_the_online_training_course_enabling_you_to_study_anytime_anywhere' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Interactive_and' | translate}}<strong> {{'engaging_online_training_modules' | translate}}</strong>, {{'designed_based_on_time_tested_methodologies_for_effective_learning' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Easy_to_use_online_interface' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Personal_Performance_Dashboard' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">{{'Training_Modules' | translate}} /<strong> {{'Videos' | translate}}</strong></li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile"><strong>{{'Module_Assessments' | translate}}</strong> {{'to_test_your_subject_knowledge_at_each_stage' | translate}}</li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile"><strong>{{'Mock_Examination' | translate}}</strong> - {{'timed_and_scored_like' | translate}}</li>
        <!--<li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          <strong>{{'Certification_Exam_1' | translate}}<span style="color:black">*</span></strong>
        </li>-->
        <li style="padding-top: 3px; padding-bottom: 3px; list-style: none; color: red; font-weight: 400; " class="ptext-mobile">
          <p style="">{{'izmenenia' | translate}}</p>
        </li>
      </ul>
    </div>
    <div class="col ">
      <p class="course-text-mobile-bold mb-3">{{'Programm_course_include' | translate}}</p>

      <p class="mb-2 ptext-mobile">{{'Foundation_Online_Training_course_consists_of_18_thematic_sections' | translate}}</p>
      <ul class="" style="padding-left: 20px;">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_1' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_2' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_3' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_4' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_5' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_6' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_7' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_8' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_9' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_10' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_11' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_12' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_13' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_14' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_15' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_16' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_17' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'text_18' | translate}}
        </li>
      </ul>
      <p style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
        {{'text_19' | translate}}
      </p>
      <p style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
        {{'text_20' | translate}}
      </p>
      <p style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
        {{'text_21' | translate}}
      </p>
      <p style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
        {{'text_22' | translate}}
      </p>
    </div>
  </div>
  <div class="row mt-3 " *ngIf="isAuthorized === false">
    <div class="col ">
      <p class=" mb-3 course-text-mobile-bold">{{'Who_is_this_course_for_1' | translate}}</p>
      <p class="mb-2 ptext-mobile">
        {{'The_PRINCE2®_Foundation_Online_Training_course_is_intended_for_those_looking_to_work' | translate}}
      </p>
      <p class="mb-2 ptext-mobile">
        {{'The_PRINCE2®_Foundation_Online_Training_course_is_specifically_intended' | translate}}
      </p>
      <p class="mb-2 mt-3 ptext-mobile">
        {{'This_Certification_is_most_suited_for' | translate}}
      </p>
      <ul class="" style="padding-left: 20px;margin-bottom: 30px;
">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Existing_project_managers' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Any_professional looking_to_become_a_project_manager' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Project_Executives_or_Engineers' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Associate_or_Assistant_Managers_that_have_a_role_in_working_on_projects' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Software_Developers' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">{{'Team_Leaders_or_Managers' | translate}}</li>
      </ul>
      <p class="mb-2 ptext-mobile">
        {{'By_choosing' | translate}} <strong>{{'online_learning' | translate}}</strong>{{'instead_of_a_classroom_based' | translate}}<strong>{{'saving_you_valuable_time' | translate}}</strong>{{'and_money_It_is_also_ideal' | translate}}<strong>{{'3_months_access' | translate}}</strong>{{'meaning_it_can_be_picked_up' | translate}}<strong>{{'can_be_studied_from_home' | translate}}</strong>{{'meaning_you_can_study_in' | translate}}
      </p>
    </div>
  </div>
  <div class="row" *ngIf="isAuthorized === false">
    <div class="col">
      <p class="mb-3 course-text-mobile-bold">{{'Benefits' | translate}}</p>
      <p class="mb-2 ptext-mobile">
        {{'As_the_standard_method_for_project_management' | translate}}
      </p>
      <ul class="" style="padding-left: 20px;margin-bottom: 30px;">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Effective_communication_channels' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'An_organized_timescale_to_the_project' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'A_method_of_sharing_lessons_learned' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'An_increase_in_competency_of_staff' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Progress_reviews_against' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext-mobile">
          {{'Flexible_decision_points' | translate}}
        </li>
      </ul>
    </div>
  </div>
</div>
  



<div class="d-none d-lg-inline">
  <div *ngIf="isAuthorized != null">
    <!--<div class="row">
      <div class="col mb-4">
        <p class="course-text">
          {{'PRINCE2®_Foundation' | translate}}
        </p>
      </div>
    </div>-->
    <div class="row mb-2">
      <div class="col">
        <p class="mb-0 ptext">
          {{'PRINCE2®_is_a_best_practice' | translate}}
        </p>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <p class="mb-0 ptext">
          {{'By_the_end_of_the_course' | translate}}
        </p>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <p class="mb-0 ptext">
          {{'The_PRINCE2®_Foundation_Online' | translate}}
        </p>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <p class="mb-0 ptext">
          <strong>{{'PRINCE2®_Foundation_Online_Training_course' | translate}}</strong> {{'Has_a_duration' | translate}} <strong>{{'35_hours' | translate}}</strong> {{'however_you_will' | translate}} <strong>{{'an_access_to_the_course' | translate}}</strong> {{'For' |translate}} <strong>{{'2_months' | translate}}</strong> {{'from_the_day_of_booking' | translate}}.
        </p>
      </div>
    </div>
  </div>
  <div class="row mc-7" *ngIf="isAuthorized === true">
    <div class="col ">
      <p class="course-text-700 mb-3">{{'Course_plan' | translate}}</p>
    </div>
    <div class="row mc-7">
      <div class="col" style="padding-left:30px;">
        <p class="mb-2 ptext">{{'The PRINCE2_Foundation_online_course_includes_the_following_topics' | translate}}</p>
        <ul class="" style="padding-left: 20px;">
          <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
            <strong>{{'PRINCE2_Principles' | translate}}</strong>{{'PRINCE2_project_management_follows_a_set_of_seven_principles' | translate}}
          </li>
          <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
            <strong>{{'PRINCE2_Processes' | translate}}</strong>{{'The_seven_PRINCE2_processes' | translate}}
          </li>
          <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
            <strong>{{'PRINCE2_Themes' | translate}}</strong> {{'These_seven_themes_need_to' | translate}}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row mc-7 flex-column" *ngIf="isAuthorized !== null">
    <div class="col ">
      <p class="course-text-700 mb-3">{{'The_Course_Includes_1' | translate}}</p>

      <p class="mb-2 ptext">{{'The_PRINCE2®_Foundation_Online_Training_course_has_the_following' | translate}}</p>
      <ul class="" style="padding-left: 20px;">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          <strong>{{'24/7_access_for_2_months' | translate}}</strong> {{'to_the_online_training_course_enabling_you_to_study_anytime_anywhere' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Interactive_and' | translate}}<strong> {{'engaging_online_training_modules' | translate}}</strong>, {{'designed_based_on_time_tested_methodologies_for_effective_learning' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Easy_to_use_online_interface' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Personal_Performance_Dashboard' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">{{'Training_Modules' | translate}} /<strong> {{'Videos' | translate}}</strong></li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext"><strong>{{'Module_Assessments' | translate}}</strong> {{'to_test_your_subject_knowledge_at_each_stage' | translate}}</li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext"><strong>{{'Mock_Examination' | translate}}</strong> - {{'timed_and_scored_like' | translate}}</li>

        <!--<li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          <strong>{{'Certification_Exam_1' | translate}} <span style="color:black">*</span></strong>
        </li>-->
        <li style="padding-top: 3px; padding-bottom: 3px; list-style: none; color: red;font-weight:400; " class="ptext">
          <p>{{'izmenenia' | translate}}</p>
        </li>
      </ul>
    </div>
    <div class="col ">
      <p class="course-text-700 mb-3">{{'Programm_course_include' | translate}}</p>

      <p class="mb-2 ptext">{{'Foundation_Online_Training_course_consists_of_18_thematic_sections' | translate}}</p>
      <ul class="" style="padding-left: 20px;">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_1' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_2' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_3' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_4' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_5' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_6' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_7' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_8' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_9' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_10' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_11' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_12' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_13' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_14' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_15' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_16' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_17' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'text_18' | translate}}
        </li>
      </ul>
      <p style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        {{'text_19' | translate}}
      </p>
      <p style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        {{'text_20' | translate}}
      </p>
      <p style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        {{'text_21' | translate}}
      </p>
      <p style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        {{'text_22' | translate}}
      </p>
    </div>
  </div>
  <div class="row mt-3 " *ngIf="isAuthorized === false">
    <div class="col ">
      <p class="course-text-700 mb-3">{{'Who_is_this_course_for_1' | translate}}</p>
      <p class="mb-2 ptext">
        {{'The_PRINCE2®_Foundation_Online_Training_course_is_intended_for_those_looking_to_work' | translate}}
      </p>
      <p class="mb-2 ptext">
        {{'The_PRINCE2®_Foundation_Online_Training_course_is_specifically_intended' | translate}}
      </p>
      <p class="mb-2 mt-3 ptext">
        {{'This_Certification_is_most_suited_for' | translate}}
      </p>
      <ul class="" style="padding-left: 20px;margin-bottom: 30px;
">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Existing_project_managers' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Any_professional looking_to_become_a_project_manager' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Project_Executives_or_Engineers' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Associate_or_Assistant_Managers_that_have_a_role_in_working_on_projects' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Software_Developers' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">{{'Team_Leaders_or_Managers' | translate}}</li>
      </ul>
      <p class="mb-2 ptext">
        {{'By_choosing' | translate}} <strong>{{'online_learning' | translate}}</strong>{{'instead_of_a_classroom_based' | translate}}<strong>{{'saving_you_valuable_time' | translate}}</strong>{{'and_money_It_is_also_ideal' | translate}}<strong>{{'3_months_access' | translate}}</strong>{{'meaning_it_can_be_picked_up' | translate}}<strong>{{'can_be_studied_from_home' | translate}}</strong>{{'meaning_you_can_study_in' | translate}}
      </p>
    </div>
  </div>
  <div class="row" *ngIf="isAuthorized === false">
    <div class="col">
      <p class="course-text-700 mb-3">{{'Benefits' | translate}}</p>
      <p class="mb-2 ptext">
        {{'As_the_standard_method_for_project_management' | translate}}
      </p>
      <ul class="" style="padding-left: 20px;margin-bottom: 30px;">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Effective_communication_channels' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'An_organized_timescale_to_the_project' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'A_method_of_sharing_lessons_learned' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'An_increase_in_competency_of_staff' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Progress_reviews_against' | translate}}
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          {{'Flexible_decision_points' | translate}}
        </li>
      </ul>
    </div>
  </div>
</div>
