import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PreviousRouteService } from '../app/previous-route-service.service';
import { AuthorizeService } from './authorize.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {
    constructor(
        private auth: AngularFireAuth, private router: Router, private previousRouteService: PreviousRouteService) {
    }
    canActivate(
        _next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.auth.authState.pipe(
            map(user => {
                if (user) {
                    return true;
                } else {
                    this.previousRouteService.setPreviousUrl(state.url);
                    this.router.navigate(['authentication']);
                    return false;
                }
            })
        );
    }
}
