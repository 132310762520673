import { CityModel } from "./CityModel";
import { CountryModel } from "./CountryModel";
import { FieldOfActivityModel } from "./FieldOfActivityModel";


export class ContactModel {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    mobilePhone: string;
    email: string;
    description: string;
    cityId: string;
    city: CityModel;
    country: CountryModel;
    countryId: string;
    fieldOfActivityId: string;
    fieldOfActivity: FieldOfActivityModel;
    isNotifyByEmail: boolean;
}