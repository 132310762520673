<div class="rightimage">
    <img src="/images/imageGirl.svg" alt="imageGirl" />
</div>

<app-courses-toolbar></app-courses-toolbar>



<div class="" id="" style="padding-right: 28.80vw;
    padding-left: 7.70vw;">
  <div class="row" style="
    margin-top: 150px;
    margin-bottom: 20px;
">
    <div class="col">
      <div class="mc-1 mb-3 mat-display-4">PRINCE2 PRACTITIONER</div>
    </div>
  </div>


  <shared-practitioner-ru></shared-practitioner-ru>


  <div class="row" style="margin-bottom:17.22vh;">
    <div class="col">
      <button class="buttonstyle px-0" mat-button color="accent" (click)="buyCourse('1AFE0911-7E51-EB11-80E1-00155D784603')" style="width: 15vw;font-size: 1.125em;">Пройти обучение</button>
    </div>
  </div>
</div>




<app-courses-footer></app-courses-footer>
