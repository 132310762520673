


<div class="d-sm-inline d-md-inline d-lg-none">
  <div class="align-items-center">
    <div id="recaptcha-container"></div>
    <div *ngIf="!sent" [@fadeInOut]>
      <div class="title-header-table title-header-mobile text-center">{{'login' | translate}}</div>
      <div class="add-phone-table add-phone-mobile">{{'enter_phone' | translate}}</div>
      <form #formData='ngForm' (ngSubmit)="onSubmit(formData.value)">
        <div *ngIf="currentCountry==='Другая'" class="w-100" appearance="outline">
          <input type="tel" prefix="{{prefixOtherCountry}}"  [showMaskTyped]="true"
                 placeHolderCharacter="_" name="phone_number" #pnumber placeholder="+"
                 class="phone-input-table phone-input-mobile w-100" [(ngModel)]="phone_number"
                 required autofocus />
        </div>
         <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Россия'" class="w-100" appearance="outline">
          <input type="tel" prefix="{{prefix}}" mask=" 000 000 00 00" [showMaskTyped]="true"
                 placeHolderCharacter="_" name="phone_number" #pnumber
                 class="phone-input-table phone-input-mobile w-100" [(ngModel)]="phone_number" placeholder="+7 (___) ___ __ __"
                 required autofocus />
        </div>
        <button type="submit" class="b-secondary-table b-secondary-mobile b-submit1 w-100 b-seconadry-text-mobile" style="" [disabled]="formData.invalid || sent || !agreed">
          <span class="b-seconadry-text " *ngIf="sent">{{'wait' | translate}}</span>
          <span class="b-seconadry-text " *ngIf="!sent">{{'get_code' | translate}}</span>
        </button>
        <div *ngIf="currentCountry==='Россия'">
          <mat-checkbox [disabled]="sent" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" [color]="color" class="license-checkbox check-box-text-table check-box-text-mobile" style="">{{'agree' | translate}} <a routerLink="/oferta" href="" target="_blank">{{'public_offer' | translate}}</a></mat-checkbox>
        </div>
        <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Другая'">
          <mat-checkbox [disabled]="sent" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" [color]="color" class="license-checkbox check-box-text-table check-box-text-mobile" style="">{{'agree' | translate}} <a routerLink="/ofertakz" href="" target="_blank">{{'public_offer' | translate}}</a></mat-checkbox>

        </div>
        <div class="mt-2 error-text-mobile error-text-table error-color" *ngIf="isError">
          {{errorMessage}}
        </div>
      </form>
    </div>

    <div *ngIf="sent" [@fadeInOut]>
      <div class="pb-3 title-header-table title-header-mobile text-center">{{'enter_code' | translate}}</div>
      <span class="add-phone-table add-phone-mobile">{{'contact_phone' | translate}}</span><b><span class="ml-1 number-table number-mobile">{{masked_phone}}</span></b>
                                                                                           <form #verifyData='ngForm' (ngSubmit)="verifyCode(verifyData.value)">
                                                                                             <div class="w-100" appearance="outline">
                                                                                               <input type="tel" mask="0 0 0 0 0 0" [showMaskTyped]="true"
                                                                                                      placeHolderCharacter="_" name="code"
                                                                                                      class="phone-input-table phone-input-mobile" [(ngModel)]="code" placeholder="_ _ _ _ _ _"
                                                                                                      required autofocus />
                                                                                               <br />
                                                                                               <div class="error-text-mobile error-text-table" *ngIf="isError">
                                                                                                 {{errorMessage}}
                                                                                               </div>
                                                                                             </div>
                                                                                             <br />
                                                                                             <div class="wait-label-table wait-label-mobile">{{'repeat_send_available' | translate}} <countdown #cd1 [config]="{ leftTime: 60, format: 'mm:ss'}" (event)="handleEvent($event)"></countdown></div>
                                                                                             <button type="submit" class="b-secondary-table b-secondary-mobile b-submit1 w-100 b-seconadry-text-mobile" [disabled]="verifyData.invalid && !repeatSending">
                                                                                               <span class="b-seconadry-text" *ngIf="verifyData.invalid">{{'repeat_code' | translate}}</span>
                                                                                               <span class="b-seconadry-text" *ngIf="!verifyData.invalid">{{'confirm' | translate}}</span>
                                                                                             </button>
                                                                                             <div *ngIf="currentCountry==='KZ'">
                                                                                               <mat-checkbox [disabled]="sent" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" [color]="color" class="license-checkbox check-box-text-table check-box-text-mobile" style="">{{'agree' | translate}} <a routerLink="/ofertakz" href="" target="_blank">{{'public_offer' | translate}}</a></mat-checkbox>
                                                                                             </div>
                                                                                             <div *ngIf="currentCountry==='RU'">
                                                                                               <mat-checkbox [disabled]="sent" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" [color]="color" class="license-checkbox check-box-text-table check-box-text-mobile" style="">{{'agree' | translate}} <a routerLink="/oferta" href="" target="_blank">{{'public_offer' | translate}}</a></mat-checkbox>
                                                                                             </div>
                                                                                           </form>
    </div>

    <mat-card class="login-card ggl-card" (click)="googleLogin()">
      <div fxLayout="row"
           fxFlexFill
           fxLayoutAlign="center center">
        <img class="ggl-image-table ggl-image-mobile" src="/images/google-icon.svg" alt="googleIcon" />
        <span class="ggl-text-table ggl-text-mobile">{{'sign_up_google' | translate}}</span>
      </div>
    </mat-card>
  </div>
</div>


<div class="d-none d-lg-inline">
  <div class="align-items-center">
    <div id="recaptcha-container"></div>
    <div *ngIf="!sent" [@fadeInOut]>
      <div class="mat-headline text-center">{{'login' | translate}}</div>
      <div class="phone-label">{{'enter_phone' | translate}}</div>
      <form #formData='ngForm' (ngSubmit)="onSubmit(formData.value)">
        <div *ngIf="currentCountry==='Другая'" class="w-100" appearance="outline">
          <input type="tel" prefix="{{prefixOtherCountry}}"  [showMaskTyped]="true"
                 placeHolderCharacter="_" name="phone_number" #pnumber
                 class="phone-input w-100" [(ngModel)]="phone_number" placeholder="+"
                 required autofocus />
        </div>
        
          <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Россия'" class="w-100" appearance="outline">
          <input type="tel" prefix="{{prefix}}" mask=" 000 000 00 00" [showMaskTyped]="true"
             placeHolderCharacter="_" name="phone_number" #pnumber
             class="phone-input w-100" [(ngModel)]="phone_number" placeholder="+7 (___) ___ __ __"
             required autofocus />
          </div>
          <button type="submit" class="b-secondary b-submit1 w-100" [disabled]="formData.invalid || sent || !agreed">
            <span *ngIf="sent">{{'wait' | translate}}</span>
            <span *ngIf="!sent">{{'get_code' | translate}}</span>
          </button>
          <div *ngIf="currentCountry==='Россия'">
            <mat-checkbox [disabled]="sent" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" [color]="color" class="license-checkbox mat-body-2">{{'agree' | translate}} <a routerLink="/oferta" class="ofertaCssLogin">{{'public_offer' | translate}}</a></mat-checkbox>
          </div>
          <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Другая'">
            <mat-checkbox [disabled]="sent" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" [color]="color" class="license-checkbox mat-body-2">{{'agree' | translate}} <a routerLink="/ofertakz" class="ofertaCssLogin">{{'public_offer' | translate}}</a></mat-checkbox>
          </div>
          <div class="mt-2 mat-body-2 error-color" *ngIf="isError">
            {{errorMessage}}
          </div>
      </form>
    </div>

    <div *ngIf="sent" [@fadeInOut]>
      <div class="mat-headline text-center">{{'enter_code' | translate}}</div>
      <span class="phone-label">{{'contact_phone' | translate}}</span><b><span class="ml-1">{{masked_phone}}</span></b>
                                                                      <form #verifyData='ngForm' (ngSubmit)="verifyCode(verifyData.value)">
                                                                        <div class="w-100" appearance="outline">
                                                                          <input type="tel" mask="0 0 0 0 0 0" [showMaskTyped]="true"
                                                                                 placeHolderCharacter="_" name="code"
                                                                                 class="code-input" [(ngModel)]="code" placeholder="_ _ _ _ _ _"
                                                                                 required autofocus />
                                                                          <br />
                                                                          <div class="mat-body-2" *ngIf="isError">
                                                                            {{errorMessage}}
                                                                          </div>
                                                                        </div>
                                                                        <br />
                                                                        <div class="wait-label">{{'repeat_send_available' | translate}} <countdown #cd1 [config]="{ leftTime: 60, format: 'mm:ss'}" (event)="handleEvent($event)"></countdown></div>
                                                                        <button type="submit" class="b-secondary b-submit2 w-100" [disabled]="verifyData.invalid && !repeatSending">
                                                                          <span *ngIf="verifyData.invalid">{{'repeat_code' | translate}}</span>
                                                                          <span *ngIf="!verifyData.invalid">{{'confirm' | translate}}</span>
                                                                        </button>
                                                                        <div *ngIf="currentCountry==='KZ'">
                                                                          <mat-checkbox [disabled]="sent" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" [color]="color" class="license-checkbox mat-body-2">{{'agree' | translate}} <a routerLink="/ofertakz" class="ofertaCssLogin">{{'public_offer' | translate}}</a></mat-checkbox>
                                                                        </div>
                                                                        <div *ngIf="currentCountry==='RU'">
                                                                          <mat-checkbox [disabled]="sent" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" [color]="color" class="license-checkbox mat-body-2">{{'agree' | translate}} <a routerLink="/oferta" class="ofertaCssLogin">{{'public_offer' | translate}}</a></mat-checkbox>
                                                                        </div>

                                                                      </form>
    </div>

    <mat-card class="login-card ggl-card" (click)="googleLogin()">
      <div fxLayout="row"
           fxFlexFill
           fxLayoutAlign="center center">
        <img class="ggl-image" src="/images/google-icon.svg" alt="googleIcon" />
        <span>{{'sign_up_google' | translate}}</span>
      </div>
    </mat-card>
  </div>
</div>


<!--<div class="align-items-center">
    <div id="recaptcha-container"></div>
    <div *ngIf="!sent" [@fadeInOut]>
        <div class="mat-headline text-center">{{'login' | translate}}</div>
        <div class="phone-label">{{'enter_phone' | translate}}</div>
        <form #formData='ngForm' (ngSubmit)="onSubmit(formData.value)">
            <div class="w-100" appearance="outline">
                <input type="tel" prefix="{{prefix}}" mask=" 000 000 00 00" [showMaskTyped]="true"
                       placeHolderCharacter="_" name="phone_number" #pnumber
                       class="phone-input w-100" [(ngModel)]="phone_number" placeholder="+7 (___) ___ __ __"
                       required autofocus />
            </div>
            <button type="submit" class="b-secondary b-submit1 w-100" [disabled]="formData.invalid || sent || !agreed">
                <span *ngIf="sent">{{'wait' | translate}}</span>
                <span *ngIf="!sent">{{'get_code' | translate}}</span>
            </button>
            <mat-checkbox [disabled]="sent" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" [color]="color" class="license-checkbox mat-body-2">{{'agree' | translate}} <a routerLink="/oferta" href="" target="_blank" >{{'public_offer' | translate}}</a></mat-checkbox>
            <div class="mt-2 mat-body-2 error-color" *ngIf="isError">
                {{errorMessage}}
            </div>
        </form>
    </div>

    <div *ngIf="sent" [@fadeInOut]>
        <div class="mat-headline text-center">{{'enter_code' | translate}}</div>
        <span class="phone-label">{{'contact_phone' | translate}}</span><b><span class="ml-1">{{masked_phone}}</span></b>
        <form #verifyData='ngForm' (ngSubmit)="verifyCode(verifyData.value)">
            <div class="w-100" appearance="outline">
                <input type="tel" mask="0 0 0 0 0 0" [showMaskTyped]="true"
                       placeHolderCharacter="_" name="code"
                       class="code-input" [(ngModel)]="code" placeholder="_ _ _ _ _ _"
                       required autofocus />
                <br />
                <div class="mat-body-2" *ngIf="isError">
                    {{errorMessage}}
                </div>
            </div>
            <br />
            <div class="wait-label">{{'repeat_send_available' | translate}} <countdown #cd1 [config]="{ leftTime: 60, format: 'mm:ss'}" (event)="handleEvent($event)"></countdown></div>
            <button type="submit" class="b-secondary b-submit2 w-100" [disabled]="verifyData.invalid && !repeatSending">
                <span *ngIf="verifyData.invalid">{{'repeat_code' | translate}}</span>
                <span *ngIf="!verifyData.invalid">{{'confirm' | translate}}</span>
            </button>
            <mat-checkbox [disabled]="sent" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" [color]="color" class="license-checkbox mat-body-2">{{'agree' | translate}} <a routerLink="/oferta" href="" target="_blank">{{'public_offer' | translate}}</a></mat-checkbox>
        </form>
    </div>

    <mat-card class="login-card ggl-card" (click)="googleLogin()">
        <div fxLayout="row"
             fxFlexFill
             fxLayoutAlign="center center">
            <img class="ggl-image" src="/images/google-icon.svg" alt="googleIcon" />
            <span>{{'sign_up_google' | translate}}</span>
        </div>
    </mat-card>
</div>-->
