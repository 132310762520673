<div class="d-sm-block d-md-block d-lg-none">
  <div class="my-modal" tabindex="-1" role="dialog" [ngClass]="{'show': showModal}">
    <div class="my-modal-dialog-mobile" role="document">
      <div class="my-modal-content">
        <!--<div class="my-modal-header">
          <h5 class="my-modal-title">Modal title</h5>
          <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>-->
        <div class="my-modal-body">
          <h5 class="choose-country-title-mobile">Выберите вашу страну</h5>
          <div class="country-countainer">
            <button type="button" class="btn-country" (click)="onClick()">
              <div class="country-box">
                <img class="country-flag-small-mobile" src="/images/Kazakhstan.svg" />
                <p class="country-name-mobile">Казахстан</p>
              </div>
              <mat-divider class="divider-modal"></mat-divider>
            </button>

            <button type="button" class="btn-country" (click)="onClick2()">
              <div class="country-box">
                <img class="country-flag-small-mobile" src="/images/Russia.svg" />
                <p class="country-name-mobile">Россия</p>
              </div>
              <mat-divider class="divider-modal"></mat-divider>
            </button>

            <button type="button" class="btn-country" (click)="onClick3()">
              <div class="country-box">
                <img class="country-flag-small-mobile" src="/images/OtherWorld.svg" />
                <p class="country-name-mobile">Другая</p>
              </div>
            </button>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>



<div class="d-none d-lg-inline">
  <div class="my-modal" tabindex="-1" role="dialog" [ngClass]="{'show': showModal}">
    <div class="my-modal-dialog" role="document">
      <div class="my-modal-content">
        <!--<div class="my-modal-header">
          <h5 class="my-modal-title">Modal title</h5>
          <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>-->
        <div class="my-modal-body">
          <h5 class="choose-country-title">Выберите вашу страну</h5>
          <div class="country-countainer">
            <button type="button" class="btn-country" (click)="onClick()">
              <div class="country-box">
                <img class="country-flag-small" src="/images/Kazakhstan.svg" />
                <p class="country-name">Казахстан</p>
              </div>
              <mat-divider class="divider-modal"></mat-divider>
            </button>

            <button type="button" class="btn-country" (click)="onClick2()">
              <div class="country-box">
                <img class="country-flag-small" src="/images/Russia.svg" />
                <p class="country-name">Россия</p>
              </div>
              <mat-divider class="divider-modal"></mat-divider>
            </button>

            <button type="button" class="btn-country" (click)="onClick3()">
              <div class="country-box">
                <img class="country-flag-small" src="/images/OtherWorld.svg" />
                <p class="country-name">Другая страна</p>
              </div>
            </button>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

