import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'materialImage'
})
export class MaterialPipe implements PipeTransform {

    transform(value: string): unknown {
        switch (value) {
            case "application/pdf":
                return "/images/pdf.svg";
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return "/images/doc.svg";
            case "image/png":
                return "/images/png.svg";

            case "image/jpeg":
            case "image/pjpeg":
                return "/images/jpg.svg";

            default:
                return "/images/file.svg";
      }
      
  }

}
