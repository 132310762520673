import { Pipe, PipeTransform } from '@angular/core';
import { TrainingStageState } from '../../models/enum/TrainingStageState';

@Pipe({ name: 'lesson_state' })
export class LessonStatePipe implements PipeTransform {
    transform(state: TrainingStageState) {
        switch (state) {
            case TrainingStageState.Idle:
                return "Lesson_not_available";
            case TrainingStageState.Started:
                return "Lesson_available";
            case TrainingStageState.Completed:
                return "Lesson_complete";
        }
    }
}