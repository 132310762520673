import { Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase';
import { CountdownComponent } from 'ngx-countdown';
import { AuthorizeService } from '../authorize.service';
import { style, state, animate, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { ApiService } from '../../app/api.service';
import { PreviousRouteService } from '../../app/previous-route-service.service';
import { first } from 'rxjs/operators';
import { countryService } from '../../app/services/countryService'; // service

const firebaseConfig = {
    apiKey: 'AIzaSyB6O-vUDIJw3LOyGl_eZL4zueJpV2saP5g',
    authDomain: 'remoteeducation.firebaseapp.com',
    projectId: 'remoteeducation',
    storageBucket: 'remoteeducation.appspot.com',
    messagingSenderId: '590966757827',

};

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
    styleUrls: ['./verify-phone.component.css'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(700, style({ opacity: 1 }))
            ])
            /*,transition(':leave', [
                animate(500, style({ opacity: 0 }))
            ])*/
        ])
    ]
})

export class VerifyPhoneComponent implements OnInit {
  get currentCountry(): string {
    return this.countryService.get();
  }
    countryLogin: string;
    public recaptchaVerifier: firebase.auth.RecaptchaVerifier;
    public sent: boolean;
    public phone_number: string;
    public prefixOtherCountry = "+";
    public prefix = "+7";
    public color = 'primary';
    public agreed = false;
    public code: string;
    public masked_phone: string;
    public isError: boolean;
    public errorMessage: string;
    repeatSending: boolean;

    @ViewChild('pnumber', { static: false }) phoneEl: ElementRef;

  constructor(public translate: TranslateService, private countryService: countryService, public authService: AuthorizeService, private router: Router,
        public apiService: ApiService, private previousRouteService: PreviousRouteService) {
        console.log(firebaseConfig);

        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
            firebase.auth().useDeviceLanguage();
      }
      this.countryLogin = localStorage.getItem('option');
    }

    ngOnInit() {
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: "invisible"
        });
    }

    setSended() {
        this.sent = true;
    }

    onSubmit(formData) {
        if (!this.agreed) {
            this.isError = true;
            this.errorMessage = "User agreement not accepted";
            return;
        }
        this.isError = false;
        this.errorMessage = null;

      this.masked_phone = this.phoneEl.nativeElement.value;
      if (this.currentCountry === 'Другая') {
        this.authService.sendPhoneVerification(this.recaptchaVerifier, this.prefixOtherCountry, formData.phone_number.toString(), this);

      }
      else {
        this.authService.sendPhoneVerification(this.recaptchaVerifier, this.prefix, formData.phone_number.toString(), this);

      }
      
    };

    googleLogin() {
        if (!this.agreed) {
            this.isError = true;
            this.errorMessage = "User agreement not accepted";
            return;
        }
        this.isError = false;
        this.errorMessage = null;
        this.authService.GoogleAuth(this);
    }

    verifyCode(verifyData) {
        if (!verifyData.code || verifyData.code.length !== 6) {
            this.repeatSending = false;
            this.sent = false;
        }
        else {
            this.authService.verifyCode(verifyData.code, this);
        }

        this.isError = false;
        this.errorMessage = null;
    }

    handleEvent(event) {
        if (event.action === "done") {
            this.repeatSending = true;
        }
    }

    setError(message: string) {
        this.isError = true;
        this.errorMessage = message;
    }

    onCodeVerified() {
        this.apiService.setContact(this.authService.user).pipe(first()).subscribe((res => {
            this.authService._contact.next(res);

            const previousRoute = this.previousRouteService.getPreviousUrl();
            console.log("previousRoute", previousRoute);
            if (previousRoute) {
                this.router.navigateByUrl(previousRoute).then(() => {
                    setTimeout(() => {
                        this.previousRouteService.deletePreviousUrlKey();
                    }, 8500);
                });
            }
            else {
                if (res.firstName) {
                    this.router.navigate([`cabinet`]);
                }
                else {
                    this.router.navigate([`register`]);
                }
            }
        }));
    }

}
