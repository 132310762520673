<div class="d-sm-block d-md-block d-lg-none">

  <mat-sidenav-container style="width: 100%">
    <mat-sidenav #sidenav position="end" style="overflow-x:hidden;">
      <div class="sidenav_container sidenav_container-mobile sidenav_container-table" (sidenavClose)="sidenav.toggle()">
        <div fxLayout="column" style="height: 100vh; overflow-x:hidden;">
          <div style="margin-top: 35px;">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;margin-left:18px; text-align:center; padding-left:8px;">
              <div fxLayout="column" fxLayoutAlign="start start" style="">
                <a (click)="goDown1()" class="phone-text">{{ 'Courses' | translate }}</a>
                <a (click)="goDown2()" style=" margin-top: 14px;" class="phone-text">{{ 'Education_process' | translate }}</a>
                <a (click)="goDown3()" style=" margin-top: 14px;" class="phone-text">{{ 'Comments' | translate }}</a>
                <a (click)="goDown4()" style=" margin-top: 14px;" class="phone-text">{{ 'About_us' | translate }}</a>
              </div>
              <button mat-icon-button (click)="sidenav.toggle()" style="margin-right: 6px;top:0;right:0;position:absolute;margin-top:21px;">
                <img style="width:20px;margin-left:10px;margin-right:17px;" src="/images/menu_close_light.svg" alt="menuClose" />
              </button>
            </div>
          </div>

          <div></div>

          <div class=" mt-auto justify-content-around" style="padding-bottom:26px;margin-left:18px;">
            <div *ngIf="currentCountry==='Россия'">
              <p class="phone-mobile phone-table">{{'phone_numru' | translate}}</p>
            </div>
            <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Другая'">
              <p class="phone-mobile phone-table">{{'phone_numkz' | translate}}</p>
            </div>
            <!--<img class="img-logo" src="/images/facebook.svg" alt="Facebook" />
        <img class="img-logo" src="/images/instagram.svg" alt="Instagram" />-->
            <img class="img-logo" src="/images/vk.svg" alt="VK" />
            <img class="img-logo" src="/images/telegram.svg" alt="Telegram" />
          </div>
        </div>
      </div>

    </mat-sidenav>
    <mat-sidenav-content style="height: 100vh; overflow-x: hidden;">
      <!--(onCountryChange)="onCountryChange($event)"
  (sidenavToggle)="sidenav.toggle()"-->
      <app-courses-toolbar (sidenavToggle)="sidenav.toggle()"></app-courses-toolbar>

      <div class="rightimage" style="top: 0;">
        <img src="/images/boyMobile.svg" alt="rightImage" style="" />
      </div>

      <div class="container991 container425" style="position: relative;">

        <div class="row">
          <div class="col text-center991 ml-2">
            <p class="bigBoldText-mobile" style="">Политика обработки персональных <br /> данных сайта <a href="" target="_blank" routerLink="/">https://prnc2.com/</a></p>

          </div>
        </div>

        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">1. Общие положения </p>

        <p class="pl-2 offertaText-mobile p575 p425">1.1. Политика ТОО «DragAu» (далее – DragAu) в отношении обработки персональных данных (далее – Политика) разработана в соответствии с Федеральным законом №152– ФЗ «О персональных данных» от 27.07.2006 г. и иными актами РФ. </p>
        <p class="pl-2 offertaText-mobile">1.2. Настоящая Политика определяет порядок обработки персональных данных пользователей сайта <a href="" target="_blank" routerLink="/">https://prnc2.com/</a> (далее – Сайт), условия и принципы обработки персональных данных, права Пользователей и обязанности DragAu, сведения о реализуемых мерах по защите обрабатываемых персональных данных.  </p>
        <p class="pl-2 offertaText-mobile">1.3. Настоящая Политика действует в отношении всех персональных данных, которые DragAu получает от пользователей Сайта (далее – Пользователи, Пользователь).</p>
        <p class="pl-2 offertaText-mobile">1.4. В случае несогласия с условиями Политики Пользователь должен немедленно прекратить любое использование Сайта. </p>


        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">2. Термины и определения </p>
        <p class="pl-2 offertaText-mobile">2.1. В Политике используются следующие термины:</p>
        <p class="pl-2 offertaText-mobile"><strong>Сайт</strong>— интернет-сайт, расположенный по адресу <a href="" target="_blank" routerLink="/">https://prnc2.com/</a> </p>
        <p class="pl-2 offertaText-mobile"><strong>Персональные данные</strong> (далее - ПДн) — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту ПДн). </p>
        <p class="pl-2 offertaText-mobile"><strong>Блокирование ПДн </strong>временное прекращение обработки ПДн (за исключением случаев, если обработка необходима для уточнения ПДн). </p>
        <p class="pl-2 offertaText-mobile"><strong>Обезличивание ПДн </strong>— действия, в результате которых невозможно определить без использования дополнительной информации принадлежность ПДн конкретному субъекту ПДн.</p>
        <p class="pl-2 offertaText-mobile"><strong>Обработка ПДн </strong> — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение ПДн.</p>
        <p class="pl-2 offertaText-mobile"><strong>Оператор</strong>– Товарищество с ограниченной ответственностью «DragAu» (ТОО «DragAu»), юридический адрес: 050010 Алматы ул. Бегалина д.42. DragAu самостоятельно организует и осуществляет обработку ПДн, а также определяет цели обработки ПДн, состав ПДн, подлежащих обработке, действия (операции), совершаемые с ПДн.</p>
        <p class="pl-2 offertaText-mobile"><strong>Личный кабинет </strong>– совокупность защищенных страниц Сайта, созданных в результате регистрации Пользователя и доступных при вводе его аутентификационных данных (адреса электронной почты и пароля или номера телефона) в предусмотренные для этого поля на Сайте Платформы.</p>
        <p class="pl-2 offertaText-mobile"><strong>Предоставление ПД</strong>– действия, направленные на раскрытие ПДн определенному лицу или определенному кругу лиц.</p>
        <p class="pl-2 offertaText-mobile"><strong>Распространение ПДн </strong>– действия, направленные на раскрытие ПДн неопределенному кругу лиц (передача ПДн) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование ПДн в средствах массовой информации, размещение в информационно–телекоммуникационных сетях или предоставление доступа к персональным данным каким–либо иным способом. </p>
        <p class="pl-2 offertaText-mobile"><strong>Пользователь </strong>посетитель Сайта, субъект ПДн. </p>
        <p class="pl-2 offertaText-mobile"><strong>Уничтожение ПДн </strong>– действия, в результате которых невозможно восстановить содержание ПДн в ИСПДн, и (или) в результате которых уничтожаются материальные носители ПДн.</p>
        <p class="pl-2 offertaText-mobile"><strong>Файлы cookie (куки) </strong>- данные, которые автоматически передаются DragAu в процессе использования Сайта с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, географическое местоположение, информация о браузере и виде операционной системы устройства Пользователя, технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Сайту. Принимая условия Политики вы соглашаетесь на использование файлов cookie</p>


        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">3. Правовые основания и цели обработки персональных данных </p>
        <p class="pl-2 offertaText-mobile">3.1. Правовыми основаниями обработки ПДн DragAu являются: </p>
        <ul class="pl-5 offertaText-mobile">
          <li>Гражданский Кодекс Республики Казахстан. </li>
          <li>Закон Республики Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их защите»;</li>
          <li>Закон Республики Казахстан от 24 ноября 2015 года № 418-V «Об информатизации»;</li>
          <li>Иные применимые нормативно-правовые акты; </li>
          <li>Локальные правовые акты DragAu.</li>
          <li>Согласие Пользователя на обработку персональных данных.</li>
        </ul>
        <p class="pl-2 offertaText-mobile">3.2. DragAu обрабатывает ПДн Пользователя исключительно в следующих целях: </p>
        <p class="pl-2 offertaText-mobile">3.2.1. Регистрация и идентификация Пользователя на Сайта, предоставление Пользователю возможности полноценного использования Сайта;</p>
        <p class="pl-2 offertaText-mobile">3.2.2. Отображение профиля Пользователя для иных Пользователей Сайта; </p>
        <p class="pl-2 offertaText-mobile">3.2.3. Установление и поддержание связи между Пользователем и DragAu, консультирование по вопросам оказания услуг DragAu;</p>
        <p class="pl-2 offertaText-mobile">3.2.4. Заключение договоров и исполнение DragAu обязательств перед Пользователем по ним (в частности, Пользовательскому соглашению, договорам оказания услуг, иным договорам). Исполнение обязательств, в частности, включает в себя информирование о дате и времени проведения занятий (в том числе путем обзвона и направления смс- сообщений, направления сообщений в мессенджерах «WhatsApp», «Telegram»); направление сертификатов и т.д.; </p>
        <p class="pl-2 offertaText-mobile">3.2.5. Направление DragAu Пользователю сообщений рекламного характера, информационных рассылок о продуктах, услугах DragAu и его партнеров, о специальных предложениях, рекламных акциях, розыгрышах, конкурсах, опросах на адрес электронной почты Пользователя, по номеру телефона Пользователя (имени Пользователя) в мессенджерах «WhatsApp», «Viber», «Telegram», посредством почтовой рассылки, SMSсообщений, push-уведомлений, а также сообщение Пользователю такой информации в устном формате по телефону</p>
        <p class="pl-2 offertaText-mobile">3.2.6. Размещение на Сайте, в официальных группах социальных сетей и иных сообществах DragAu в сети Интернет, прочих рекламных и информационных источниках, в целях, не связанных с установлением личности Пользователя:</p>

        <ul class="pl-5 offertaText-mobile">
          <li>видеоматериалов, полученных в процессе оказания услуг, </li>
          <li>оставленных Пользователем отзывов об услугах, оказываемых DragAu.</li>
        </ul>

        <p class="pl-2 offertaText-mobile">3.2.7. Улучшение качества обслуживания Пользователя и модернизация Сайта путем обработки запросов и заявок от Пользователя; </p>
        <p class="pl-2 offertaText-mobile">3.2.8. Статистические   и    иные    исследования   на    основе   обезличенной   информации и, предоставленной Пользователем;</p>
        <p class="pl-2 offertaText-mobile">3.2.9. Конкретный объем ПДн, обрабатываемых в указанных выше целях, определен в разделе 5 Политики. </p>


        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">4. Условия и порядок предоставления согласия на обработку персональных данных DragAu</p>


        <p class="pl-2 offertaText-mobile">4.1. DragAu не проверяет предоставляемые Пользователем ПДн. В связи с этим DragAu исходит из того, что при предоставлении ПДн на Сайте Пользователь: </p>
        <p class="pl-2 offertaText-mobile">4.1.1. Является дееспособным лицом. В случае недееспособности лица, использующего Сайт, согласие на обработку ПДн предоставляется законным представителем Пользователя, который ознакомился и принял условия обработки ПДн, указанные в настоящей Политике.</p>
        <p class="pl-2 offertaText-mobile">4.1.2. Указывает достоверную информацию о себе или о представляемом недееспособном лице (п. 4.1.1) в объемах, необходимых для использования Сайта. Пользователь самостоятельно поддерживает предоставленные ПДн в актуальном состоянии. Последствия предоставления Пользователем недостоверной или недостаточной информации определены в Пользовательском соглашении.</p>
        <p class="pl-2 offertaText-mobile">4.1.3. Осознает, что информация на Сайте, размещаемая Пользователем о себе, может становиться доступной для других Пользователей Сайта, может быть скопирована и распространена такими Пользователями в случаях, предусмотренных Политикой. </p>
        <p class="pl-2 offertaText-mobile">4.2. Пользователь принимает условия Политики и дает DragAu информированное и осознанное согласие на обработку своих ПДн на условиях, предусмотренных Политикой и Законом: </p>
        <p class="pl-2 offertaText-mobile">4.2.1. <strong>При регистрации и идентификации на Сайте</strong> — для ПДн, которые Пользователь предоставляет DragAu: путем заполнения формы для регистрации, расположенной в сети Интернет по адресу <a routerLink="/authentication/login" target="_blank" href="">https://prnc2.com/register</a> . Пользователь считается предоставившим согласие на обработку своих ПДн в момент нажатия кнопки «Сохранить профиль».</p>
        <p class="pl-2 offertaText-mobile">4.2.2. <strong>При внесении или изменении ПДн в разделе «Персональная информация» Личного кабинета</strong> — при редактировании или дополнении информации в разделе «Персональная информация». Указанные ПДн могут использоваться для выполнения DragAu обязательств, предусмотренных Пользовательским соглашением, условиями использования сервисов Сайта, договорами об оказании услуг. Пользователь считается предоставившим согласие на обработку своих вновь внесенных или измененных ПДн в момент завершения их редактирования. После заполнения любой из граф, указанных в разделе «Персональная информация», ПДн обновляются автоматически.</p>
        <p class="pl-2 offertaText-mobile">4.2.3. <strong>При заполнении формы обратной связи</strong>, в том числе заявки на прохождения курса,  заявки на использование других сервисов Сайта — для ПДн, которые Пользователь предоставляет DragAu при заполнении формы обратной связи в сети Интернет на Сайте и электронных сервисах. Пользователь считается предоставившим согласие на обработку своих ПДн, внесенных в поля формы обратной связи, в момент нажатия кнопки,подтверждающей отправку заявки (кнопки могут называться «Отправить», «Оставить заявку», «Записаться», «Получить консультацию» и иным аналогичным образом).</p>
        <p class="pl-2 offertaText-mobile">4.2.4. <strong>При любом использовании Сайта</strong> — для ПДн, которые автоматически передаются DragAu в процессе использования Сайта с помощью установленного на устройстве Пользователя программного обеспечения. Пользователь считается предоставившим согласие на обработку своих ПДн в момент начала использования Сайта.</p>
        <p class="pl-2 offertaText-mobile">4.3. Совершая действия, указанные в п. 4.2 настоящей Политики, Пользователь дает DragAu согласие на обработку соответствующих ПДн, перечень которых указан в разделе 5 настоящей Политики, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление третьим лицам, доступ), обезличивание, блокирование, удаление, уничтожение ПДн с использованием и без использования средств автоматизации в соответствии с целями, указанными в разделе 3 настоящей Политики.</p>
        <p class="pl-2 offertaText-mobile">4.4. Предоставленное Пользователем в соответствии с настоящей Политикой согласие на обработку ПДн действует со дня предоставления такого согласия и в течение срока, необходимого для достижения целей обработки ПДн или до момента отзыва Пользователем указанного согласия, если иное не предусмотрено действующим законодательством РК. Предоставленное Пользователем в соответствии с настоящей Политикой согласие на обработку ПДн может быть в любой момент отозвано Пользователем. Пользователь может отозвать предоставленное ранее DragAu согласие на обработку ПДн одним из следующих способов: </p>
        <ul class="pl-5 offertaText-mobile">
          <li>направив соответствующее заявление почтой по адресу: 050010 Алматы ул. Бегалина д.42;</li>
          <li>направив соответствующее заявление в форме электронного документа на адрес электронной почты <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a></li>
        </ul>


        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">5. Персональные данные, обрабатываемые DragAu</p>

        <p class="pl-2 offertaText-mobile">5.1. Обработка ПДн Пользователей осуществляется на следующих условиях, используя для каждой цели соответствующий объем обрабатываемых ПДн: </p>
        <p class="pl-2 offertaText-mobile">5.1.1. Регистрация на Сайте:</p>
        <ul class="pl-5 offertaText-mobile">
          <li>имя </li>
          <li>фамилия</li>
          <li>адрес электронной почты </li>
          <li>область деятельности </li>
          <li>страна</li>
          <li>город </li>
        </ul>
        <p class="pl-2 offertaText-mobile">5.1.2. Идентификация на Сайте:</p>
        <ul class="pl-5 offertaText-mobile">
          <li>номер телефона </li>
          <li>аккаунт Google</li>

        </ul>
        <p class="pl-2 offertaText-mobile">5.1.3. Отображение профиля для иных Пользователей Сайта: </p>
        <ul class="pl-5 offertaText-mobile">
          <li>фамилия и имя</li>
        </ul>
        <p class="pl-2 offertaText-mobile">5.1.4. Установление и поддержание связи между Пользователем и DragAu, консультирование по вопросам оказания услуг:</p>
        <ul class="pl-5 offertaText-mobile">
          <li>фамилия и имя  </li>
          <li>номер телефона </li>
          <li>адрес электронной почты </li>
          <li>данные аккаунтов в социальных сетях и мессенджерах </li>
        </ul>
        <p class="pl-2 offertaText-mobile">5.1.5. Заключение договоров, исполнение обязательств по ним </p>
        <ul class="pl-5 offertaText-mobile">
          <li>фамилия и имя  </li>
          <li>номер телефона </li>

        </ul>
        <p class="pl-2 offertaText-mobile">5.1.6. Направление сообщений рекламного характера; информационных рассылок; предоставление информации рекламного характера в устном и письменном виде </p>
        <ul class="pl-5 offertaText-mobile">
          <li>фамилия и имя  </li>
          <li>номер телефона </li>
          <li>адрес электронной почты</li>
        </ul>
        <p class="pl-2 offertaText-mobile">5.1.7. Размещение фото и видеоматериалов, полученных в ходе оказания услуг DragAu; комментариев Пользователей относительно услуг DragAu </p>
        <ul class="pl-5 offertaText-mobile">
          <li>фамилия и имя </li>
          <li>изображение</li>
          <li>данные аккаунтов в социальных сетях (если указаны в разделе «Персональная информация») </li>

        </ul>
        <p class="pl-2 offertaText-mobile">5.1.8. Улучшение качества обслуживания Пользователей и модернизация Сайта − файлы cookie </p>
        <ul class="pl-5 offertaText-mobile">
          <li>файлы cookie</li>
        </ul>
        <p class="pl-2 offertaText-mobile">5.1.9. Статистические и иные исследования на основе обезличенной информации − файлы cookie </p>
        <ul class="pl-5 offertaText-mobile">
          <li>файлы cookie</li>
        </ul>


        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">6. Обработка персональных данных </p>
        <p class="pl-2 offertaText-mobile">6.1. DragAu обрабатывает ПДн на основе следующих принципов:</p>
        <ul class="pl-5 offertaText-mobile">
          <li>законности и справедливой основы;</li>
          <li>ограничения обработки ПДн достижением конкретных, заранее определенных и законных целей; </li>
          <li>недопущения обработки ПДн, несовместимой с целями сбора ПДн;</li>
          <li>недопущения объединения баз данных, содержащих ПДн, обработка которых осуществляется в целях, несовместимых между собой;</li>
          <li>обработки только тех ПДн, которые отвечают целям их обработки;</li>
          <li>соответствия содержания и объема обрабатываемых ПДн заявленным целям обработки; </li>
          <li>недопущения обработки ПДн, избыточных по отношению к заявленным целям их обработки;</li>
          <li>обеспечения точности, достаточности и актуальности ПДн по отношению к целям обработки ПДн; </li>
          <li>уничтожения либо обезличивания ПДн по достижении целей их обработки или в случае утраты необходимости в достижении этих целей, получении от Пользователей требования об уничтожении ПДн, поступлении от Пользователя отзыва согласия на обработку ПДн.</li>
        </ul>


        <p class="pl-2 offertaText-mobile">6.2. DragAu производит обработку ПДн Пользователей (запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение) с использованием баз данных на территории РК.</p>
        <p class="pl-2 offertaText-mobile">6.3. Обработка ПДн Пользователей производится DragAu как с использованием автоматизированных средств, так и без них</p>
        <p class="pl-2 offertaText-mobile">6.4. DragAu и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять ПДн без согласия субъекта ПДн, если иное не предусмотрено  законом РК</p>
        <p class="pl-2 offertaText-mobile">6.5. Обработка ПДн Пользователя включает совершение DragAu следующих действий: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление третьим лицам, доступ), обезличивание, блокирование, удаление, уничтожение. </p>

        <p class="pl-2 offertaText-mobile">6.6. Хранение ПДн Пользователей осуществляется на электронных носителях. При обработке ПДн с целью исполнения обязательств по соглашениям с Пользователем DragAu может извлекать ПДн и хранить их на материальных носителях. Хранение таких ПДн осуществляется в течение срока, установленного законодательством Республики Казахстан об образовании и архивном деле. Хранение ПДн осуществляется (в зависимости от того, какое событие наступит раньше): </p>
        <ul class="pl-5 offertaText-mobile">
          <li>до момента их удаления Пользователем в разделе «Персональная информация» в личном кабинете на Сайте; </li>
          <li>до момента их уничтожения DragAu — в случае поступления от Пользователя отзыва согласия на обработку ПДн или требования об уничтожении ПДн; </li>
          <li>до момента истечения срока действия согласия. </li>
        </ul>





        <p class="pl-2 offertaText-mobile">6.7. DragAu вправе осуществлять передачу ПДн третьим лицам с соблюдением следующих условий:</p>
        <ul class="pl-5 offertaText-mobile">
          <li>Третье лицо осуществляет обработку ПДн с использованием баз данных на территории Российской Федерации.</li>
          <li>Третье лицо обеспечивает конфиденциальность ПДн при их обработке и использовании; обязуется не раскрывать иным лицам, а также не распространять ПДн Пользователей без их согласия.</li>
          <li>Третье лицо гарантирует соблюдение следующих мер по обеспечению безопасности ПДн при их обработке: использование средств защиты информации; обнаружение и фиксация фактов несанкционированного доступа к персональным данным и принятие мер по восстановлению ПДн; ограничение доступа к персональным данным; регистрация и учет действий с персональными данными; контроль и оценка эффективности применяемых мер по обеспечению безопасности ПДн</li>
          <li>Перечень разрешенных способов обработки ПДн: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение. Третьему лицу запрещено осуществлять передачу и распространение ПДн. </li>
        </ul>

        <p class="pl-2 offertaText-mobile">Цели передачи ПДн:</p>
        <ul class="pl-5 offertaText-mobile">
          <li>Оптимизация DragAu рассылки сообщений информационного и рекламного характера и коммуникации DragAu с Пользователями относительно услуг DragAu. В этом случае третьему лицу для коммуникации с Пользователем передаются следующие ПДн Пользователей: фамилия, имя; номер телефона; адрес электронной почты.</li>
          <li>Направление Пользователям информационных рассылок. В этом случае третьему лицу передаются следующие ПДн Пользователей: фамилия, имя; адрес электронной почты. Каждая информационная рассылка предоставляет Пользователю возможность отказаться от получения таких рассылок.</li>
          <li> Исполнение DragAu условий договоров и соглашений, заключенных с Пользователями Сайта, с привлечением третьих лиц. В этом случае третьему лицу передаются следующие ПДн Пользователей: фамилия, имя; адрес электронной почты. Объем передаваемых ПДн Пользователя третьим лицам может быть также уточнен в условиях договора</li>
        </ul>


        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">7. Меры, принимаемые DragAu для защиты персональных данных </p>
        <p class="pl-2 offertaText-mobile">7.1. DragAu принимает необходимые и достаточные правовые, организационные и технические меры для защиты информации, предоставляемой Пользователями, от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц. Такие действия, в частности, включают:</p>
        <ul class="pl-5 offertaText-mobile">
          <li>Назначение лица, ответственного за обработку ПДн;</li>
          <li>Применение организационных и технических мер по обеспечению безопасности ПДн при их обработке в информационных системах;</li>
          <li>Контроль фактов несанкционированного доступа к ПДн и принятие мер по недопущению подобных инцидентов в дальнейшем;</li>
          <li>Контроль за принимаемыми мерами по обеспечению безопасности ПДн и уровнем защищенности информационных систем ПДн.</li>
        </ul>


        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">8. Права пользователя </p>

        <p class="pl-2 offertaText-mobile">8.1. Пользователь принимает решение о предоставлении его ПДн и дает согласие на их обработку свободно, своей волей и в своем интересе. Пользователь выражает свое согласие на обработку ПДн в порядке, приведенном в п. 4.2 настоящей Политики</p>
        <p class="pl-2 offertaText-mobile">8.2. Пользователь имеет право на получение у DragAu информации, касающейся обработки его ПДн</p>
        <p class="pl-2 offertaText-mobile">8.3. Пользователь вправе направлять DragAu свои запросы и требования (далее – Обращение), в том числе относительно использования его ПДн, а также отзыва согласия на обработку ПДн. Обращение может быть направлено следующими способами:</p>
        <p class="pl-2 offertaText-mobile">8.3.1. В письменной форме по адресу DragAu (раздел 11 Политики); </p>
        <p class="pl-2 offertaText-mobile">8.3.2. В форме электронного документа (скан-, фотокопия документа). Документ должен быть направлен с адреса электронной почты Пользователя, указанного им при регистрации на Сайте по адресу электронной почты DragAu:<a href="mailto:info@comproducts.ru"> info@comproducts.ru.</a></p>


        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">9. Актуализация, исправление, удаление и уничтожение ПДн</p>
        <p class="pl-2 offertaText-mobile">9.1. DragAu обязуется сообщить Пользователю или его представителю информацию о наличии ПДн, относящихся к этому Пользователю, а также предоставить возможность ознакомления с этими ПДн при обращении Пользователя или его представителя в течение 30 (тридцати) дней с даты получения запроса Пользователя или его представителя.</p>
        <p class="pl-2 offertaText-mobile">9.2. DragAu обязуется предоставить безвозмездно Пользователю или его представителю возможность ознакомления с ПДн, относящимися к этому Пользователю.</p>
        <p class="pl-2 offertaText-mobile">9.3. В срок, не превышающий 7 (семи) рабочих дней со дня предоставления Пользователем или его представителем сведений, подтверждающих, что ПДн являются неполными, неточными или неактуальными, DragAu обязуется внести в них необходимые изменения.</p>
        <p class="pl-2 offertaText-mobile">9.4. В срок, не превышающий 7 (семи) рабочих дней со дня представления Пользователем или его представителем сведений, подтверждающих, что такие ПДн являются незаконно полученными или не являются необходимыми для заявленной цели обработки, DragAu обязуется уничтожить такие ПДн. DragAu обязуется также уведомить Пользователя или его представителя о внесенных изменениях и предпринятыхмерах и принять разумные меры для уведомления третьих лиц, которым ПДн этого Пользователя были переданы</p>
        <p class="pl-2 offertaText-mobile">9.5. В случае подтверждения факта неточности ПДн DragAu на основании сведений, представленных Пользователем или его представителем либо уполномоченным органом по защите прав субъектов ПДн, или иных необходимых документов обязуется уточнить ПДн либо обеспечить их уточнение (если обработка ПДн осуществляется другим лицом, действующим по поручению DragAu) в течение 7 (семи) рабочих дней со дня представления таких сведений и снять блокирование ПДн</p>
        <p class="pl-2 offertaText-mobile">9.6. DragAu обязуется прекратить обработку ПДн или обеспечить прекращение обработки ПДн лицом, действующим по поручению DragAu: </p>
        <ul class="pl-5 offertaText-mobile">
          <li>в случае выявления неправомерной обработки ПДн, осуществляемой DragAu или лицом, действующим по поручению DragAu, в срок, не превышающий 3 (трех) рабочих дней с даты этого выявления; </li>
          <li>в случае отзыва Пользователем согласия на обработку его ПДн; </li>
          <li>в случае достижения цели обработки ПДН.</li>
        </ul>
        <p class="pl-2 offertaText-mobile">9.7. DragAu обязуется уничтожить ПДн Пользователя или обеспечить их уничтожение (если обработка ПДн осуществляется другим лицом, действующим по поручению DragAu) в срок, не превышающий 30 (тридцати) дней с даты достижения цели обработки ПДн. </p>
        <p class="pl-2 offertaText-mobile">9.8. В случае отсутствия возможности уничтожения ПДн в течение указанного срока DragAu осуществляет блокирование таких ПДн или обеспечивает их блокирование (если обработка ПДн осуществляется другим лицом, действующим по поручению DragAu) и обеспечивает уничтожение ПДн в срок не более чем 6 (шесть) месяцев, если иной срок не установлен законами Республики Казахстан. </p>



        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">10. Изменение политики </p>

        <p class="pl-2 offertaText-mobile">10.1. DragAu оставляет за собой право вносить изменения в Политику. Пользователь обязан при каждом новом использовании Сайта ознакомиться с текстом Политики. </p>
        <p class="pl-2 offertaText-mobile">10.2. Новая редакция Политики вступает в силу с момента ее размещения в соответствующем разделе Сайта DragAu. Продолжение пользования Сайтом или его сервисами после публикации новой редакции Политики означает принятие Политики и ее условий Пользователем. В случае несогласия с условиями Политики Пользователь должен немедленно прекратить использование Сайта и его сервисов</p>
        <p class="pl-2 offertaText-mobile">10.3. Все вопросы по настоящей Политике просим направлять на адрес электронной почты DragAu <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a></p>



        <p class="offertaText-mobile" style="text-align:center;font-weight:bold;">11. сведения о DragAu</p>

        <p class="pl-2 offertaText-mobile mb-0">ТОО “DragAu”</p>
        <p class="pl-2 offertaText-mobile mb-0">Адрес: 050010 Алматы ул. Бегалина д.42</p>
        <p class="pl-2 offertaText-mobile mb-0">РНН 600 900 545 436</p>
        <p class="pl-2 offertaText-mobile mb-0">БИН 041 040 005 066</p>
        <p class="pl-2 offertaText-mobile mb-0">ИИК (IBAN) KZ316017131000014558</p>
        <p class="pl-2 offertaText-mobile mb-0">БИК: HSBKKZKX</p>
        <p class="pl-2 offertaText-mobile mb-0">Банк: АО «Народный Банк Казахстана»</p>
        <p class="pl-2 offertaText-mobile mb-5">Кбе 17</p>



      </div>





      <div class="">
        <div class="ab-footer-mobile ab-footer-table" fxLayout="column" fxFlex="25.55">
          <div fxFlex="21" style="">
            <div fxLayout="column" fxFlexFill style="">
              <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 30em;" />
              <!--<img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />-->
              <!--<div fxFlex fxLayoutAlign="start" style="padding-top: 1.85vh;">-->
              <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
              <!--<img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
        <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />-->
              <!--</div>-->
            </div>
          </div>
          <div fxLayout="column" class="" fxFlex="22" style="margin-left: 3.73vw; margin-bottom:2vh;">
            <div fxLayout="column" class="pb-4" fxFlexFill>

              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown1()" style="">{{'Courses' | translate}}</a>
              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown2()" style="">{{'Education_process' | translate}}</a>
              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown3()" style="">{{'Comments' | translate}}</a>
              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown4()" style="">{{'About_us' | translate}}</a>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" fxFlex="30" class="py-2" style="margin-left: 3.73vw;">
            <div fxLayout="column" fxFlexFill>
              <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" routerLink="/additional-services" style="">{{'Additional_services' | translate}}</a>
              <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="">{{'support' | translate}}</a>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" routerLink="/oferta" style="">{{'public_offer_1' | translate}}</a>
              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" routerLink="/ofertakz" style="">{{'public_offer_1' | translate}}</a>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>

              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>

              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" class="py-2" fxFlex="30" style="margin-left: 3.73vw;">
            <div fxLayout="column" fxFlexFill>
              <div *ngIf="currentCountry==='Россия'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>

              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>

              </div>
              <div *ngIf="currentCountry==='Россия'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>

              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>

              </div>
              <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" style="" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
            </div>
          </div>
          <div fxLayout="column" fxFlex="22" style="margin-left: 3.73vw;">
            <div fxLayout="row" fxFlexFill>
              <img src="/images/axelos.svg" class="axelos-img-mobile axelos-img-table" alt="axelosLogo" style="" />
              <div fxFlex fxLayoutAlign="start center" style="align-items:center;margin-left: 10px;">
                <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img-mobile footer-contact-img-table " />
          <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img-mobile footer-contact-img-table" />-->
                <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard-mobile" />
                <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa-mobile" />
              </div>

            </div>
          </div>

        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div class="d-none d-lg-inline">
  <div class="rightimage">
    <img src="/images/rightImage.svg" alt="rightImage" style="width: 24vw;" />
  </div>

  <app-courses-toolbar></app-courses-toolbar>

  <div class="containerOfferta">

    <p class="bigBoldText" style=" text-align:center;margin-top: 3vh;">Политика обработки персональных данных сайта <a href="" target="_blank" routerLink="/">https://prnc2.com/</a></p>

    <p class="offertaText" style="text-align:center;font-weight:bold;">1. Общие положения </p>

    <p class="pl-2 offertaText">1.1. Политика ТОО «DragAu» (далее – DragAu) в отношении обработки персональных данных (далее – Политика) разработана в соответствии с Законом Республики Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их защите».</p>
    <p class="pl-2 offertaText">1.2. Настоящая Политика определяет порядок обработки персональных данных пользователей сайта <a href="" target="_blank" routerLink="/">https://prnc2.com/</a> (далее – Сайт), условия и принципы обработки персональных данных, права Пользователей и обязанности DragAu, сведения о реализуемых мерах по защите обрабатываемых персональных данных.  </p>
    <p class="pl-2 offertaText">1.3. Настоящая Политика действует в отношении всех персональных данных, которые DragAu получает от пользователей Сайта (далее – Пользователи, Пользователь).</p>
    <p class="pl-2 offertaText">1.4. В случае несогласия с условиями Политики Пользователь должен немедленно прекратить любое использование Сайта. </p>


    <p class="offertaText" style="text-align:center;font-weight:bold;">2. Термины и определения </p>
    <p class="pl-2 offertaText">2.1. В Политике используются следующие термины:</p>
    <p class="pl-2 offertaText"><strong>Сайт</strong>— интернет-сайт, расположенный по адресу <a href="" target="_blank" routerLink="/">https://prnc2.com/</a> </p>
    <p class="pl-2 offertaText"><strong>Персональные данные</strong> (далее - ПДн) — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту ПДн). </p>
    <p class="pl-2 offertaText"><strong>Блокирование ПДн </strong>временное прекращение обработки ПДн (за исключением случаев, если обработка необходима для уточнения ПДн). </p>
    <p class="pl-2 offertaText"><strong>Обезличивание ПДн </strong>— действия, в результате которых невозможно определить без использования дополнительной информации принадлежность ПДн конкретному субъекту ПДн.</p>
    <p class="pl-2 offertaText"><strong>Обработка ПДн </strong> — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение ПДн.</p>
    <p class="pl-2 offertaText"><strong>Оператор</strong>– Товарищество с ограниченной ответственностью «DragAu» (TОО «DragAu»), юридический адрес: Алматы ул. Бегалина, д.42. DragAu самостоятельно организует и осуществляет обработку ПДн, а также определяет цели обработки ПДн, состав ПДн, подлежащих обработке, действия (операции), совершаемые с ПДн.</p>
    <p class="pl-2 offertaText"><strong>Личный кабинет </strong>– совокупность защищенных страниц Сайта, созданных в результате регистрации Пользователя и доступных при вводе его аутентификационных данных (адреса электронной почты и пароля или номера телефона) в предусмотренные для этого поля на Сайте Платформы.</p>
    <p class="pl-2 offertaText"><strong>Предоставление ПД</strong>– действия, направленные на раскрытие ПДн определенному лицу или определенному кругу лиц.</p>
    <p class="pl-2 offertaText"><strong>Распространение ПДн </strong>– действия, направленные на раскрытие ПДн неопределенному кругу лиц (передача ПДн) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование ПДн в средствах массовой информации, размещение в информационно–телекоммуникационных сетях или предоставление доступа к персональным данным каким–либо иным способом. </p>
    <p class="pl-2 offertaText"><strong>Пользователь </strong>посетитель Сайта, субъект ПДн. </p>
    <p class="pl-2 offertaText"><strong>Уничтожение ПДн </strong>– действия, в результате которых невозможно восстановить содержание ПДн в ИСПДн, и (или) в результате которых уничтожаются материальные носители ПДн.</p>
    <p class="pl-2 offertaText"><strong>Файлы cookie (куки) </strong>- данные, которые автоматически передаются DragAu в процессе использования Сайта с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, географическое местоположение, информация о браузере и виде операционной системы устройства Пользователя, технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Сайту. Принимая условия Политики вы соглашаетесь на использование файлов cookie</p>


    <p class="offertaText" style="text-align:center;font-weight:bold;">3. Правовые основания и цели обработки персональных данных </p>
    <p class="pl-2 offertaText">3.1. Правовыми основаниями обработки DragAu являются: </p>
    <ul class="pl-5 offertaText">
      <li>Гражданский Кодекс Республики Казахстан;</li>
      <li>Закон Республики Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их защите»;</li>
      <li>Закон Республики Казахстан от 24 ноября 2015 года № 418-V «Об информатизации»;</li>
      <li>Иные применимые нормативно-правовые акты; </li>
      <li>Локальные правовые акты DragAu.</li>
      <li>Согласие Пользователя на обработку персональных данных.</li>
    </ul>
    <p class="pl-2 offertaText">3.2. DragAu обрабатывает ПДн Пользователя исключительно в следующих целях: </p>
    <p class="pl-2 offertaText">3.2.1. Регистрация и идентификация Пользователя на Сайта, предоставление Пользователю возможности полноценного использования Сайта;</p>
    <p class="pl-2 offertaText">3.2.2. Отображение профиля Пользователя для иных Пользователей Сайта; </p>
    <p class="pl-2 offertaText">3.2.3. Установление и поддержание связи между Пользователем и DragAu, консультирование по вопросам оказания услуг DragAu;</p>
    <p class="pl-2 offertaText">3.2.4. Заключение договоров и исполнение DragAu обязательств перед Пользователем по ним (в частности, Пользовательскому соглашению, договорам оказания услуг, иным договорам). Исполнение обязательств, в частности, включает в себя информирование о дате и времени проведения занятий (в том числе путем обзвона и направления смс- сообщений, направления сообщений в мессенджерах «WhatsApp», «Telegram»); направление сертификатов и т.д.; </p>
    <p class="pl-2 offertaText">3.2.5. Направление DragAu Пользователю сообщений рекламного характера, информационных рассылок о продуктах, услугах DragAu и его партнеров, о специальных предложениях, рекламных акциях, розыгрышах, конкурсах, опросах на адрес электронной почты Пользователя, по номеру телефона Пользователя (имени Пользователя) в мессенджерах «WhatsApp», «Viber», «Telegram», посредством почтовой рассылки, SMSсообщений, push-уведомлений, а также сообщение Пользователю такой информации в устном формате по телефону</p>
    <p class="pl-2 offertaText">3.2.6. Размещение на Сайте, в официальных группах социальных сетей и иных сообществах DragAu в сети Интернет, прочих рекламных и информационных источниках, в целях, не связанных с установлением личности Пользователя:</p>

    <ul class="pl-5 offertaText">
      <li>видеоматериалов, полученных в процессе оказания услуг, </li>
      <li>оставленных Пользователем отзывов об услугах, оказываемых DragAu.</li>
    </ul>

    <p class="pl-2 offertaText">3.2.7. Улучшение качества обслуживания Пользователя и модернизация Сайта путем обработки запросов и заявок от Пользователя; </p>
    <p class="pl-2 offertaText">3.2.8. Статистические   и    иные    исследования   на    основе   обезличенной   информации и, предоставленной Пользователем;</p>
    <p class="pl-2 offertaText">3.2.9. Конкретный объем ПДн, обрабатываемых в указанных выше целях, определен в разделе 5 Политики. </p>


    <p class="offertaText" style="text-align:center;font-weight:bold;">4. Условия и порядок предоставления согласия на обработку персональных данных DragAu</p>


    <p class="pl-2 offertaText">4.1. DragAu не проверяет предоставляемые Пользователем ПДн. В связи с этим DragAu исходит из того, что при предоставлении ПДн на Сайте Пользователь: </p>
    <p class="pl-2 offertaText">4.1.1. Является дееспособным лицом. В случае недееспособности лица, использующего Сайт, согласие на обработку ПДн предоставляется законным представителем Пользователя, который ознакомился и принял условия обработки ПДн, указанные в настоящей Политике.</p>
    <p class="pl-2 offertaText">4.1.2. Указывает достоверную информацию о себе или о представляемом недееспособном лице (п. 4.1.1) в объемах, необходимых для использования Сайта. Пользователь самостоятельно поддерживает предоставленные ПДн в актуальном состоянии. Последствия предоставления Пользователем недостоверной или недостаточной информации определены в Пользовательском соглашении.</p>
    <p class="pl-2 offertaText">4.1.3. Осознает, что информация на Сайте, размещаемая Пользователем о себе, может становиться доступной для других Пользователей Сайта, может быть скопирована и распространена такими Пользователями в случаях, предусмотренных Политикой. </p>
    <p class="pl-2 offertaText">4.2. Пользователь принимает условия Политики и дает DragAu информированное и осознанное согласие на обработку своих ПДн на условиях, предусмотренных Политикой и Законом: </p>
    <p class="pl-2 offertaText">4.2.1. <strong>При регистрации и идентификации на Сайте</strong> — для ПДн, которые Пользователь предоставляет DragAu: путем заполнения формы для регистрации, расположенной в сети Интернет по адресу <a routerLink="/authentication/login" target="_blank" href="">https://prnc2.com/register</a> . Пользователь считается предоставившим согласие на обработку своих ПДн в момент нажатия кнопки «Сохранить профиль».</p>
    <p class="pl-2 offertaText">4.2.2. <strong>При внесении или изменении ПДн в разделе «Персональная информация» Личного кабинета</strong> — при редактировании или дополнении информации в разделе «Персональная информация». Указанные ПДн могут использоваться для выполнения DragAu обязательств, предусмотренных Пользовательским соглашением, условиями использования сервисов Сайта, договорами об оказании услуг. Пользователь считается предоставившим согласие на обработку своих вновь внесенных или измененных ПДн в момент завершения их редактирования. После заполнения любой из граф, указанных в разделе «Персональная информация», ПДн обновляются автоматически.</p>
    <p class="pl-2 offertaText">4.2.3. <strong>При заполнении формы обратной связи</strong>, в том числе заявки на прохождения курса,  заявки на использование других сервисов Сайта — для ПДн, которые Пользователь предоставляет DragAu при заполнении формы обратной связи в сети Интернет на Сайте и электронных сервисах. Пользователь считается предоставившим согласие на обработку своих ПДн, внесенных в поля формы обратной связи, в момент нажатия кнопки,подтверждающей отправку заявки (кнопки могут называться «Отправить», «Оставить заявку», «Записаться», «Получить консультацию» и иным аналогичным образом).</p>
    <p class="pl-2 offertaText">4.2.4. <strong>При любом использовании Сайта</strong> — для ПДн, которые автоматически передаются DragAu в процессе использования Сайта с помощью установленного на устройстве Пользователя программного обеспечения. Пользователь считается предоставившим согласие на обработку своих ПДн в момент начала использования Сайта.</p>
    <p class="pl-2 offertaText">4.3. Совершая действия, указанные в п. 4.2 настоящей Политики, Пользователь дает DragAu согласие на обработку соответствующих ПДн, перечень которых указан в разделе 5 настоящей Политики, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление третьим лицам, доступ), обезличивание, блокирование, удаление, уничтожение ПДн с использованием и без использования средств автоматизации в соответствии с целями, указанными в разделе 3 настоящей Политики.</p>
    <p class="pl-2 offertaText">4.4. Предоставленное Пользователем в соответствии с настоящей Политикой согласие на обработку ПДн действует со дня предоставления такого согласия и в течение срока, необходимого для достижения целей обработки ПДн или до момента отзыва Пользователем указанного согласия, если иное не предусмотрено действующим законодательством РК. Предоставленное Пользователем в соответствии с настоящей Политикой согласие на обработку ПДн может быть в любой момент отозвано Пользователем. Пользователь может отозвать предоставленное ранее DragAu согласие на обработку ПДн одним из следующих способов: </p>
    <ul class="pl-5 offertaText">
      <li>направив соответствующее заявление почтой по адресу: 050010 Алматы ул. Бегалина д.42;</li>
      <li>направив соответствующее заявление в форме электронного документа на адрес электронной почты <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a></li>
    </ul>


    <p class="offertaText" style="text-align:center;font-weight:bold;">5. Персональные данные, обрабатываемые DragAu</p>

    <p class="pl-2 offertaText">5.1. Обработка ПДн Пользователей осуществляется на следующих условиях, используя для каждой цели соответствующий объем обрабатываемых ПДн: </p>
    <p class="pl-2 offertaText">5.1.1. Регистрация на Сайте:</p>
    <ul class="pl-5 offertaText">
      <li>имя</li>
      <li>фамилия</li>
      <li>адрес электронной почты</li>
      <li>область деятельности</li>
      <li>страна</li>
      <li>город </li>
    </ul>
    <p class="pl-2 offertaText">5.1.2. Идентификация на Сайте:</p>
    <ul class="pl-5 offertaText">
      <li>номер телефона </li>
      <li>аккаунт Google</li>

    </ul>
    <p class="pl-2 offertaText">5.1.3. Отображение профиля для иных Пользователей Сайта: </p>
    <ul class="pl-5 offertaText">
      <li>фамилия и имя</li>
    </ul>
    <p class="pl-2 offertaText">5.1.4. Установление и поддержание связи между Пользователем и DragAu, консультирование по вопросам оказания услуг:</p>
    <ul class="pl-5 offertaText">
      <li>фамилия и имя  </li>
      <li>номер телефона </li>
      <li>адрес электронной почты </li>
      <li>данные аккаунтов в социальных сетях и мессенджерах </li>
    </ul>
    <p class="pl-2 offertaText">5.1.5. Заключение договоров, исполнение обязательств по ним </p>
    <ul class="pl-5 offertaText">
      <li>фамилия и имя  </li>
      <li>номер телефона </li>

    </ul>
    <p class="pl-2 offertaText">5.1.6. Направление сообщений рекламного характера; информационных рассылок; предоставление информации рекламного характера в устном и письменном виде </p>
    <ul class="pl-5 offertaText">
      <li>фамилия и имя  </li>
      <li>номер телефона </li>
      <li>адрес электронной почты</li>
    </ul>
    <p class="pl-2 offertaText">5.1.7. Размещение фото и видеоматериалов, полученных в ходе оказания услуг DragAu; комментариев Пользователей относительно услуг DragAu </p>
    <ul class="pl-5 offertaText">
      <li>фамилия и имя </li>
      <li>изображение</li>
      <li>данные аккаунтов в социальных сетях (если указаны в разделе «Персональная информация») </li>

    </ul>
    <p class="pl-2 offertaText">5.1.8. Улучшение качества обслуживания Пользователей и модернизация Сайта − файлы cookie </p>
    <ul class="pl-5 offertaText">
      <li>файлы cookie</li>
    </ul>
    <p class="pl-2 offertaText">5.1.9. Статистические и иные исследования на основе обезличенной информации − файлы cookie </p>
    <ul class="pl-5 offertaText">
      <li>файлы cookie</li>
    </ul>


    <p class="offertaText" style="text-align:center;font-weight:bold;">6. Обработка персональных данных </p>
    <p class="pl-2 offertaText">6.1. DragAu обрабатывает ПДн на основе следующих принципов:</p>
    <ul class="pl-5 offertaText">
      <li>законности и справедливой основы;</li>
      <li>ограничения обработки ПДн достижением конкретных, заранее определенных и законных целей;</li>
      <li>недопущения обработки ПДн, несовместимой с целями сбора ПДн;</li>
      <li>недопущения объединения баз данных, содержащих ПДн, обработка которых осуществляется в целях, несовместимых между собой;</li>
      <li>обработки только тех ПДн, которые отвечают целям их обработки;</li>
      <li>соответствия содержания и объема обрабатываемых ПДн заявленным целям обработки;</li>
      <li>недопущения обработки ПДн, избыточных по отношению к заявленным целям их обработки;</li>
      <li>обеспечения точности, достаточности и актуальности ПДн по отношению к целям обработки ПДн;</li>
      <li>уничтожения либо обезличивания ПДн по достижении целей их обработки или в случае утраты необходимости в достижении этих целей, получении от Пользователей требования об уничтожении ПДн, поступлении от Пользователя отзыва согласия на обработку ПДн.</li>
    </ul>
    <p class="pl-2 offertaText">6.2. DragAu производит обработку ПДн Пользователей (запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение) с использованием баз данных на территории РК.</p>
    <p class="pl-2 offertaText">6.3. Обработка ПДн Пользователей производится DragAu как с использованием автоматизированных средств, так и без них</p>
    <p class="pl-2 offertaText">6.4. DragAu и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять ПДн без согласия субъекта ПДн, если иное не предусмотрено  законом РК.</p>
    <p class="pl-2 offertaText">6.5. Обработка ПДн Пользователя включает совершение DragAu следующих действий: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление третьим лицам, доступ), обезличивание, блокирование, удаление, уничтожение. </p>

    <p class="pl-2 offertaText">6.6. Хранение ПДн Пользователей осуществляется на электронных носителях. При обработке ПДн с целью исполнения обязательств по соглашениям с Пользователем DragAu может извлекать ПДн и хранить их на материальных носителях. Хранение таких ПДн осуществляется в течение срока, установленного законодательством Республики Казахстан об образовании и архивном деле. Хранение ПДн осуществляется (в зависимости от того, какое событие наступит раньше): </p>
    <ul class="pl-5 offertaText">
      <li>до момента их удаления Пользователем в разделе «Персональная информация» в личном кабинете на Сайте; </li>
      <li>до момента их уничтожения DragAu — в случае поступления от Пользователя отзыва согласия на обработку ПДн или требования об уничтожении ПДн; </li>
      <li>до момента истечения срока действия согласия. </li>
    </ul>





    <p class="pl-2 offertaText">6.7. DragAu вправе осуществлять передачу ПДн третьим лицам с соблюдением следующих условий:</p>
    <ul class="pl-5 offertaText">
      <li>Третье лицо осуществляет обработку ПДн с использованием баз данных на территории Республики Казахстан.</li>
      <li>Третье лицо обеспечивает конфиденциальность ПДн при их обработке и использовании; обязуется не раскрывать иным лицам, а также не распространять ПДн Пользователей без их согласия.</li>
      <li>Третье лицо гарантирует соблюдение следующих мер по обеспечению безопасности ПДн при их обработке: использование средств защиты информации; обнаружение и фиксация фактов несанкционированного доступа к персональным данным и принятие мер по восстановлению ПДн; ограничение доступа к персональным данным; регистрация и учет действий с персональными данными; контроль и оценка эффективности применяемых мер по обеспечению безопасности ПДн</li>
      <li>Перечень разрешенных способов обработки ПДн: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение. Третьему лицу запрещено осуществлять передачу и распространение ПДн. </li>
    </ul>

    <p class="pl-2 offertaText">Цели передачи ПДн:</p>
    <ul class="pl-5 offertaText">
      <li>Оптимизация DragAu рассылки сообщений информационного и рекламного характера и коммуникации DragAu с Пользователями относительно услуг DragAu. В этом случае третьему лицу для коммуникации с Пользователем передаются следующие ПДн Пользователей: фамилия, имя; номер телефона; адрес электронной почты.</li>
      <li>Направление Пользователям информационных рассылок. В этом случае третьему лицу передаются следующие ПДн Пользователей: фамилия, имя; адрес электронной почты. Каждая информационная рассылка предоставляет Пользователю возможность отказаться от получения таких рассылок.</li>
      <li>Исполнение DragAu условий договоров и соглашений, заключенных с Пользователями Сайта, с привлечением третьих лиц. В этом случае третьему лицу передаются следующие ПДн Пользователей: фамилия, имя; адрес электронной почты. Объем передаваемых ПДн Пользователя третьим лицам может быть также уточнен в условиях договора</li>
    </ul>


    <p class="offertaText" style="text-align:center;font-weight:bold;">7. Меры, принимаемые DragAu для защиты персональных данных </p>
    <p class="pl-2 offertaText">7.1. DragAu принимает необходимые и достаточные правовые, организационные и технические меры для защиты информации, предоставляемой Пользователями, от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц. Такие действия, в частности, включают:</p>
    <ul class="pl-5 offertaText">
      <li>Назначение лица, ответственного за обработку ПДн;</li>
      <li>Применение организационных и технических мер по обеспечению безопасности ПДн при их обработке в информационных системах;</li>
      <li>Контроль фактов несанкционированного доступа к ПДн и принятие мер по недопущению подобных инцидентов в дальнейшем;</li>
      <li>Контроль за принимаемыми мерами по обеспечению безопасности ПДн и уровнем защищенности информационных систем ПДн.</li>
    </ul>


    <p class="offertaText" style="text-align:center;font-weight:bold;">8. Права пользователя </p>

    <p class="pl-2 offertaText">8.1. Пользователь принимает решение о предоставлении его ПДн и дает согласие на их обработку свободно, своей волей и в своем интересе. Пользователь выражает свое согласие на обработку ПДн в порядке, приведенном в п. 4.2 настоящей Политики</p>
    <p class="pl-2 offertaText">8.2. Пользователь имеет право на получение у DragAu информации, касающейся обработки его ПДн</p>
    <p class="pl-2 offertaText">8.3. Пользователь вправе направлять DragAu свои запросы и требования (далее – Обращение), в том числе относительно использования его ПДн, а также отзыва согласия на обработку ПДн. Обращение может быть направлено следующими способами:</p>
    <p class="pl-2 offertaText">8.3.1. В письменной форме по адресу DragAu (раздел 11 Политики); </p>
    <p class="pl-2 offertaText">8.3.2. В форме электронного документа (скан-, фотокопия документа). Документ должен быть направлен с адреса электронной почты Пользователя, указанного им при регистрации на Сайте по адресу электронной почты DragAu:<a href="mailto:info@comproducts.ru"> info@comproducts.ru.</a></p>


    <p class="offertaText" style="text-align:center;font-weight:bold;">9. Актуализация, исправление, удаление и уничтожение ПДн</p>
    <p class="pl-2 offertaText">9.1. DragAu обязуется сообщить Пользователю или его представителю , информацию о наличии ПДн, относящихся к этому Пользователю, а также предоставить возможность ознакомления с этими ПДн при обращении Пользователя или его представителя в течение 30 (тридцати) дней с даты получения запроса Пользователя или его представителя.</p>
    <p class="pl-2 offertaText">9.2. DragAu обязуется предоставить безвозмездно Пользователю или его представителю возможность ознакомления с ПДн, относящимися к этому Пользователю.</p>
    <p class="pl-2 offertaText">9.3. В срок, не превышающий 7 (семи) рабочих дней со дня предоставления Пользователем или его представителем сведений, подтверждающих, что ПДн являются неполными, неточными или неактуальными, DragAu обязуется внести в них необходимые изменения.</p>
    <p class="pl-2 offertaText">9.4. В срок, не превышающий 7 (семи) рабочих дней со дня представления Пользователем или его представителем сведений, подтверждающих, что такие ПДн являются незаконно полученными или не являются необходимыми для заявленной цели обработки, DragAu обязуется уничтожить такие ПДн. DragAu обязуется также уведомить Пользователя или его представителя о внесенных изменениях и предпринятыхмерах и принять разумные меры для уведомления третьих лиц, которым ПДн этого Пользователя были переданы</p>
    <p class="pl-2 offertaText">9.5. В случае подтверждения факта неточности ПДн DragAu на основании сведений, представленных Пользователем или его представителем либо уполномоченным органом по защите прав субъектов ПДн, или иных необходимых документов обязуется уточнить ПДн либо обеспечить их уточнение (если обработка ПДн осуществляется другим лицом, действующим по поручению DragAu) в течение 7 (семи) рабочих дней со дня представления таких сведений и снять блокирование ПДн</p>
    <p class="pl-2 offertaText">9.6. DragAu обязуется прекратить обработку ПДн или обеспечить прекращение обработки ПДн лицом, действующим по поручению DragAu: </p>
    <ul class="pl-5 offertaText">
      <li>в случае выявления неправомерной обработки ПДн, осуществляемой DragAu или лицом, действующим по поручению DragAu, в срок, не превышающий 3 (трех) рабочих дней с даты этого выявления; </li>
      <li>в случае отзыва Пользователем согласия на обработку его ПДн; </li>
      <li>в случае достижения цели обработки ПДН.</li>
    </ul>
    <p class="pl-2 offertaText">9.7. DragAu обязуется уничтожить ПДн Пользователя или обеспечить их уничтожение (если обработка ПДн осуществляется другим лицом, действующим по поручению DragAu) в срок, не превышающий 30 (тридцати) дней с даты достижения цели обработки ПДн. </p>
    <p class="pl-2 offertaText">9.8. В случае отсутствия возможности уничтожения ПДн в течение указанного срока DragAu осуществляет блокирование таких ПДн или обеспечивает их блокирование (если обработка ПДн осуществляется другим лицом, действующим по поручению DragAu) и обеспечивает уничтожение ПДн в срок не более чем 6 (шесть) месяцев, если иной срок не установлен законами Республики Казахстан. </p>



    <p class="offertaText" style="text-align:center;font-weight:bold;">10. Изменение политики </p>

    <p class="pl-2 offertaText">10.1. DragAu оставляет за собой право вносить изменения в Политику. Пользователь обязан при каждом новом использовании Сайта ознакомиться с текстом Политики. </p>
    <p class="pl-2 offertaText">10.2. Новая редакция Политики вступает в силу с момента ее размещения в соответствующем разделе Сайта DragAu. Продолжение пользования Сайтом или его сервисами после публикации новой редакции Политики означает принятие Политики и ее условий Пользователем. В случае несогласия с условиями Политики Пользователь должен немедленно прекратить использование Сайта и его сервисов</p>
    <p class="pl-2 offertaText">10.3. Все вопросы по настоящей Политике просим направлять на адрес электронной почты DragAu <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a></p>



    <p class="offertaText" style="text-align:center;font-weight:bold;">11. сведения о DragAu</p>

    <p class="pl-2 offertaText mb-0">ТОО “DragAu”</p>
    <p class="pl-2 offertaText mb-0">Адрес: 050010 Алматы ул. Бегалина д.42</p>
    <p class="pl-2 offertaText mb-0">РНН 600 900 545 436</p>
    <p class="pl-2 offertaText mb-0">БИН 041 040 005 066</p>
    <p class="pl-2 offertaText mb-0">ИИК (IBAN) KZ316017131000014558</p>
    <p class="pl-2 offertaText mb-0">БИК: HSBKKZKX</p>
    <p class="pl-2 offertaText mb-0">Банк: АО «Народный Банк Казахстана»</p>
    <p class="pl-2 offertaText mb-5">Кбе 17</p>



  </div>





  <div class="ab-footer" fxLayout="row" fxFlex="25.55">
    <div fxFlex="25">
      <div fxLayout="column" fxFlexFill>
        <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 12em;" />
        <img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />
        <div fxFlex fxLayoutAlign="start center">
          <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
          <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
          <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />
          <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard" />
          <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa" />

        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="20">
      <div fxLayout="column" fxFlexFill>

        <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown1()">{{'Courses' | translate}}</a>
        <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown2()">{{'Education_process' | translate}}</a>
        <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown3()">{{'Comments' | translate}}</a>
        <a fxFlex="30" class="mat-title footer-text" (click)="goDown4()">{{'About_us' | translate}}</a>

      </div>



    </div>
    <div fxLayout="column" fxFlex="30">
      <div fxLayout="column" fxFlexFill>
        <a fxFlex="20" class="mat-title footer-text" routerLink="/additional-services" style="margin-bottom: 0.55vh;">{{'Additional_services' | translate}}</a>
        <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="margin-bottom: 1.20vh;">{{'support' | translate}}</a>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
        </div>
        <div *ngIf="currentCountry==='Россия'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/oferta">{{'public_offer_1' | translate}}</a>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh; color: white !important;" routerLink="/ofertakz">{{'public_offer_1' | translate}}</a>
        </div>
        <div *ngIf="currentCountry==='Россия'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>
        </div>

      </div>
    </div>

    <div fxLayout="column" fxFlex="30">
      <div fxLayout="column" fxFlexFill>
        <div *ngIf="currentCountry==='Россия'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>
        </div>
        <div *ngIf="currentCountry==='Россия'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>
        </div>
        <a fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>

      </div>
    </div>

  </div>
</div>

<mat-menu #langMenu="matMenu">
  <button mat-menu-item *ngFor="let language of translate.getLangs()"
          (click)="switchLang(language)">
    <span>{{language | translate}}</span>
  </button>
</mat-menu>

<mat-menu #contactMenu="matMenu">
  <button mat-menu-item
          (click)="cabinet()">
    <span>{{'cabinet' | translate}}</span>
  </button>
  <button mat-menu-item
          (click)="logout()">
    <span>{{'logout' | translate}}</span>
  </button>
</mat-menu>
