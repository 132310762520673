// meta.service.ts

import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private meta: Meta, private route: ActivatedRoute) { }

  setCanonicalURL(url: string) {
    this.meta.updateTag({ rel: 'canonical', href: url });
  }
}
