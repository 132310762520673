// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiKey: 'AIzaSyB6O-vUDIJw3LOyGl_eZL4zueJpV2saP5g',
    authDomain: 'remoteeducation.web.app',
    projectId: 'remoteeducation',
    storageBucket: 'remoteeducation.appspot.com',
    messagingSenderId: '590966757827',
    databaseURL: "https://remoteeducation.firebaseio.com",
    appId: "1:590966757827:web:821f432cae432ca8574576",
    measurementId: "G-9CTPWYC7KH"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
