<div class="d-sm-block d-md-block d-lg-none" *ngIf="isBrowser" style="flex-direction:column;display:flex;box-sizing:border-box;">

  <mat-sidenav-container style="width: 100%;">
    <mat-sidenav #sidenav position="end" style="overflow-x:hidden;">
      <div class="sidenav_container sidenav_container-mobile sidenav_container-table" (sidenavClose)="sidenav.toggle()">
        <div fxLayout="column" style="height: 100vh; overflow-x:hidden;">
          <div style="margin-top: 35px;">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;margin-left:18px; text-align:center; padding-left:8px;">
              <div fxLayout="column" fxLayoutAlign="start start" style="">
                <a (click)="goDown1()" class="phone-text">{{ 'Courses' | translate }}</a>
                <a (click)="goDown2()" style=" margin-top: 14px;" class="phone-text">{{ 'Education_process' | translate }}</a>
                <a (click)="goDown3()" style=" margin-top: 14px;" class="phone-text">{{ 'Comments' | translate }}</a>
                <a (click)="goDown4()" style=" margin-top: 14px;" class="phone-text">{{ 'About_us' | translate }}</a>
              </div>
              <button mat-icon-button (click)="sidenav.toggle()" style="margin-right: 6px;top:0;right:0;position:absolute;margin-top:21px;">
                <img style="width:20px;margin-left:10px;margin-right:17px;" src="/images/menu_close_light.svg" alt="menuClose" />
              </button>
            </div>
          </div>

          <div></div>

          <div class=" mt-auto justify-content-around" style="padding-bottom:26px;margin-left:18px;">
            <div *ngIf="currentCountry==='Россия'">
              <p class="phone-mobile phone-table">{{'phone_numru' | translate}}</p>
            </div>
            <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Другая'">
              <p class="phone-mobile phone-table">{{'phone_numkz' | translate}}</p>
            </div>
            <!--<img class="img-logo" src="/images/facebook.svg" alt="Facebook" />
  <img class="img-logo" src="/images/instagram.svg" alt="Instagram" />-->
            <img class="img-logo" src="/images/vk.svg" alt="VK" />
            <img class="img-logo" src="/images/telegram.svg" alt="Telegram" />
          </div>
        </div>
      </div>

    </mat-sidenav>
    <mat-sidenav-content style="height: 100vh; overflow-x: hidden;">
      <!--(onCountryChange)="onCountryChange($event)"
  (sidenavToggle)="sidenav.toggle()"-->
      <app-courses-toolbar (sidenavToggle)="sidenav.toggle()"></app-courses-toolbar>
      <div class="rightimage" style="top:0;">
        <img src="/images/boyMobile.svg" alt="rightImage" />
      </div>

      <div class="" id="" style="padding-right: 2.08vw;
    padding-left: 2.08vw;">
        <div class="row" style="
        margin-top: 5.47vh;
        margin-bottom: 20px;">
          <div class="col">
            <div class="mc-1 mb-3 mat-display-4 title-mobile">PRINCE2 FOUNDATION</div>
          </div>
        </div>



        <shared-foundation-ru></shared-foundation-ru>


        <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Другая'" class="row btnMobile" style="margin-bottom: 5vh;">
          <div class="col">
            <button class=" px-0 buttonstyle btnMobileSize " mat-button color="accent" (click)="buyCourse('F6FACACB-5FB7-ED11-88B6-A92673B5996B')" style="width: 30vw;font-size: 1rem;">{{'take_training' | translate}}</button>
          </div>
        </div>

        <div *ngIf="currentCountry==='Россия'" class="row btnMobile" style="margin-bottom: 5vh;">
          <div class="col">
            <button class=" px-0 buttonstyle btnMobileSize " mat-button color="accent" (click)="buyCourse('443BC788-7D51-EB11-80E1-00155D784603')" style="width: 30vw;font-size: 1rem;">{{'take_training' | translate}}</button>
          </div>
        </div>

        

      </div>

      <div class="">
        <div class="ab-footer-mobile ab-footer-table" fxLayout="column" fxFlex="25.55">
          <div fxFlex="21" style="">
            <div fxLayout="column" fxFlexFill style="">
              <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 30em;" />
              <!--<img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />-->
              <!--<div fxFlex fxLayoutAlign="start" style="padding-top: 1.85vh;">-->
              <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
              <!--<img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
        <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />-->
              <!--</div>-->
            </div>
          </div>
          <div fxLayout="column" class="" fxFlex="22" style="margin-left: 3.73vw; margin-bottom:2vh;">
            <div fxLayout="column" class="pb-4" fxFlexFill>

              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown1()" style="">{{'Courses' | translate}}</a>
              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown2()" style="">{{'Education_process' | translate}}</a>
              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown3()" style="">{{'Comments' | translate}}</a>
              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown4()" style="">{{'About_us' | translate}}</a>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" fxFlex="30" class="py-2" style="margin-left: 3.73vw;">
            <div fxLayout="column" fxFlexFill>
              <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" routerLink="/additional-services" style="">{{'Additional_services' | translate}}</a>
              <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="">{{'support' | translate}}</a>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" routerLink="/oferta" style="">{{'public_offer_1' | translate}}</a>
              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" routerLink="/ofertakz" style="">{{'public_offer_1' | translate}}</a>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>

              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>

              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" class="py-2" fxFlex="30" style="margin-left: 3.73vw;">
            <div fxLayout="column" fxFlexFill>
              <div *ngIf="currentCountry==='Россия'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>

              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>

              </div>
              <div *ngIf="currentCountry==='Россия'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>

              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>

              </div>
              <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" style="" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
            </div>
          </div>
          <div fxLayout="column" fxFlex="22" style="margin-left: 3.73vw;">
            <div fxLayout="row" fxFlexFill>
              <img src="/images/axelos.svg" class="axelos-img-mobile axelos-img-table" alt="axelosLogo" style="" />
              <div fxFlex fxLayoutAlign="start center" style="align-items:center;margin-left: 10px;">
                <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img-mobile footer-contact-img-table " />
          <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img-mobile footer-contact-img-table" />-->
                <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard-mobile" />
                <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa-mobile" />
              </div>

            </div>
          </div>

        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div class="d-none d-lg-inline"*ngIf="isBrowser">

  <div class="rightimage">
    <img src="/images/rightImage.svg" alt="rightImage" />
  </div>

  <app-courses-toolbar></app-courses-toolbar>

  <div class="" id="" style="padding-right: 28.80vw;
    padding-left: 7.70vw;">
    <div class="row" style="
        margin-top: 150px;
        margin-bottom: 20px;">
      <div class="col">
        <div class="mc-1 mb-3 mat-display-4">PRINCE2 FOUNDATION</div>
      </div>
    </div>

    <shared-foundation-ru></shared-foundation-ru>

    <div *ngIf="currentCountry==='Россия'" class="row" style="margin-bottom:17.22vh;">
      <div class="col">
        <button class=" px-0 buttonstyle" mat-button color="accent" (click)="buyCourse('443BC788-7D51-EB11-80E1-00155D784603')" style="width: 15vw;font-size: 1.125em;">{{'take_training' | translate}}</button>
      </div>
    </div>
    <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" class="row" style="margin-bottom:17.22vh;">
      <div class="col">
        <button class=" px-0 buttonstyle" mat-button color="accent" (click)="buyCourse('F6FACACB-5FB7-ED11-88B6-A92673B5996B')" style="width: 15vw;font-size: 1.125em;">{{'take_training' | translate}}</button>
      </div>
    </div>
  </div>

  <div class="ab-footer" fxLayout="row" fxFlex="25.55">
    <div fxFlex="25">
      <div fxLayout="column" fxFlexFill>
        <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 12em;" />
        <img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />
        <div fxFlex fxLayoutAlign="start center">
          <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
          <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
          <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />
          <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard" />
          <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa" />

        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="20">
      <div fxLayout="column" fxFlexFill>

        <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown1()">{{'Courses' | translate}}</a>
        <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown2()">{{'Education_process' | translate}}</a>
        <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown3()">{{'Comments' | translate}}</a>
        <a fxFlex="30" class="mat-title footer-text" (click)="goDown4()">{{'About_us' | translate}}</a>

      </div>



    </div>
    <div fxLayout="column" fxFlex="30">
      <div fxLayout="column" fxFlexFill>
        <a fxFlex="20" class="mat-title footer-text" routerLink="/additional-services" style="margin-bottom: 0.55vh;">{{'Additional_services' | translate}}</a>
        <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="margin-bottom: 1.20vh;">{{'support' | translate}}</a>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
        </div>
        <div *ngIf="currentCountry==='Россия'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/oferta">{{'public_offer_1' | translate}}</a>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh; color: white !important;" routerLink="/ofertakz">{{'public_offer_1' | translate}}</a>
        </div>
        <div *ngIf="currentCountry==='Россия'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>
        </div>

      </div>
    </div>

    <div fxLayout="column" fxFlex="30">
      <div fxLayout="column" fxFlexFill>
        <div *ngIf="currentCountry==='Россия'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>
        </div>
        <div *ngIf="currentCountry==='Россия'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>
        </div>
        <a fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>

      </div>
    </div>

  </div>
</div>
<div class="d-none d-lg-inline" *ngIf="!isBrowser">

    <div class="rightimage">
        <img src="/images/rightImage.svg" alt="rightImage" />
    </div>

    <app-courses-toolbar></app-courses-toolbar>

    <div class="" id="" style="padding-right: 28.80vw;
    padding-left: 7.70vw;">
        <div class="row" style="
        margin-top: 150px;
        margin-bottom: 20px;">
            <div class="col">
                <div class="mc-1 mb-3 mat-display-4">PRINCE2 FOUNDATION</div>
            </div>
        </div>
        <div>

          Онлайн-курс PRINCE2, PRINCE2 на русском, Экзамен PRINCE2, Сертификация PRINCE2, PRINCE2 в России, Видеоуроки PRINCE2, Стандарт PRINCE2, Методология PRINCE2, Онлайн-курс обучения PRINCE2, Обучение Принц2, Курсы Принц2, Принц2 на русском,
        </div>
        <shared-foundation-ru></shared-foundation-ru>

        <div class="row" style="margin-bottom:17.22vh;">
            <div class="col">
                <button class=" px-0 buttonstyle" mat-button color="accent" (click)="buyCourse('443BC788-7D51-EB11-80E1-00155D784603')" style="width: 15vw;font-size: 1.125em;">{{'take_training' | translate}}</button>
            </div>
        </div>
    </div>

    

    <div class="ab-footer" fxLayout="row" fxFlex="25.55">
      
      <div fxFlex="25">
        <div fxLayout="column" fxFlexFill>
          <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 12em;" />
          <img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />
          <div fxFlex fxLayoutAlign="start end">
            <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
            <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
            <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="20">
        <div fxLayout="column" fxFlexFill>

          <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" data-menuanchor="courses" href="/#courses">{{'Courses' | translate}}</a>
          <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" data-menuanchor="education" href="/#education">{{'Education_process' | translate}}</a>
          <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" data-menuanchor="comments" href="/#comments">{{'Comments' | translate}}</a>
          <a fxFlex="30" class="mat-title footer-text" data-menuanchor="about" href="/#about">{{'About_us' | translate}}</a>

        </div>



      </div>
      <div fxLayout="column" fxFlex="30">
        <div fxLayout="column" fxFlexFill>
          <a fxFlex="20" class="mat-title footer-text" routerLink="/additional-services" style="margin-bottom: 1.20vh;">{{'Additional_services' | translate}}</a>
          <a fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="margin-bottom: 1.20vh;">{{'support' | translate}}</a>
          <a fxFlex="20" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" innerHTML="{{'public_offer_1' | translate}}"></a>
          <a fxFlex="30" class="mat-title footer-text ofertaCss" style="color: white !important;" innerHTML="{{'Personal_data_processing_policy' | translate}}"></a>
        </div>
      </div>

      <div fxLayout="column" fxFlex="30">
        <div fxLayout="column" fxFlexFill>
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'address' | translate}}</p>
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_num' | translate}}</p>
          <a fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
        </div>
      </div>

    </div>
</div>
