import { Component, OnInit, Output, EventEmitter, Injectable } from '@angular/core';
import { countryService } from '../services/countryService'; // service

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-courses-navbar',
  templateUrl: './courses-navbar.component.html',
  styleUrls: ['./courses-navbar.component.css']
})
export class CoursesNavbarComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();
  @Output() courses = new EventEmitter();
  @Output() education = new EventEmitter();
  @Output() feedback = new EventEmitter();
  @Output() aboutUs = new EventEmitter();

  countryBurger: string;
  countrySwap: string = 'RU';

  get currentCountry(): string {
    return this.countryService.get();
  }
  constructor(
    private countryService: countryService,
  ) {
    this.countryBurger = localStorage.getItem('option');



  }

  ngOnInit(): void {
  }

 

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }



  public slideTo(section: string) {
    this.onSidenavClose();
    switch (section) {
      case "courses": {
        this.courses.emit();
        break;
      }
      case "education": {
        this.education.emit();
        break;
      }
      case "feedback": {
        this.feedback.emit();
        break;
      }
      case "aboutUs": {
        this.aboutUs.emit();
        break;
      }
      default: {
        break;
      }
    }
  }

}
