import { Observable, of } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
//import * as translationEn from '../assets/i18n/en-US.json';
//import * as translationRu from '../assets/i18n/ru-RU.json';

/*const TRANSLATIONS = {
    "en-US": translationEn,
    "ru-RU": translationRu,
};*/

import {
    makeStateKey,
    StateKey,
    TransferState
} from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
//import { isPlatformBrowser } from '@angular/common';

export class TranslateBrowserLoader implements TranslateLoader {
    constructor(private http: HttpClient, private transferState: TransferState) { }

    public getTranslation(lang: string): Observable<any> {
        const key: StateKey<number> = makeStateKey<number>(
            'transfer-translate-' + lang
        );
        const data = this.transferState.get(key, null);

        // First we are looking for the translations in transfer-state, 
        // if none found, http load as fallback
        if (data) {
            return new Observable((observer) => {
                observer.next(data);
                observer.complete();
            });
        } else {
            return new TranslateHttpLoader(this.http).getTranslation(lang);
        }
    }
}

/*export class JSONModuleLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return of(TRANSLATIONS[lang]);
    }
}*/

export function translateBrowserLoaderFactory(
    httpClient: HttpClient,
    transferState: TransferState,
    platform: any
) {
    //if (isPlatformBrowser(platform)) {
        return new TranslateBrowserLoader(httpClient, transferState);
    /*} else {
        return new JSONModuleLoader();
    }*/
    
}