import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filename' })
export class FilenamePipe implements PipeTransform {
    transform(name: string) {
        if (!name || name === undefined) {
            return null;
        }
        return name.split('.')[0];
    }
}