import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatePipe } from './pipes/state.pipe';
import { StateChangePipe } from './pipes/statechange.pipe';
import { StateChaneIconPipe } from './pipes/statechangeicon.pipe';
import { BoolPipe } from './pipes/bool.pipe';
import { NamePipe } from './pipes/name.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ShortString } from './pipes/shortstring';
import { ShortLang } from './pipes/short-lang';
import { ConfirmArgs, ConfirmComponent } from './dialogs/confirm/confirm.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { FirstnamePipe } from './pipes/firstname.pipe';
import { LastnamePipe } from './pipes/lastname.pipe';
import { LessonStatePipe } from './pipes/lesson_state.pipe';
import { InfiniteScrollComponentComponent } from './infinite-scroll-component/infinite-scroll-component.component';
import { SafePipe } from './pipes/safe-pipe.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialPipe } from './pipes/material.pipe';
import { FilenamePipe } from './pipes/filename';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { ArrayNotEmptyPipe } from './pipes/array-not-empty.pipe';
import { FoundationRuSharedComponent } from './courses/foundation-ru-shared/foundation-ru-shared.component';
import { PractitionerRuSharedComponent } from './courses/practitioner-ru-shared/practitioner-ru-shared.component';
import { FoundationEnSharedComponent } from './courses/foundation-en-shared/foundation-en-shared.component';
import { PractitionerEnSharedComponent } from './courses/practitioner-en-shared/practitioner-en-shared.component';
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
import { LangMenuPipe } from './pipes/lang_menu.pipe';
import { MessageDescPipe } from './pipes/message_desc.pipe';
import { AnswerResultPipe } from './pipes/answer_result.pipe';
import { PaymentDetailsComponent } from './dialogs/payment-details/payment-details.component';
import { NotDesktopDialogComponent } from './dialogs/not-desktop-dialog/not-desktop-dialog.component';
import { LocalizedDatePipe } from './pipes/localized_date.pipe';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [StatePipe, StateChangePipe, BoolPipe,
        StateChaneIconPipe, NamePipe,
        ShortString, ShortLang, ConfirmComponent,
        FirstnamePipe, LastnamePipe, LessonStatePipe, InfiniteScrollComponentComponent, SafePipe, MaterialPipe,
        FilenamePipe, FilterArrayPipe, ArrayNotEmptyPipe, FoundationRuSharedComponent, PractitionerRuSharedComponent,
        FoundationEnSharedComponent, PractitionerEnSharedComponent, MinuteSecondsPipe,
    LangMenuPipe, MessageDescPipe, AnswerResultPipe, PaymentDetailsComponent, NotDesktopDialogComponent,
    LocalizedDatePipe
    ],
  imports: [
      CommonModule,
      MatDialogModule,
      MatProgressSpinnerModule,
      MatIconModule,
      MatSelectModule,
      MatListModule,
      FormsModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatInputModule,
      FlexLayoutModule,
      TranslateModule.forChild({
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }),
    ],
    exports: [StatePipe, StateChangePipe, BoolPipe,
        StateChaneIconPipe, NamePipe,
        ShortString, ShortLang, ConfirmComponent,
        FirstnamePipe, LastnamePipe, LessonStatePipe,
        InfiniteScrollComponentComponent, SafePipe, MaterialPipe,
        FilenamePipe, FilterArrayPipe, ArrayNotEmptyPipe, FoundationRuSharedComponent, PractitionerRuSharedComponent,
        FoundationEnSharedComponent, PractitionerEnSharedComponent,
        MinuteSecondsPipe, PaymentDetailsComponent,
        LangMenuPipe, MessageDescPipe, AnswerResultPipe,
      NotDesktopDialogComponent,
      LocalizedDatePipe
    ]
})
export class SharedModule { }
