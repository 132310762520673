import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../../../api-authorization/authorize.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../app.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-practitioner-ru',
  templateUrl: './practitioner-ru.component.html',
  styleUrls: ['./practitioner-ru.component.css']
})
export class PractitionerRuComponent implements OnInit {

  isAuthorized?: boolean = null;

    constructor(public readonly authService: AuthorizeService, private router: Router) {
    this.authService.afAuth.authState.subscribe(user => {
      this.isAuthorized = user !== null;
    });
  }

  ngOnInit(): void {
    }

    buyCourse(course: string) {
        this.router.navigate(['register', { course: course }]);
    }

}
