import { Pipe, PipeTransform } from '@angular/core';
import { ContactModel } from '../../models/ContactModel';

@Pipe({ name: 'firstname' })
export class FirstnamePipe implements PipeTransform {
    transform(model: any) {
        if (!model || model === undefined) {
            return null;
        }
        return model?.contact?.firstName;
    }
}