import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GlobalObjectService } from '../../global-object.service';

@Component({
  selector: 'infinite-scroll',
  templateUrl: './infinite-scroll-component.component.html',
  styleUrls: ['./infinite-scroll-component.component.css']
})


export class InfiniteScrollComponentComponent implements OnDestroy {
    @Input() options = {};
    @Output() scrolled = new EventEmitter();
    @ViewChild('anchor', { static: false }) anchor: ElementRef<HTMLElement>;
    @Input() showDidider = true;

    private observer: IntersectionObserver;

    constructor(private host: ElementRef,
        public windowRef: GlobalObjectService,
        @Inject(PLATFORM_ID) private platformId: object,    ) { }

    get element() {
        return this.host.nativeElement;
    }

    ngAfterViewInit() {
        const options = {
            root: null,
            ...this.options
        };

        this.observer = new IntersectionObserver(([entry]) => {
            entry.isIntersecting && this.scrolled.emit();
        }, options);


        this.observer.observe(this.anchor.nativeElement);

        console.log(this.anchor.nativeElement);
    }

    private isHostScrollable() {
        if (isPlatformBrowser(this.platformId)) {
            const style = this.windowRef.getWindow().getComputedStyle(this.element);

            return style.getPropertyValue('overflow') === 'auto' ||
                style.getPropertyValue('overflow-y') === 'scroll';
        }
        else {
            return false;
        }
    }

    ngOnDestroy() {
        this.observer.disconnect();
    }
}