import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PreviousRouteService {
    constructor(
        @Inject(PLATFORM_ID) public platformId: object
    ) { }

    public setPreviousUrl(url: string) {
        if (isPlatformBrowser(this.platformId)) {
            sessionStorage.setItem('previousUrl', url);
        }
    }

    public getPreviousUrl() {
        if (isPlatformBrowser(this.platformId)) {
            const previousUrl = sessionStorage.getItem('previousUrl');
            return previousUrl;
        }
        return null;
    }

    public deletePreviousUrlKey() {
        if (isPlatformBrowser(this.platformId)) {
            sessionStorage.removeItem('previousUrl');
        }
    }
}