import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../app/api.service';
import { ContactModel } from '../app/models/ContactModel';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {
    public user: firebase.User;
    public isAuthLoaded = false;
    public googleProvider = new firebase.auth.GoogleAuthProvider();

    public _contact: BehaviorSubject<ContactModel> = new BehaviorSubject<ContactModel | null>(null);
    public contact = this._contact.asObservable();

    confirmationResult: any;

    constructor(public afAuth: AngularFireAuth, public router: Router,
        public translate: TranslateService, public apiService: ApiService) {

        this.afAuth.authState.subscribe(user => {
            if (user) {
                this.user = user;
                console.log("new user");
                console.log(user);
                localStorage.setItem('user', JSON.stringify(this.user));
                this.reloadContact();

                /*if (localStorage.getItem('contact')) {
                    //this._contact.next(JSON.parse(localStorage.getItem('contact')));
                    console.log("localStorage");
                    console.log(localStorage.getItem('contact'));
                    this._contact.next(JSON.parse(localStorage.getItem('contact')));
                    
                }*/
            } else {
                localStorage.setItem('user', null);
            }
            this.isAuthLoaded = true;
        });
        
        /*console.log(this.contact);
        if (!this.contact) {
            console.log("updateContact");
            this.updateContact();
        }*/
    }

    getAccessToken(): Observable<string> {
        return this.afAuth.idToken;
    }

    get isAuthenticated(): boolean {
        return this.afAuth.authState !== null;
    } 

    /*getContact(): Observable<ContactModel> {
        if (this.contact !== null) {
            console.log(this.contact);
            return of(this.contact);
        } else {
            if (this.user) {
                //console.log("this.apiService.getContact()");
                return EMPTY;
            }
            else {
                //console.log("user is null");
                return EMPTY;
            }
        }
    }*/

    reloadContact() {
        if (!this.isAuthenticated || !this.user) {
            return;
        }

        setTimeout(() => {
            this.apiService.getContact()
                .pipe(first())
                .subscribe(data => {
                    this._contact.next(data);
                    localStorage.setItem('contact', JSON.stringify(data));
                });
        }, 500);


    }

    logout() {
        this.afAuth.signOut().then(r => {
            this.router.navigate(["/"]);
            this.user = null;
            this._contact.next(null);
            localStorage.setItem('user', null);
            localStorage.setItem('contact', null);
        });
    }

    async sendPhoneVerification(appVerifier: any, prefix: string, phone_number: string, callbackObj: any) {
        this.afAuth.signOut().then(r => {
            this.afAuth.useDeviceLanguage();

            const phoneNumberString = prefix + phone_number.toString();

            this.afAuth.signInWithPhoneNumber(phoneNumberString, appVerifier)
                .then((confirmationResult) => {
                    callbackObj.sent = true;
                    this.confirmationResult = confirmationResult;
                    console.log(confirmationResult);
                    //const verification = prompt('Enter verification code');
                })
                .catch((err) => {
                    console.log('sms not sent', err);
                    callbackObj.setError(err.message);
                });
        });
    }

    GoogleAuth(callbackObj: any) {
        return this.AuthLogin(this.googleProvider, callbackObj);
    }

    AuthLogin(provider, callbackObj: any) {
        return this.afAuth.signInWithPopup(provider)
            .then((good) => {
                localStorage.setItem('user', JSON.stringify(good.user));
                callbackObj.onCodeVerified();
                this.reloadContact();
            }).catch((bad) => {
                callbackObj.setError(bad.message);
            })
    }

    async verifyCode(code: string, callbackObj: any) {
        if (code !== null) {
            this.confirmationResult.confirm(code)
                .then((good) => {
                    localStorage.setItem('user', JSON.stringify(good.user));
                    //console.log(good.user.getIdToken());
                    callbackObj.onCodeVerified();
                    // all checks out
                    this.reloadContact();
                })
                .catch((bad) => {
                    callbackObj.setError(bad.message);
                });
        } else {
            callbackObj.setError('No verification code entered');
        }
    }
}
