import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from '../api-authorization/authorize.guard';
import { LoginComponent } from '../api-authorization/login/login.component';
import { FoundationEnComponent } from './courses/foundation-en/foundation-en.component';
import { FoundationRuComponent } from './courses/foundation-ru/foundation-ru.component';
import { PractitionerEnComponent } from './courses/practitioner-en/practitioner-en.component';
import { PractitionerRuComponent } from './courses/practitioner-ru/practitioner-ru.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { canActivate, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { SuccessComponent } from '../api-authorization/payment-result/success/success.component';
import { FailComponent } from '../api-authorization/payment-result/fail/fail.component';
import { AdditionalServicesComponent } from './components/additional-services/additional-services.component';
import { OfertaComponent } from './support/oferta/oferta.component';
import { OfertaKZComponent } from './support/oferta-kz/oferta-kz.component';
import { PrivacyComponent } from './support/privacy/privacy.component';
import { PrivacyKzComponent } from './support/privacy-kz/privacy-kz.component';
import { PayboxComponent } from './support/paybox/paybox.component';

const redirectLoggedInToHome = () => redirectLoggedInTo('cabinet');

export const routes: Routes = [

    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
        data: {
            state: 'main',
        },
        //...canActivate(redirectLoggedInToHome)
    },
    {
        path: 'cabinet',
        loadChildren: () => import('./cabinet/cabinet.module').then(m => m.CabinetModule),
        canActivate: [AuthorizeGuard],
        data: {
            state: 'main',
            title: 'Кабинет',
            breadcrumb: [
                {
                    label: 'Кабинет',
                    url: '/cabinet'
                }
            ]
        },
    },
    {
        path: 'payment/success/:id',
        component: SuccessComponent,
        data: { state: 'success' }
    },
    {
        path: 'payment/fail/:id',
        component: FailComponent,
        data: { state: 'fail' }
    },
    {
        path: 'foundation-en',
        component: FoundationEnComponent,
        data: { state: 'course' }
    },
    {
        path: 'foundation-ru',
        component: FoundationRuComponent,
        data: { state: 'course' }
    },
    {
        path: 'practitioner-en',
        component: PractitionerEnComponent,
        data: { state: 'course' }
    },
    {
        path: 'practitioner-ru',
        component: PractitionerRuComponent,
        data: { state: 'course' }
    },
    {
      path: 'additional-services',
      component: AdditionalServicesComponent,
      data: { state: 'course' }
  },
    {
        path: 'oferta',
        component: OfertaComponent,
        data: { state: 'privacy' }
  },
  {
    path: 'ofertakz',
    component: OfertaKZComponent,
    data: { state: 'privacy' }
  },
    {
        path: 'privacy',
        component: PrivacyComponent,
        data: { state: 'privacy' }
  },

  {
    path: 'privacykz',
    component: PrivacyKzComponent,
    data: { state: 'privacy' }
  },
  {
    path: 'payment-rules',
    component: PayboxComponent,
    data: { state: 'paybox' }
  },
    {
        path: 'authentication',
        component: LoginComponent,
        data: { state: 'authentication' }
    }
    , {
        path: '**',
        component: PageNotFoundComponent,
        data: { state: 'page_not_found' }
    },

    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    //relativeLinkResolution: 'legacy',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    useHash: false,
    initialNavigation: 'enabled'
})],
    exports: [RouterModule]
})
export class AppRouters { }
