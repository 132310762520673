import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { ApplicationPaths } from './api-authorization.constants';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { VerifyPhoneComponent } from './verify-phone/verify-phone.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CountdownModule } from 'ngx-countdown';
import { AngularFireModule } from '@angular/fire';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from './register/register.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../app/shared-module/shared-module.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthorizeInterceptor } from './authorize.interceptor';
import { ErrorDialogService } from '../app/errordialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import localeRu from '@angular/common/locales/ru';
import localeEN from '@angular/common/locales/en';
import { Languages } from '../app/app.component';
import { AuthorizeGuard } from './authorize.guard';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export const firebaseConfig = {
    apiKey: 'AIzaSyB6O-vUDIJw3LOyGl_eZL4zueJpV2saP5g',
    authDomain: 'remoteeducation.firebaseapp.com',
    projectId: 'remoteeducation',
    storageBucket: 'remoteeducation.appspot.com',
    messagingSenderId: '590966757827',
    databaseURL: "https://remoteeducation.firebaseio.com",
    appId: "1:590966757827:web:821f432cae432ca8574576",
    measurementId: "G-9CTPWYC7KH"
};

registerLocaleData(localeEN, Languages[1]);
registerLocaleData(localeRu, Languages[0]);

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['authentication']);


@NgModule({
  imports: [
        CommonModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAnalyticsModule,
        //HttpClientModule,
        NgxMaskModule.forRoot(),
        TranslateModule/*.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })*/,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        FlexLayoutModule,
        MatCheckboxModule,
        CountdownModule,
        BrowserAnimationsModule,
        MatStepperModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        SharedModule,
        MatDialogModule,
        MatAutocompleteModule,
        RouterModule.forChild(
      [
        { path: ApplicationPaths.Register, component: LoginComponent, canActivate: [AuthorizeGuard] },
        { path: ApplicationPaths.Profile, component: LoginComponent },
            { path: ApplicationPaths.Login, component: LoginComponent, data: { state: 'authentication' } },
        { path: ApplicationPaths.LoginFailed, component: LoginComponent },
        { path: ApplicationPaths.LoginCallback, component: LoginComponent },
        {
            path: "register", component: RegisterComponent, data: { state: 'register' },
            canActivate: [AuthorizeGuard]
        },
      ]
    )
    ],
    providers: [
        ScreenTrackingService,
        //{ provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true }
    ],
  declarations: [LoginComponent, VerifyPhoneComponent, RegisterComponent],
    exports: [LoginComponent]
})
export class ApiAuthorizationModule { }
