import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Inject, Injectable, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizeService } from '../../../api-authorization/authorize.service';
import { Languages } from '../../app.component';
import { GlobalObjectService } from '../../global-object.service';
import { Meta, Title } from '@angular/platform-browser';
import { countryService } from '../../services/countryService'; // service
import { MetaService } from '../../services/meta.service';


@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-foundation-ru',
  templateUrl: './foundation-ru.component.html',
  styleUrls: ['./foundation-ru.component.css']
})
export class FoundationRuComponent {
  private fragment: string;
  get currentCountry(): string {
    return this.countryService.get();
  }
  static TABLET_WIDTH = 1025;
  countrySwap: string = 'RU';
  isBrowser = false;
  desktopMenu: boolean = false;
  @Output() sidenavClose = new EventEmitter();
  constructor(private router: Router, private route: ActivatedRoute, public windowRef: GlobalObjectService, private scroller: ViewportScroller,
    private countryService: countryService, private metaService: MetaService,
    @Inject(PLATFORM_ID) private platformId: object, private meta: Meta,
    private title: Title) {
    this.isBrowser = isPlatformBrowser(this.platformId)
    this.title.setTitle("Онлайн-обучение и экзамен PRINCE2");
    this.meta.updateTag(
      { name: 'description', content: 'Управление проектами на основе метода PRINCE2. Видео курс и сертификация PRINCE2 Foundation на русском языке' });
    this.meta.updateTag(
      { name: 'keywords', content: 'Онлайн-курс PRINCE2, PRINCE2 на русском, Экзамен PRINCE2, Сертификация PRINCE2, PRINCE2 в России, Видеоуроки PRINCE2, Стандарт PRINCE2, Методология PRINCE2, Онлайн-курс обучения PRINCE2, Обучение Принц2, Курсы Принц2, Принц2 на русском' });
    this.countrySwap = localStorage.getItem('option');
    

  }


  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }
  //ngOnInit() {
  //  console.log(this.router.navigate)
  //  this.router.navigate(["/"]);
  //}
  goDown1() {
      this.router.navigate(['/'], { fragment: 'courses' });
  }
 goDown2() {
     this.router.navigate(['/'], { fragment: 'education' });
  }
  goDown3() {
      this.router.navigate(['/'], { fragment: 'comments' });
  }
  goDown4() {
      this.router.navigate(['/'], { fragment: 'about' });
  }

  onCountryChange() {

    this.countrySwap = localStorage.getItem('option');
    /*alert(this.countrySwap);*/
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.desktopMenu = event.target.outerWidth >= FoundationRuComponent.TABLET_WIDTH;
  }


  ngOnInit() {

    // Установка уникального канонического URL для данной страницы
    const canonicalURL = 'https://prnc2.com/foundation-ru'; // Здесь задайте уникальный URL для страницы
    this.metaService.setCanonicalURL(canonicalURL);
    
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });


    /*this.localcountryTest = this.сoursesToolbarComponent.countryTest;*/

  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.isBrowser) {
        this.desktopMenu = this.windowRef.getWindow().outerWidth >= FoundationRuComponent.TABLET_WIDTH;
        try {
          if (this.fragment && !this.desktopMenu) {
            setTimeout(() => {
              document.querySelector('#' + this.fragment).scrollIntoView();
            }, 300);
          }
        } catch (e) { }
      }
    });
  }

    buyCourse(course: string) {
        this.router.navigate(['register', { course: course }]);
    }
}
