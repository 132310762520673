import { Pipe, PipeTransform } from '@angular/core';
import { ContactModel } from '../../models/ContactModel';

@Pipe({ name: 'arrayNotEmpty' })
export class ArrayNotEmptyPipe implements PipeTransform {
    transform(model: Array<any>) {
        if (!model || model === undefined) {
            return false;
        }
        return model.length > 0;
    }
}