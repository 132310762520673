

<div class="d-sm-block d-md-block d-lg-none">

  <mat-sidenav-container style="width: 100%">

    <mat-sidenav #sidenav position="end" style="overflow-x:hidden;">
      <div class="sidenav_container sidenav_container-mobile sidenav_container-table" (sidenavClose)="sidenav.toggle()">
        <div fxLayout="column" style="height: 100vh; overflow-x:hidden;">
          <div style="margin-top: 35px;">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;margin-left:18px; text-align:center; padding-left:8px;">
              <div fxLayout="column" fxLayoutAlign="start start" style="">
                <a (click)="goDown1()" class="phone-text">{{ 'Courses' | translate }}</a>
                <a (click)="goDown2()" style=" margin-top: 14px;" class="phone-text">{{ 'Education_process' | translate }}</a>
                <a (click)="goDown3()" style=" margin-top: 14px;" class="phone-text">{{ 'Comments' | translate }}</a>
                <a (click)="goDown4()" style=" margin-top: 14px;" class="phone-text">{{ 'About_us' | translate }}</a>
              </div>
              <button mat-icon-button (click)="sidenav.toggle()" style="margin-right: 6px;top:0;right:0;position:absolute;margin-top:21px;">
                <img style="width:20px;margin-left:10px;margin-right:17px;" src="/images/menu_close_light.svg" alt="menuClose" />
              </button>
            </div>
          </div>

          <div></div>

          <div class=" mt-auto justify-content-around" style="padding-bottom:26px;margin-left:18px;">
            <div *ngIf="currentCountry==='Россия'">
              <p class="phone-mobile phone-table">{{'phone_numru' | translate}}</p>
            </div>
            <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Другая'">
              <p class="phone-mobile phone-table">{{'phone_numkz' | translate}}</p>
            </div>
            <!--<img class="img-logo" src="/images/facebook.svg" alt="Facebook" />
        <img class="img-logo" src="/images/instagram.svg" alt="Instagram" />-->
            <img class="img-logo" src="/images/vk.svg" alt="VK" />
            <img class="img-logo" src="/images/telegram.svg" alt="Telegram" />
          </div>
        </div>
      </div>

    </mat-sidenav>
    <mat-sidenav-content style="height: 100vh; overflow-x: hidden;">
      <!--(onCountryChange)="onCountryChange($event)"
  (sidenavToggle)="sidenav.toggle()"-->
      <app-courses-toolbar (sidenavToggle)="sidenav.toggle()"></app-courses-toolbar>

      <div class="rightimage" style="top: 0;">
        <img src="/images/boyMobile.svg" alt="rightImage" style="" />
      </div>

      <div class="container991 container425" style="position: relative;">
        <div class="row p644 p643 p375 container425">
          <div class="col">
            <p class="offertaText mb-0" style="">
              Настоящий документ, постоянно размещенный в сети Интернет по сетевому адресу: <a routerLink="/ofertakz" target="_blank" href="">https://prnc2.com/ofertakz</a> является публичной офертой в соответствии со ст.387 и 389 Гражданского кодекса Республики Казахстан
            </p>
          </div>

        </div>
        <div class="row p644 p643second p575 p465">
          <div class="col">
            <p class="offertaText" style="">
              на заключение Договора оказания услуг удаленного доступа (далее - Договор) с любым заинтересованным физическим лицом (далее - Заказчик). Надлежащим акцептом настоящей оферты в соответствии с пунктом 3 статьи 396 ГК является совершение Заказчиком в совокупности следующих действий:
            </p>
          </div>

        </div>

        <p class="offertaText " style="">
          Договора оказания услуг удаленного доступа (далее - Договор) с любым заинтересованным физическим лицом (далее - Заказчик).
        </p>
        <p class="offertaText" style="">
          Надлежащим акцептом настоящей оферты в соответствии со статьей 438 Гражданского кодекса Российской Федерации является совершение Заказчиком в совокупности следующих действий:
        </p>

        <ul class="pl-5 offertaText">
          <li>отправка Заказчиком своих персональных данных через форму заявки на сайте <a href="" target="_blank" routerLink="/">https://prnc2.com/</a></li>
        </ul>

        <p class=" offertaText pt-2">Для получения доступа к отдельным частям Платформы, Пользователю также требуется: </p>
        <ul class="pl-5 offertaText">
          <li>Подать заявку на приобретение дополнительного доступа через функционал Платформы;</li>
          <li>оплатить Тариф Исполнителя в порядке, определенном в Договоре.</li>
        </ul>

        <p class=" pt-2 offertaText">Оплачивая стоимость услуг Исполнителя, Заказчик:</p>
        <ul class="pl-5 offertaText">
          <li>гарантирует достоверность и актуальность сведений, предоставляемых о себе;</li>
          <li>гарантирует, что он является совершеннолетним и полностью дееспособным лицом;</li>
          <li>соглашается, что он самостоятельно несет ответственность за любые последствия, возникающие в результате указания недостоверных, неактуальных или неполных сведений о себе.</li>
        </ul>

        <p class="offertaText">Датой акцепта оферты Заказчиком (датой заключения Договора) считается дата зачисления денежных средств за оказание Исполнителем услуг на расчетный счет Исполнителя.</p>

        <p class="bigBoldText" style=" text-align:center;margin-top: 3vh;">ДОГОВОР</p>
        <p class="offertaText" style="text-align:center;font-weight:bold;">1.Термины и определения</p>

        <p class=" offertaText">В Договоре, если иное прямо не вытекает из текста, указанные ниже термины будут иметь следующие значения:</p>
        <p class=" offertaText mb-1" style="font-weight:bold;">Платформа</p>
        <p class=" offertaText">Объект интеллектуальных прав, информационная система <strong>«Портал обучения PRNC2.com»,</strong> исключительное право на который принадлежит Исполнителю, доступный в сети Интернет по адресу: <a href="" target="_blank" routerLink="/">https://prnc2.com/</a>, состоящий из совокупности представленных в объективной форме данных и команд, предназначенных для функционирования ЭВМ и других компьютерных устройств в целях получения определенного результата, включая программную оболочку для интерактивного (мультимедийного) взаимодействия с содержащейся в программе информацией и порождаемые ею аудиовизуальные отображения. Под результатом в данном случае понимается организация процесса самообучения Заказчика по выбранной им тематике, а аудиовизуальным отображением - совокупность информации, текстов, графических элементов, дизайна, изображений, фото и видеоматериалов и иных объектов интеллектуальной собственности, доступ к которым осуществляется путем предоставления Заказчику возможности использования различных данных и команд.</p>
        <p class=" offertaText mb-1" style="font-weight:bold;">Сайт</p>
        <p class=" offertaText">Составное произведение, представляющее собой совокупность информации, текстов, графических элементов, дизайна, изображений, фото - и видеоматериалов, иных результатов интеллектуальной деятельности, содержащихся в информационной системе, обеспечивающей доступность такой информации в сети Интернет в пределах доменной зоны <a href="" target="_blank" routerLink="/">https://prnc2.com/</a>.</p>
        <p class=" offertaText mb-1" style="font-weight:bold;">Личный кабинет</p>
        <p class=" offertaText">Совокупность защищенных страниц Платформы, созданных в результате регистрации Заказчика и доступных при вводе его аутентификационных данных (адреса электронной почты и пароля) в предусмотренные для этого поля на Сайте. Юридически значимые действия, совершенные Заказчиком через его Личный Кабинет являются совершенными с его простой электронной подписью, где идентификатором и ключом электронной подписи является его аутентификационные данные.</p>
        <p class=" offertaText mb-1" style="font-weight:bold;">Курс</p>
        <p class=" offertaText">Определенная часть информации, содержащейся в Платформе, доступ к которой передается путем предоставления Заказчику определенных данных и команд, состоящая из   совокупности взаимосвязанных занятий и материалов (тексты, фото- и видеоматериалы, иные объекты интеллектуальных прав), объединенных единой темой, расположенных в определенной последовательности и направленных на самостоятельное приобретение Заказчиком знаний и навыков по соответствующей теме.</p>
        <p class=" offertaText mb-1" style="font-weight:bold;">Занятие</p>
        <p class=" offertaText">Лекция, проводимая через функционал Платформы в дистанционной форме (онлайн) в формате записи — записанная лекция, которая доступна онлайн. Доступ Заказчика к лекции в формате записи возможен в любое время.</p>
        <p class=" offertaText mb-1" style="font-weight:bold;">Тариф</p>
        <p class=" offertaText">Стоимость услуг Исполнителя в рамках выбранного Заказчиком Курса. Тарифы публикуются Исполнителем на <a href="" target="_blank" routerLink="/">https://prnc2.com/</a>.</p>
        <p class=" offertaText mb-1" style="font-weight:bold;">Раздел</p>
        <p class=" offertaText">Составная часть Курса, состоящая из блока Занятий, объединенных единой тематикой.</p>
        <p class=" offertaText mb-1" style="font-weight:bold;">Контент</p>
        <p class=" offertaText">Сообщения, комментарии и т.д., в том числе объекты авторских прав, размещаемые Пользователем на платформе.</p>

        <p class="offertaText" style="text-align:center;font-weight:bold;">2.Предмет Договора</p>

        <p class=" offertaText">2.1. Исполнитель обязуется предоставить Заказчику через информационно коммуникационную сеть “Интернет” удаленный доступ к Платформе (далее - “Услуги”), а Заказчик обязуется уплатить Исполнителю вознаграждение за предоставленный доступ в соответствии с п. 4.5 Договора. Исполнитель предоставляет Заказчику доступ только к той части Платформы (данным и командам, необходимым для доступа к Курсу), которая соответствует Курсу, выбранному Заказчиком. </p>
        <p class=" offertaText">2.2. По дополнительному согласованию с Исполнителем, доступ может быть предоставлен третьему лицу, на которое укажет Заказчик, при условии несения ответственности за его действия, как за свои собственные. В этом случае, в рамках исполнения Договора третье лицо приравнивается к Заказчику и имеет все те же права и обязанности что и Пользователь, если иное прямо не указано в Договоре. </p>
        <p class=" offertaText">2.3. Исполнитель размещает перечень Курсов, доступных для прохождения, на Платформе. Информация о стоимости, содержании, расписании Курса (датах и времени проведения Занятий) доступна Заказчику на странице выбранного Курса на Сайте. По письменному запросу Заказчика, направленному на электронную почту Исполнителя <a href="mailto:info@comproducts.ru">info@comproducts.ru</a>, Исполнитель направляет Заказчику подробную информацию о Курсе на электронную почту Заказчика, указанную им при регистрации на Платформе.</p>
        <p class=" offertaText">2.4. Услуги считаются оказанными Исполнителем с момента предоставления Заказчику доступа к Платформе.</p>


        <p class="offertaText" style="text-align:center;font-weight:bold;">3.Правила предоставления доступа к Курсу</p>

        <p class=" offertaText">3.1. Под предоставлением доступа к Курсу имеется в виду предоставление доступа к определенной совокупности данных и команд, позволяющих интерактивно взаимодействовать с частью Платформы. </p>
        <p class=" offertaText">3.2. Исполнитель предоставляет Заказчику доступ к Курсу после регистрации Заказчика на Сайте и оплаты стоимости Услуг в порядке, предусмотренном п. 4.5 Договора.</p>
        <p class=" offertaText">3.3. Исполнитель информирует Заказчика о предоставлении Доступа посредством направления уведомления на его электронную почту, указанную при регистрации на Сайте. </p>
        <p class=" offertaText">3.4. Курс состоит из Разделов. В зависимости от Курса доступ Заказчика к Разделам Курса может предоставляться: а) одномоментно в отношении всех Разделов курса; б) в зависимости от прогресса Заказчика: доступ к каждому следующему Разделу предоставляется после прохождения Заказчиком предыдущего Раздела;  </p>
        <p class=" offertaText">3.5. Содержание Курса может изменяться Исполнителем в одностороннем порядке, путем увеличения или изменения количества информации в Курсе. </p>


        <p class="offertaText" style="text-align:center;font-weight:bold;">4. Дополнительные Права и обязанности Сторон</p>
        <p class=" offertaText">4.1. Исполнитель обязан:</p>
        <p class=" offertaText">4.1.1. Осуществлять информационную поддержку Заказчика по вопросам оказания Услуг и работы Платформы по рабочим дням с 9:00 до 18:00 по времени Астаны. Все вопросы по поддержке направляются Заказчиком по электронному адресу: <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a></p>
        <p class=" offertaText">4.1.2. В случае обнаружения ошибок/недоработок в технологической части Платформы, допущенных Исполнителем, либо по вине Исполнителя, своими силами и за свой счет устранить обнаруженные ошибки/недоработки в разумные сроки. </p>
        <p class=" offertaText">4.2. Исполнитель вправе: </p>
        <p class=" offertaText">4.2.1. Без согласования с Заказчиком привлекать третьих лиц для исполнения настоящего Договора, оставаясь ответственным за действия таких лиц, как за свои собственные. </p>
        <p class=" offertaText">4.2.2. Запрашивать у Заказчика всю необходимую информацию, документы для надлежащего исполнения обязательств по настоящему Договору.</p>
        <p class=" offertaText">4.2.3. Изменять стоимость Услуг. Информация об актуальной стоимости Услуг доступна Заказчику на сайте <a href="" target="_blank" routerLink="/">https://prnc2.com/</a>. на странице конкретного Курса. Изменение стоимости Услуг в отношении уже оплаченного Заказчиком доступа к Курсу не производится. </p>
        <p class=" offertaText">4.2.4. Приостанавливать работу Платформы для проведения необходимых плановых профилактических и ремонтных работ на технических ресурсах Исполнителя. </p>
        <p class=" offertaText">4.2.5. Приостановить доступ к Платформе в случае нарушения Заказчиком настоящего Договора или в случае непредоставления Заказчиком всей необходимой информации, либо предоставления неполной информации, необходимой для оказания услуг по Договору в соответствии с законодательством РК. </p>
        <p class=" offertaText">4.2.6. Изменять содержание Курса, включая темы отдельных Занятий, их содержание, количество, даты и время проведения соответствующих Занятий, график размещения Разделов на Платформе и сроки открытия доступов к Разделам, заменить Экспертов. Информация о таких изменениях доступна Заказчику в Личном кабинете.</p>
        <p class=" offertaText">4.3. Заказчик обязан: </p>
        <p class=" offertaText">4.3.1. Своевременно и в полном объеме оплатить стоимость Услуг Исполнителя. </p>
        <p class=" offertaText">4.3.2. Своевременно предоставлять полную и достоверную информацию, необходимую для оказания Услуг (в том числе при регистрации на Сайте). </p>
        <p class=" offertaText">4.3.3. Обеспечить конфиденциальность логина и пароля к личному кабинету на Платформе. </p>
        <p class=" offertaText">4.3.4. Соблюдать этические нормы поведения при прохождении Курса, в частности не публиковать в общих чатах сообщения, не относящиеся к тематике освоения Курса, не допускать неуважительных высказываний и оскорблений в адрес других Заказчиков, сотрудников Исполнителя, Исполнителя.</p>
        <p class=" offertaText">4.4. Заказчик вправе: </p>
        <p class=" offertaText">4.4.1. Приостановить доступ к Курсу на определенный период с использованием соответствующего функционала Платформы.</p>
        <p class=" offertaText">4.4.2. Получать информационную поддержку по вопросам, связанным с порядком оказания Услуг и работой Платформы, на протяжении доступа к Курсу. </p>
        <p class=" offertaText">4.5. Финансовые условия:</p>
        <p class=" offertaText">4.5.1. Стоимость Услуг Исполнителя определяется в соответствии с Тарифами, указанными на Сайте.</p>
        <p class=" offertaText">4.5.2. Согласно законодательству РК стоимость Услуг Исполнителя облагается НДС</p>
        <p class=" offertaText">4.5.3. Заказчик производит оплату в размере 100% (ста процентов) тарифа единовременно.</p>
        <p class=" offertaText">4.5.4. Датой исполнения Заказчиком обязательств по оплате услуг Исполнителя является дата поступления денежных средств на расчетный счет Исполнителя. </p>
        <p class=" offertaText">4.5.5. При оплате Услуг на Сайте, Заказчик автоматически перенаправляется на страницу системы приема платежей для внесения оплаты. Исполнитель не контролирует аппаратнопрограммный комплекс электронной системы платежей. Если в результате таких ошибок произошло списание денежных средств Заказчика, но платеж не был авторизован электронной системой платежей, обязанности по возврату денежных средств Заказчику лежат на провайдере электронной системы платежей.</p>
        <p class=" offertaText">4.5.6. Акты об оказании Услуг предоставляются Исполнителем по запросу Заказчика, направленному Заказчиком на электронную почту Исполнителя <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a></p>
        <p class=" offertaText">4.5.7. Фискальные документы отправляются в электронном виде на почту Заказчика, указанную при регистрации на сайте в соответствии с законодательством РК. </p>


        <p class="offertaText" style="text-align:center;font-weight:bold;">5. Интеллектуальная собственность </p>
        <p class=" offertaText">5.1. Исключительное право на Платформу, в том числе на ее части принадлежат Исполнителю, либо им получены все необходимые права и разрешения. </p>
        <p class=" offertaText">5.2. Заказчик имеет право пользоваться Платформой в рамках предоставленного функционала и интерактивного взаимодействия с доступной информацией на все время доступа к Платформе в соответствии с настоящим Договором.</p>
        <p class=" offertaText">5.3. Заказчик обязан:</p>
        <ul class="pl-5 offertaText">
          <li>воздерживаться от любых действий, которые нарушают права Исполнителя на результаты интеллектуальной деятельности, в частности, не копировать, не записывать, не воспроизводить, не распространять любые результаты интеллектуальной деятельности Исполнителя без письменного разрешения Исполнителя; </li>
          <li>немедленно сообщать Исполнителю о любых ставших известными фактах нарушения исключительных прав Исполнителя;</li>
          <li>не предоставлять свои аутентификационные данные для доступа в Личный кабинет на Платформе третьим лицам. В случае утраты, а также в случаях незаконного получения доступа к логину и паролю третьими лицами, Заказчик обязуется незамедлительно сообщить об этом Исполнителю, путем направления уведомления по адресу: <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a>. До момента отправки указанного извещения все действия, совершенные с использованием Личного кабинета Заказчика, считаются совершенными Заказчиком. </li>
        </ul>
        <p class=" offertaText">5.4. Использование Заказчиком Платформы, ее содержимого и составляющих (как в целом, так и фрагментарно) и прочих разработанных Исполнителем технических решений не означает передачи (отчуждения) Заказчику и / или любому третьему лицу прав на результаты интеллектуальной деятельности, как в целом, так и в части.</p>
        <p class=" offertaText">5.5. К конфиденциальной относится любая информация, относящаяся к процессу оказания Услуг Исполнителем, неопубликованная в открытом доступе и не являющаяся доступной для всеобщего сведения. Заказчик обязуется не разглашать конфиденциальную информацию и иные данные, предоставленные Исполнителем в ходе оказания Услуг (за исключением общедоступной информации), третьим лицам без предварительного письменного согласия Исполнителя</p>

        <p class="offertaText" style="text-align:center;font-weight:bold;">6. Контент и комментарии на Платформе</p>
        <p class=" offertaText">6.1. Заказчик вправе размещать на Платформе Контент, если такое возможно, исходя из функциональности Платформы. При этом при размещении Контента Заказчик предоставляет согласие на право использования Контента на территории всех стран мира на срок действия исключительного права следующими способами:</p>
        <p class=" offertaText">6.1.1. воспроизводить (копировать) Контент; </p>
        <p class=" offertaText">6.1.2. распространять Контент; </p>
        <p class=" offertaText">6.1.3. осуществлять публичный показ Контента и его отдельных частей без соблюдения их последовательности;</p>
        <p class=" offertaText">6.1.4. переводить или иным образом перерабатывать Контент</p>
        <p class=" offertaText">6.1.5. доводить Контент до всеобщего сведения таким образом, что любое лицо может получить доступ к произведению из любого места и в любое время по собственному выбору (доведение до всеобщего сведения). </p>
        <p class=" offertaText">6.2. В случае обнаружения Заказчиком на Сайте любой информации, в том числе Контента, нарушающей его законные права и интересы, Заказчик вправе обратиться с соответствующим заявлением к Исполнителю путём направления сообщения на адрес электронной почты, указанный в Договоре. В заявлении необходимо изложить суть нарушенных прав и интересов, указав на соответствующую информацию, приложить документы, подтверждающие законность предъявляемых претензий, указать реквизиты, в том числе паспортные данные и контактные данные, предоставить согласие на обработку персональных данных в связи с направлением такого заявления.</p>
        <p class=" offertaText">6.3. Заказчик посредством Сайта и Личного кабинета также имеет возможность размещать в специальных разделах Сайта информацию и комментарии с учётом следующего:</p>
        <p class=" offertaText">6.3.1. такая информация должна соответствовать тематике Сайта. Исполнитель по своему усмотрению определяет соответствие такой информации тематике Сайта и имеет право удалять такую информацию, если она не соответствует данному критерию; </p>
        <p class=" offertaText">6.3.2. запрещается размещение любых ссылок на другие интернет-сайты без согласования с Исполнителем. Исполнитель имеет право удалять такую размещённую Заказчиком информацию;</p>
        <p class=" offertaText">6.3.3. запрещается размещение информации, нарушающей права и законные интересы третьих лиц, в том числе интеллектуальные, а также информации, которая: </p>
        <ul class=" offertaText">
          <li>содержит угрозы, дискредитирует, оскорбляет, содержит нецензурную лексику, порочит честь и достоинство или деловую репутацию, или нарушает неприкосновенность частной жизни пользователей Сайта, третьих лиц, Исполнителя; </li>
          <li>нарушает права несовершеннолетних лиц;</li>
          <li>является вульгарной или непристойной, содержит нецензурную лексику, содержит порнографические изображения и тексты или сцены сексуального характера с участием несовершеннолетних; </li>
          <li>содержит сцены насилия либо бесчеловечного обращения с животными; </li>
          <li>содержит описание средств и способов суицида, любое подстрекательство к его совершению;</li>
          <li>пропагандирует и/или способствует разжиганию расовой, религиозной, этнической ненависти или вражды, пропагандирует фашизм или идеологию расового превосходства; </li>
          <li>содержит экстремистские материалы;</li>
          <li>пропагандирует преступную деятельность или содержит советы, инструкции или руководства по совершению преступных действий; </li>
          <li>содержит информацию ограниченного доступа, включая, но не ограничиваясь государственной и коммерческой тайной, информацией о частной жизни третьих лиц; </li>
          <li>содержит рекламу или описывает привлекательность употребления наркотических веществ;</li>
          <li>носит мошеннический характер; </li>
          <li>содержит навязчивые способы привлечения внимания к своей публикации, в том числе (но не исключительно) заголовки заглавными буквами, несколько знаков препинания, нестандартный шрифт и т. п.; </li>
          <li>нарушает иные права и интересы граждан и юридических лиц или требования законодательства Республики Казахстан; </li>
          <li>запрещается размещение рекламной информации без согласования с Исполнителем.</li>

        </ul>
        <p class=" offertaText">6.3.4. Исполнитель вправе редактировать или удалять информацию, размещенную Заказчиком на Сайте, в целях обеспечения соблюдения законодательства Республики Казахстан и Договора </p>
        <p class=" offertaText">6.3.5. Не допускается использовать Сайт:</p>
        <ul class="pl-5 offertaText">
          <li>с целью массового распространения информации (в т. ч. спам-рассылки), а также загрузки, хранения, публикации, распространения, либо предоставления доступа третьим лицам к какому- либо программному обеспечению, позволяющему вносить изменения в нормальное функционирование Сайта;</li>
          <li>для осуществления Заказчиком какой-либо предпринимательской деятельности или любой деятельности, направленной на извлечение дохода, за исключением случаев, прямо указанных в Договоре или иных договорах между Исполнителем и Заказчиком, либо в односторонних документах Исполнителя, условия которых относятся к Заказчику.</li>
        </ul>


        <p class="offertaText" style="text-align:center;font-weight:bold;">7. Конфиденциальность</p>
        <p class=" offertaText">7.1. К конфиденциальной относится любая информация, относящаяся к процессу оказания Услуг Исполнителем, не опубликованная в открытом доступе и не являющаяся доступной для всеобщего сведения. Заказчик обязуется не разглашать конфиденциальную информацию и иные данные, предоставленные Исполнителем в ходе оказания Услуг (за исключением общедоступной информации), третьим лицам без предварительного письменного согласия Исполнителя.</p>
        <p class=" offertaText">7.2. В рамках оказания услуг Заказчик может предоставляться доступ к программному обеспечению сторонних правообладателей, в том числе к внутренним информационным системам, сайтам (далее — ПО) через Платформу. В таком случае Заказчик не может совершать с ПО никаких действий за исключением тех, которые необходимы для прохождения Курса. Срок предоставления права использования такого ПО ограничен сроком прохождения Курса. Заказчик обязан сохранять конфиденциальность сведений в отношении ПО и не вправе разглашать их без согласия правообладателя такого ПО. Если при этом происходит создание или переработка какого бы то ни было ПО, Заказчик безвозмездно передаёт исключительное право на переработанное ПО или созданное ПО Компании, предоставившей доступ с момента создания или переработки, если иное не было специально согласовано Сторонами.</p>

        <p class="offertaText" style="text-align:center;font-weight:bold;">8. Ответственность Сторон </p>
        <p class=" offertaText">8.1. Исполнитель несет ответственность в соответствии с законодательством РК при наличии его вины. </p>
        <p class=" offertaText">8.2. Исполнитель не несёт ответственности за качество соединения с сетью Интернет и функционирование оборудования и программного обеспечения Заказчика. </p>
        <p class=" offertaText">8.3. При неисполнении Заказчиком обязательства, предусмотренного пунктами 5.3 Договора, и выявлении Исполнителем факта доступа третьих лиц к содержанию Курса Заказчик обязан во внесудебном порядке по письменному требованию Исполнителя оплатить штраф в размере 500 000 (пятьсот тысяч) тенге за каждый случай несанкционированного предоставления доступа третьим лицам.</p>
        <p class=" offertaText">8.4. В случае нарушения Заказчиком условий Договора Исполнитель вправе прекратить доступ Заказчика к Личному кабинету, а также заблокировать доступ Заказчика на Платформу или конкретному Курсу (-ам).</p>
        <p class=" offertaText">8.5. Исполнитель не несет ответственности за содержание информации, размещаемой в чатах мессенджеров («WhatsApp», «Telegram»), в том числе за использование третьими лицами персональных данных, которые Заказчик оставляет в таких чатах. </p>
        <p class=" offertaText">8.6. Платформа и ее программные средства, в том числе Личный кабинет, предоставляются «Как есть». На Заказчике лежит риск использования Платформы. Исполнитель не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по настоящему Договору, а также за возможный ущерб, возникший в результате:</p>
        <ul class="pl-5 offertaText">
          <li>сбоев в работе Платформы и (или) иного программного обеспечения, вызванных ошибками в коде, компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении; </li>
          <li>отсутствия (невозможности установления, прекращения и пр.) Интернет-соединений;</li>
          <li>установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение настоящего Договора;</li>
          <li>других случаев, связанных с действием (бездействием) пользователей Интернета и/или других субъектов, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования;</li>
          <li>использования (невозможность использования) и какие бы то ни было последствия использования (невозможности использования) Заказчиком выбранной им формы оплаты услуг по Договору. </li>
        </ul>
        <p class="offertaText" style="text-align:center;font-weight:bold;">9. Срок действия Договора. Порядок расторжения </p>
        <p class=" offertaText">9.1. Договор вступает в силу с даты его акцепта Заказчиком.</p>
        <p class=" offertaText">9.2. Договор, может быть, в любое время расторгнут по инициативе Исполнителя в одностороннем порядке.</p>
        <p class=" offertaText">9.3. Договор может быть расторгнут в части доступа к Курсу по инициативе Исполнителя в одностороннем внесудебном порядке по истечению 3 лет с момента начала его действия, в случае отсутствия организационной, технической или юридической возможности предоставлять доступ к этой части Платформы. </p>
        <p class=" offertaText">9.4. Договор может быть в любое время расторгнут в одностороннем порядке по инициативе Заказчика путем направления Исполнителю уведомления по электронной почте Исполнителя <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a> с указанием в ней причин отказа от Договора, а также своих данных позволяющих Исполнителю однозначно верифицировать владельца Личного кабинета на Платформе, реквизитов и иной информации необходимой для возврата денежных средств</p>
        <p class=" offertaText">9.5. В случае досрочного расторжения Договора Исполнитель возвращает Заказчику часть стоимости Услуг на условиях, предусмотренных Правилами возврата денежных средств (Приложение №1 к Договору).</p>

        <p class="offertaText" style="text-align:center;font-weight:bold;">10. Изменение условий Договора</p>
        <p class=" offertaText">10.1. Исполнитель вправе в одностороннем порядке изменять условия Договора, и такие изменения вступают в силу в момент опубликования новой версии Договора в сети Интернет по адресу <a routerLink="/ofertakz" target="_blank" href="">https://prnc2.com/ofertakz</a>. При этом в части величины Вознаграждения за уже предоставленный доступ старая версия Договора продолжает действовать для Сторон без изменений. В остальной части новые положения Договора имеют обратную силу. </p>
        <p class=" offertaText">10.2. Использование Платформы или направление новых Заявок после опубликования новой версии Договора будет означать согласие Пользователя с условиями новой версии Договора. Если Пользователь не согласен с условиями новой версии Договора, он должен прекратить использовать Платформу или оплачивать Счета.</p>
        <p class=" offertaText">10.3. Заказчик вправе передать свои права и обязанности по Договору третьей Стороне при условии сохранения доступа Курса только при условии получения письменного согласия Исполнителя и на основании отдельного соглашения, заключенного Сторонами. </p>
        <p class=" offertaText">10.4. Исполнитель вправе уступать права, переводить долги (в том числе привлекать субагентов и субподрядчиков) по всем обязательствам, возникшим из Договора. Настоящим заказчик дает свое согласие на уступку прав и перевод долга любым третьим лицам. О состоявшейся уступке прав и/или переводе долга Исполнитель информирует Заказчика, размещая соответствующую информацию в Личном кабинете.</p>

        <p class="offertaText" style="text-align:center;font-weight:bold;">11. Рассылки и персональные данные</p>
        <p class=" offertaText">11.1. Заказчик дает свое согласие Исполнителю на обработку персональных данных Заказчика, указанных им при регистрации на Сайте, а также в Личном кабинете на условиях, предусмотренных Политикой обработки персональных данных, размещенной по адресу: <a routerLink="/oferta" target="_blank" href="">https://prnc2.com/privacy</a></p>
        <p class=" offertaText">11.2. Заказчик дает согласие на получение от Исполнителя смс-рассылок, а также иных видов рассылок и уведомлений, информационного характера (устных и письменных), с использованием любых средств связи, включая, но не ограничиваясь следующими: электронная почта, телефон, почтовые рассылки. Настоящее согласие может быть в любое время отозвано Заказчиком посредством направления уведомления по электронной почте Исполнителя <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a> С учетом того, что данное согласие необходимо для корректного исполнения Договора со стороны Исполнителя и корректного функционирования Платформы, в случае отзыва согласия по настоящему пункту Заказчик вправе расторгнуть договор в одностороннем (внесудебном) порядке или ограничить доступ к Платформе </p>
        <p class=" offertaText">11.3. Заказчик дает согласие на получение от Исполнителя смс-рассылок, а также иных видов рассылок и уведомлений, рекламного характера (устных и письменных), с использованием любых средств связи, включая но не ограничиваясь следующими: электронная почта, телефон, почтовые рассылки. Данное согласие является встречным предоставлением Заказчика за возможность использования демонстрационной части Платформы, до момента оплаты Курса без взимания дополнительной платы. Настоящее согласие может быть в любое время отозвано Заказчиком посредством направления уведомления по электронной почте Исполнителя <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a></p>
        <p class=" offertaText">11.4. Заказчик дает согласие на использование Исполнителем отзывов Заказчика об Исполнителе и оказываемых им услугах, оставленных Заказчиком в официальных группах Исполнителя в социальных сетях, в целях размещения таких отзывов на официальных сайтах Исполнителя, в информационных и рекламных материалах Исполнителя. Настоящее согласие действует с даты заключения Договора. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя, указанному в разделе 15 Договора.</p>
        <p class=" offertaText">11.5. Заказчик даёт свое согласие Исполнителю на обнародование и дальнейшее использование изображения Заказчика в фото-, видеоматериалах, равно как и зафиксированного в независимых друг от друга кадрах таких видеоматериалов, а также зафиксированного в любых иных объектах изображении в целях размещения такого изображения на официальных сайтах Исполнителя, в информационных и рекламных материалах Исполнителя и любых иных целях, связанных с деятельностью Исполнителя и не противоречащих действующему законодательству. Настоящее согласие действует с даты заключения Договора и распространяется на любые объекты, созданные Исполнителем в период доступа к Платформе Заказчиком, а также полученные от Заказчика в этот период. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя, указанному в разделе 15 Договора. </p>
        <p class=" offertaText">11.6. Заказчик дает свое согласие на получение рекламно-информационного материала от партнеров Исполнителя в целях возможного получения различных поощрений (бонусы, скидки) от них. Условия получения и размер поощрения определяется партнерами Исполнителя самостоятельно. В случае изменения условий поощрения от партнеров Исполнителя вправе изменять, добавлять и убирать бонусы от партнеров, направляя письменное уведомление Заказчику на электронную почту, указанную в Личном кабинете. В случае таких изменений Заказчик не вправе требовать от Исполнителя оставлять прежние условия поощрений от партнеров и не праве требовать какие-либо выплаты и убытки, связанные с такими изменениями. Заказчик настоящим понимает и принимает, что Исполнитель самостоятельно не вносит какие-либо изменения от партнеров и не несет ответственности и не контролирует такие изменения. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя, указанному в разделе 15 Договора. </p>

        <p class="offertaText" style="text-align:center;font-weight:bold;">12. Порядок разрешения споров </p>
        <p class=" offertaText">12.1. В случае возникновения любых разногласий между Заказчиком и Исполнителем относительно исполнения каждой из сторон условий Договора, а также любых иных разногласий, такие разногласия должны быть урегулированы с применением обязательного досудебного претензионного порядка. Исполнитель обязуется направить Заказчику претензию в электронном виде на адрес электронной почты, указанный Заказчиком при регистрации на Сайте. Заказчик обязуется направить Исполнителю претензию в электронном виде на адрес электронной почты <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a>, а также продублировать претензию в письменном виде на адрес Исполнителя, указанный в разделе 15 Договора. Срок ответа на претензию - 10 (десять) рабочих дней со дня ее получения. Если Законодательством РК установлен меньший срок, то применяется срок, установленный законодательством. При несоблюдении любой из сторон всех перечисленных выше условий обязательный претензионный порядок не считается соблюденным.</p>

        <p class="offertaText" style="text-align:center;font-weight:bold;">13. Уведомления и электронный документооборот</p>
        <p class=" offertaText">13.1. Если иное не предусмотрено в Договоре или действующим законодательством, любые уведомления, запросы или иные сообщения (корреспонденция), представляемые Сторонами друг другу, должны быть оформлены в письменном виде и направлены получающей Стороне по почте, путем направления заказной корреспонденции, по электронной почте (на адрес и (или) с адреса Исполнителя, указанного в разделе 15 Договора на адрес и (или) с адреса Заказчика, указанного при регистрации на Платформе) или при помощи курьерской службы. Датой получения корреспонденции считается дата получения уведомления о доставке почтового отправления, в том числе заказной корреспонденции, электронного подтверждения доставки при отправлении электронной почтой (или в отсутствии такового – момент отправления сообщения), или день доставки в случае отправления корреспонденции с курьером. При рассмотрении споров в суде переписка Сторон по электронной почте, а также переписка через Личный кабинет будут признаны Сторонами достаточными доказательствами.</p>
        <p class=" offertaText">13.2. При исполнении (изменении, дополнении, прекращении) Договора, а также при ведении переписки по указанным вопросам допускается использование аналогов собственноручной подписи Сторон. Стороны подтверждают, что все уведомления, сообщения, соглашения и документы в рамках исполнения Сторонами обязательств, возникших из Договора, подписанные аналогами собственноручной подписи Сторон, имеют юридическую силу и обязательны для исполнения Сторонами. Под аналогами собственноручной подписи понимаются уполномоченные адреса электронной почты и учетные данные к Личному кабинету. </p>
        <p class=" offertaText">13.3. Стороны признают, что все уведомления, сообщения, соглашения, документы и письма, направленные с использованием уполномоченных адресов электронной почты и Личного кабинета, считаются направленными и подписанными Сторонами, кроме случаев, когда в таких письмах прямо не указано обратное.</p>
        <p class=" offertaText">13.4. Уполномоченными адресами электронной почты Сторон признаются:</p>
        <ul class=" offertaText">
          <li>Для Исполнителя: <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a> или иной, указанный в Договоре</li>
          <li>Для Заказчика: адрес электронной почты, указанный при регистрации на Сайте. </li>
        </ul>
        <p class=" offertaText">13.5. Стороны обязуются обеспечивать конфиденциальность сведений и информации, необходимых для доступа к уполномоченным адресам электронной почты и Личному кабинету, не допускать разглашение такой информации и передачу третьим лицам. Стороны самостоятельно определяют порядок ограничения доступа к такой информации.</p>
        <p class=" offertaText">13.6. До момента получения от Заказчика информации о нарушения режима конфиденциальности все действия и документы, совершенные и направленные с помощью уполномоченного адреса электронной почты Заказчика и Личного кабинета, даже если такие действия и документы были совершены и направлены иными лицами, считаются совершенными и направленными Заказчиком. В этом случае права и обязанности, а также ответственность наступают у Пользователя.</p>
        <p class=" offertaText">13.7. До момента получения от Платформы информации о нарушения режима конфиденциальности, все действия и документы, совершенные и направленные с помощью уполномоченного адреса электронной почты Платформы, даже если такие действия и документы были совершены и направлены иными лицами, считаются совершенными и направленными Платформой.</p>

        <p class="offertaText" style="text-align:center;font-weight:bold;">14. Прочие условия </p>

        <p class=" offertaText">14.1. Заказчик вправе передать свои права и обязанности по Договору третьей Стороне при условии сохранения доступа к Курсу только при условии получения письменного согласия Исполнителя и на основании отдельного соглашения, заключенного Сторонами.</p>
        <p class=" offertaText">14.2. В рамках Платформы Пользователю предоставлена возможность получать различные поощрения (бонусы, скидки) от партнеров Платформы. Условия получения и размер поощрения определяется партнерами Платформы самостоятельно. В случае изменения условий поощрения от партнеров Платформа вправе изменять, добавлять и убирать бонусы от партнеров, направляя письменное уведомление Пользователю в порядке, предусмотренной настоящим Договором и порядком работы Платформы. В случае таких изменений Пользователь не вправе требовать от Платформы оставлять прежние условия поощрений от партнеров и не праве требовать какие-либо выплаты и убытки, связанные с такими изменениями. Пользователь настоящим понимает и принимает, что Платформа самостоятельно не вносит какие-либо изменения от партнеров и не несет ответственности и не контролирует такие изменения.</p>
        <p class=" offertaText">14.3. Недействительность одного из условий договора не влечет недействительности всего Договора в целом. В случае признания условия Договора недействительным, стороны обязаны вступить в переговоры и изменить договор таким образом, чтобы он продолжил свое действие.</p>
        <p class=" offertaText">14.4. Во всем остальном, что не урегулировано Договором, Стороны руководствуются действующим законодательством Республики Казахстан без учета его коллизионных норм.</p>


        <p class="offertaText" style="text-align:center;font-weight:bold;">15. Реквизиты Исполнителя</p>
        <p class=" offertaText mb-0">Исполнитель:</p>
        <p class=" offertaText mb-0">ТОО “DragAu”</p>
        <p class=" offertaText mb-0">Адрес: 050010 Алматы ул.Бегалина д.42</p>
        <p class=" offertaText mb-0">БИН 041 040 005 066</p>
        <p class=" offertaText mb-0">ИИК (IBAN) KZ316017131000014558</p>
        <p class=" offertaText mb-0">БИК: HSBKKZKX</p>
        <p class=" offertaText mb-0">Банк:АО Народный Банк Казахстана»</p>
        <p class=" offertaText mb-0">Кбе 17</p>

        <p class=" offertaText mb-1 mt-3" style="font-weight:bold;">Приложение №1 к Договору</p>


        <p class="offertaText" style="text-align:center;font-weight:bold;">Правила возврата денежных средств</p>
        <p class=" offertaText">Настоящие Правила возврата денежных средств (далее - Правила) регулируют отношения между Исполнителем и Заказчиком, связанные с возвратом оплаченной стоимости Услуг в случае досрочного расторжения Договора. </p>
        <p class=" offertaText">1. Возврат денежных средств в соответствии с 454 ГК РК (отказ от Договора) возможен до исполнения сторонами обязательств по Договору, т. е. до момента предоставления доступа к Платформе.</p>
        <p class=" offertaText">2. В случае отказа Заказчика от Договора и наличии у него права на возврат денежных средств, Исполнитель направляет Заказчику форму заявления о возврате денежных средств (далее - Заявление). Заказчик не позднее 5 (пяти) рабочих дней с момента получения формы Заявления от Исполнителя обязуется корректно заполнить, подписать и направить ее Исполнителю в сканированном виде на электронный адрес <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a> и в оригинале на почтовый адрес: 050010 Алматы ул.Бегалина д.42.</p>

      </div>




      <div class="">
        <div class="ab-footer-mobile ab-footer-table" fxLayout="column" fxFlex="25.55">
          <div fxFlex="21" style="">
            <div fxLayout="column" fxFlexFill style="">
              <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 30em;" />
              <!--<img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />-->
              <!--<div fxFlex fxLayoutAlign="start" style="padding-top: 1.85vh;">-->
              <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
              <!--<img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
        <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />-->
              <!--</div>-->
            </div>
          </div>
          <div fxLayout="column" class="" fxFlex="22" style="margin-left: 3.73vw; margin-bottom:2vh;">
            <div fxLayout="column" class="pb-4" fxFlexFill>

              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown1()" style="">{{'Courses' | translate}}</a>
              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown2()" style="">{{'Education_process' | translate}}</a>
              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown3()" style="">{{'Comments' | translate}}</a>
              <a fxFlex="25" class="mat-title footer-text-table footer-text-mobile" (click)="goDown4()" style="">{{'About_us' | translate}}</a>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" fxFlex="30" class="py-2" style="margin-left: 3.73vw;">
            <div fxLayout="column" fxFlexFill>
              <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" routerLink="/additional-services" style="">{{'Additional_services' | translate}}</a>
              <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="">{{'support' | translate}}</a>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" routerLink="/oferta" style="">{{'public_offer_1' | translate}}</a>
              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table ofertaCss" routerLink="/ofertakz" style="">{{'public_offer_1' | translate}}</a>
              </div>
              <div *ngIf="currentCountry==='Россия'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>

              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <a fxFlex="100" class="mat-title footer-text-2-mobile footer-text-2-table mb-0 ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>

              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" class="py-2" fxFlex="30" style="margin-left: 3.73vw;">
            <div fxLayout="column" fxFlexFill>
              <div *ngIf="currentCountry==='Россия'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>

              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>

              </div>
              <div *ngIf="currentCountry==='Россия'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>

              </div>
              <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
                <p class="mat-title  footer-text-2-mobile footer-text-2-table" style=""><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>

              </div>
              <a fxFlex="20" class="mat-title footer-text-2-mobile footer-text-2-table" style="" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>
            </div>
          </div>
          <div fxLayout="column" fxFlex="22" style="margin-left: 3.73vw;">
            <div fxLayout="row" fxFlexFill>
              <img src="/images/axelos.svg" class="axelos-img-mobile axelos-img-table" alt="axelosLogo" style="" />
              <div fxFlex fxLayoutAlign="start center" style="align-items:center;margin-left: 10px;">
                <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img-mobile footer-contact-img-table " />
          <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img-mobile footer-contact-img-table" />-->
                <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img-mobile footer-contact-img-table" />
                <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard-mobile" />
                <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa-mobile" />
              </div>

            </div>
          </div>

        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>




<div class="d-none d-lg-inline">
  <div class="rightimage">
    <img src="/images/rightImage.svg" alt="rightImage" style="width: 24vw;" />
  </div>

  <app-courses-toolbar></app-courses-toolbar>

  <div class="containerOfferta">

    <p class="pl-2 offertaText" style="">
      Настоящий документ, постоянно размещенный в сети Интернет по сетевому адресу: <a routerLink="/ofertakz" target="_blank" href="">https://prnc2.com/ofertakz</a> является публичной офертой в соответствии со ст.387 и 389 Гражданского кодекса Республики Казахстан на заключение Договора оказания услуг удаленного доступа (далее - Договор) с любым заинтересованным физическим лицом (далее - Заказчик). Надлежащим акцептом настоящей оферты в соответствии с пунктом 3 статьи 396 ГК является совершение Заказчиком в совокупности следующих действий:
    </p>

    <ul class="pl-5 offertaText">
      <li>отправка Заказчиком своих персональных данных через форму заявки на сайте <a href="" target="_blank" routerLink="/">https://prnc2.com/</a></li>
    </ul>

    <p class="pl-2 offertaText pt-2">Для получения доступа к отдельным частям Платформы, Пользователю также требуется: </p>
    <ul class="pl-5 offertaText">
      <li>Подать заявку на приобретение дополнительного доступа через функционал Платформы;</li>
      <li>оплатить Тариф Исполнителя в порядке, определенном в Договоре.</li>
    </ul>

    <p class="pl-2 pt-2 offertaText">Оплачивая стоимость услуг Исполнителя, Заказчик:</p>
    <ul class="pl-5 offertaText">
      <li>гарантирует достоверность и актуальность сведений, предоставляемых о себе;</li>
      <li>гарантирует, что он является совершеннолетним и полностью дееспособным лицом;</li>
      <li>соглашается, что он самостоятельно несет ответственность за любые последствия, возникающие в результате указания недостоверных, неактуальных или неполных сведений о себе.</li>
    </ul>

    <p class="pl-2 offertaText">Датой акцепта оферты Заказчиком (датой заключения Договора) считается дата зачисления денежных средств за оказание Исполнителем услуг на расчетный счет Исполнителя.</p>

    <p class="bigBoldText" style=" text-align:center;margin-top: 3vh;">ДОГОВОР</p>
    <p class="offertaText" style="text-align:center;font-weight:bold;">1.Термины и определения</p>

    <p class="pl-2 offertaText">В Договоре, если иное прямо не вытекает из текста, указанные ниже термины будут иметь следующие значения:</p>
    <p class="pl-2 offertaText mb-1" style="font-weight:bold;">Платформа</p>
    <p class="pl-2 offertaText">Объект интеллектуальных прав, информационная система <strong>«Портал обучения PRNC2.com»,</strong> исключительное право на который принадлежит Исполнителю, доступный в сети Интернет по адресу: <a href="" target="_blank" routerLink="/">https://prnc2.com/</a>, состоящий из совокупности представленных в объективной форме данных и команд, предназначенных для функционирования ЭВМ и других компьютерных устройств в целях получения определенного результата, включая программную оболочку для интерактивного (мультимедийного) взаимодействия с содержащейся в программе информацией и порождаемые ею аудиовизуальные отображения. Под результатом в данном случае понимается организация процесса самообучения Заказчика по выбранной им тематике, а аудиовизуальным отображением - совокупность информации, текстов, графических элементов, дизайна, изображений, фото и видеоматериалов и иных объектов интеллектуальной собственности, доступ к которым осуществляется путем предоставления Заказчику возможности использования различных данных и команд.</p>
    <p class="pl-2 offertaText mb-1" style="font-weight:bold;">Сайт</p>
    <p class="pl-2 offertaText">Составное произведение, представляющее собой совокупность информации, текстов, графических элементов, дизайна, изображений, фото - и видеоматериалов, иных результатов интеллектуальной деятельности, содержащихся в информационной системе, обеспечивающей доступность такой информации в сети Интернет в пределах доменной зоны <a href="" target="_blank" routerLink="/">https://prnc2.com/</a>.</p>
    <p class="pl-2 offertaText mb-1" style="font-weight:bold;">Личный кабинет</p>
    <p class="pl-2 offertaText">Совокупность защищенных страниц Платформы, созданных в результате регистрации Заказчика и доступных при вводе его аутентификационных данных (адреса электронной почты и пароля) в предусмотренные для этого поля на Сайте. Юридически значимые действия, совершенные Заказчиком через его Личный Кабинет являются совершенными с его простой электронной подписью, где идентификатором и ключом электронной подписи является его аутентификационные данные.</p>
    <p class="pl-2 offertaText mb-1" style="font-weight:bold;">Курс</p>
    <p class="pl-2 offertaText">Определенная часть информации, содержащейся в Платформе, доступ к которой передается путем предоставления Заказчику определенных данных и команд, состоящая из   совокупности взаимосвязанных занятий и материалов (тексты, фото- и видеоматериалы, иные объекты интеллектуальных прав), объединенных единой темой, расположенных в определенной последовательности и направленных на самостоятельное приобретение Заказчиком знаний и навыков по соответствующей теме.</p>
    <p class="pl-2 offertaText mb-1" style="font-weight:bold;">Занятие</p>
    <p class="pl-2 offertaText">Лекция, проводимая через функционал Платформы в дистанционной форме (онлайн) в формате записи — записанная лекция, которая доступна онлайн. Доступ Заказчика к лекции в формате записи возможен в любое время.</p>
    <p class="pl-2 offertaText mb-1" style="font-weight:bold;">Тариф</p>
    <p class="pl-2 offertaText">Стоимость услуг Исполнителя в рамках выбранного Заказчиком Курса.Тарифы публикуются Исполнителем на <a href="" target="_blank" routerLink="/">https://prnc2.com/</a>.</p>
    <p class="pl-2 offertaText mb-1" style="font-weight:bold;">Раздел</p>
    <p class="pl-2 offertaText">Составная часть Курса, состоящая из блока Занятий, объединенных единой тематикой.</p>
    <p class="pl-2 offertaText mb-1" style="font-weight:bold;">Контент</p>
    <p class="pl-2 offertaText">Сообщения, комментарии и т.д., в том числе объекты авторских прав, размещаемые Пользователем на платформе.</p>

    <p class="offertaText" style="text-align:center;font-weight:bold;">2.Предмет Договора</p>

    <p class="pl-2 offertaText">2.1. Исполнитель обязуется предоставить Заказчику через информационно коммуникационную сеть “Интернет” удаленный доступ к Платформе (далее - “Услуги”), а Заказчик обязуется уплатить Исполнителю вознаграждение за предоставленный доступ в соответствии с п. 4.5 Договора. Исполнитель предоставляет Заказчику доступ только к той части Платформы (данным и командам, необходимым для доступа к Курсу), которая соответствует Курсу, выбранному Заказчиком.</p>
    <p class="pl-2 offertaText">2.2. По дополнительному согласованию с Исполнителем, доступ может быть предоставлен третьему лицу, на которое укажет Заказчик, при условии несения ответственности за его действия, как за свои собственные. В этом случае, в рамках исполнения Договора третье лицо приравнивается к Заказчику и имеет все те же права и обязанности что и Пользователь, если иное прямо не указано в Договоре. </p>
    <p class="pl-2 offertaText">2.3. Исполнитель размещает перечень Курсов, доступных для прохождения, на Платформе. Информация о стоимости, содержании, расписании Курса (датах и времени проведения Занятий) доступна Заказчику на странице выбранного Курса на Сайте. По письменному запросу Заказчика, направленному на электронную почту Исполнителя <a href="mailto:info@comproducts.ru">info@comproducts.ru</a>, Исполнитель направляет Заказчику подробную информацию о Курсе на электронную почту Заказчика, указанную им при регистрации на Платформе.</p>
    <p class="pl-2 offertaText">2.4. Услуги считаются оказанными Исполнителем с момента предоставления Заказчику доступа к Платформе.</p>


    <p class="offertaText" style="text-align:center;font-weight:bold;">3.Правила предоставления доступа к Курсу</p>

    <p class="pl-2 offertaText">3.1. Под предоставлением доступа к Курсу имеется в виду предоставление доступа к определенной совокупности данных и команд, позволяющих интерактивно взаимодействовать с частью Платформы. </p>
    <p class="pl-2 offertaText">3.2. Исполнитель предоставляет Заказчику доступ к Курсу после регистрации Заказчика на Сайте и оплаты стоимости Услуг в порядке, предусмотренном п. 4.5 Договора.</p>
    <p class="pl-2 offertaText">3.3. Исполнитель информирует Заказчика о предоставлении Доступа посредством направления уведомления на его электронную почту, указанную при регистрации на Сайте. </p>
    <p class="pl-2 offertaText">3.4. Курс состоит из Разделов. В зависимости от Курса доступ Заказчика к Разделам Курса может предоставляться: а) одномоментно в отношении всех Разделов курса; б) в зависимости от прогресса Заказчика: доступ к каждому следующему Разделу предоставляется после прохождения Заказчиком предыдущего Раздела;  </p>
    <p class="pl-2 offertaText">3.5. Содержание Курса может изменяться Исполнителем в одностороннем порядке, путем увеличения или изменения количества информации в Курсе. </p>


    <p class="offertaText" style="text-align:center;font-weight:bold;">4. Дополнительные Права и обязанности Сторон</p>
    <p class="pl-2 offertaText">4.1. Исполнитель обязан:</p>
    <p class="pl-2 offertaText">4.1.1. Осуществлять информационную поддержку Заказчика по вопросам оказания Услуг и работы Платформы по рабочим дням с 9:00 до 18:00 по времени Астаны. Все вопросы по поддержке направляются Заказчиком по электронному адресу: <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a></p>
    <p class="pl-2 offertaText">4.1.2. В случае обнаружения ошибок/недоработок в технологической части Платформы, допущенных Исполнителем, либо по вине Исполнителя, своими силами и за свой счет устранить обнаруженные ошибки/недоработки в разумные сроки. </p>
    <p class="pl-2 offertaText">4.2. Исполнитель вправе: </p>
    <p class="pl-2 offertaText">4.2.1. Без согласования с Заказчиком привлекать третьих лиц для исполнения настоящего Договора, оставаясь ответственным за действия таких лиц, как за свои собственные. </p>
    <p class="pl-2 offertaText">4.2.2. Запрашивать у Заказчика всю необходимую информацию, документы для надлежащего исполнения обязательств по настоящему Договору.</p>
    <p class="pl-2 offertaText">4.2.3. Изменять стоимость Услуг. Информация об актуальной стоимости Услуг доступна Заказчику на сайте <a href="" target="_blank" routerLink="/">https://prnc2.com/</a>. на странице конкретного Курса. Изменение стоимости Услуг в отношении уже оплаченного Заказчиком доступа к Курсу не производится. </p>
    <p class="pl-2 offertaText">4.2.4. Приостанавливать работу Платформы для проведения необходимых плановых профилактических и ремонтных работ на технических ресурсах Исполнителя. </p>
    <p class="pl-2 offertaText">4.2.5. Приостановить доступ к Платформе в случае нарушения Заказчиком настоящего Договора или в случае непредоставления Заказчиком всей необходимой информации, либо предоставления неполной информации, необходимой для оказания услуг по Договору в соответствии с законодательством РК. </p>
    <p class="pl-2 offertaText">4.2.6. Изменять содержание Курса, включая темы отдельных Занятий, их содержание, количество, даты и время проведения соответствующих Занятий, график размещения Разделов на Платформе и сроки открытия доступов к Разделам, заменить Экспертов. Информация о таких изменениях доступна Заказчику в Личном кабинете.</p>
    <p class="pl-2 offertaText">4.3. Заказчик обязан: </p>
    <p class="pl-2 offertaText">4.3.1. Своевременно и в полном объеме оплатить стоимость Услуг Исполнителя. </p>
    <p class="pl-2 offertaText">4.3.2. Своевременно предоставлять полную и достоверную информацию, необходимую для оказания Услуг (в том числе при регистрации на Сайте). </p>
    <p class="pl-2 offertaText">4.3.3. Обеспечить конфиденциальность логина и пароля к личному кабинету на Платформе. </p>
    <p class="pl-2 offertaText">4.3.4. Соблюдать этические нормы поведения при прохождении Курса, в частности не публиковать в общих чатах сообщения, не относящиеся к тематике освоения Курса, не допускать неуважительных высказываний и оскорблений в адрес других Заказчиков, сотрудников Исполнителя, Исполнителя.</p>
    <p class="pl-2 offertaText">4.4. Заказчик вправе: </p>
    <p class="pl-2 offertaText">4.4.1. Приостановить доступ к Курсу на определенный период с использованием соответствующего функционала Платформы.</p>
    <p class="pl-2 offertaText">4.4.2. Получать информационную поддержку по вопросам, связанным с порядком оказания Услуг и работой Платформы, на протяжении доступа к Курсу. </p>
    <p class="pl-2 offertaText">4.5. Финансовые условия.</p>
    <p class="pl-2 offertaText">4.5.1. Стоимость Услуг Исполнителя определяется в соответствии с Тарифами, указанными на Сайте.</p>
    <p class="pl-2 offertaText">4.5.2. Согласно законодательству РК стоимость Услуг Исполнителя облагается НДС.</p>
    <p class="pl-2 offertaText">4.5.3. Заказчик производит оплату в размере 100% (ста процентов) тарифа единовременно.</p>
    <p class="pl-2 offertaText">4.5.4. Датой исполнения Заказчиком обязательств по оплате услуг Исполнителя является дата поступления денежных средств на расчетный счет Исполнителя. </p>
    <p class="pl-2 offertaText">4.5.5. При оплате Услуг на Сайте, Заказчик автоматически перенаправляется на страницу системы приема платежей для внесения оплаты. Исполнитель не контролирует аппаратнопрограммный комплекс электронной системы платежей. Если в результате таких ошибок произошло списание денежных средств Заказчика, но платеж не был авторизован электронной системой платежей, обязанности по возврату денежных средств Заказчику лежат на провайдере электронной системы платежей.</p>
    <p class="pl-2 offertaText">4.5.6. Акты об оказании Услуг предоставляются Исполнителем по запросу Заказчика, направленному Заказчиком на электронную почту Исполнителя <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a></p>
    <p class="pl-2 offertaText">4.5.7. Фискальные документы отправляются в электронном виде на почту Заказчика, указанную при регистрации на сайте в соответствии с законодательством РК. </p>


    <p class="offertaText" style="text-align:center;font-weight:bold;">5. Интеллектуальная собственность </p>
    <p class="pl-2 offertaText">5.1. Исключительное право на Платформу, в том числе на ее части принадлежат Исполнителю, либо им получены все необходимые права и разрешения. </p>
    <p class="pl-2 offertaText">5.2. Заказчик имеет право пользоваться Платформой в рамках предоставленного функционала и интерактивного взаимодействия с доступной информацией на все время доступа к Платформе в соответствии с настоящим Договором.</p>
    <p class="pl-2 offertaText">5.3. Заказчик обязан:</p>
    <ul class="pl-5 offertaText">
      <li>воздерживаться от любых действий, которые нарушают права Исполнителя на результаты интеллектуальной деятельности, в частности, не копировать, не записывать, не воспроизводить, не распространять любые результаты интеллектуальной деятельности Исполнителя без письменного разрешения Исполнителя; </li>
      <li>немедленно сообщать Исполнителю о любых ставших известными фактах нарушения исключительных прав Исполнителя;</li>
      <li>не предоставлять свои аутентификационные данные для доступа в Личный кабинет на Платформе третьим лицам. В случае утраты, а также в случаях незаконного получения доступа к логину и паролю третьими лицами, Заказчик обязуется незамедлительно сообщить об этом Исполнителю, путем направления уведомления по адресу: <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a>. До момента отправки указанного извещения все действия, совершенные с использованием Личного кабинета Заказчика, считаются совершенными Заказчиком. </li>
    </ul>
    <p class="pl-2 offertaText">5.4. Использование Заказчиком Платформы, ее содержимого и составляющих (как в целом, так и фрагментарно) и прочих разработанных Исполнителем технических решений означает передачи (отчуждения) Заказчику и / или любому третьему лицу прав на результаты интеллектуальной деятельности, как в целом, так и в части.</p>
    <p class="pl-2 offertaText">5.5. К конфиденциальной относится любая информация, относящаяся к процессу оказания Услуг Исполнителем, неопубликованная в открытом доступе и не являющаяся доступной для всеобщего сведения. Заказчик обязуется не разглашать конфиденциальную информацию и иные данные, предоставленные Исполнителем в ходе оказания Услуг (за исключением общедоступной информации), третьим лицам без предварительного письменного согласия Исполнителя.</p>

    <p class="offertaText" style="text-align:center;font-weight:bold;">6. Контент и комментарии на Платформе</p>
    <p class="pl-2 offertaText">6.1. Заказчик вправе размещать на Платформе Контент, если такое возможно, исходя из функциональности Платформы. При этом при размещении Контента Заказчик предоставляет согласие на право использования Контента на территории всех стран мира на срок действия исключительного права следующими способами:</p>
    <p class="pl-2 offertaText">6.1.1. воспроизводить (копировать) Контент; </p>
    <p class="pl-2 offertaText">6.1.2. распространять Контент; </p>
    <p class="pl-2 offertaText">6.1.3. осуществлять публичный показ Контента и его отдельных частей без соблюдения их последовательности;</p>
    <p class="pl-2 offertaText">6.1.4. переводить или иным образом перерабатывать Контент</p>
    <p class="pl-2 offertaText">6.1.5. доводить Контент до всеобщего сведения таким образом, что любое лицо может получить доступ к произведению из любого места и в любое время по собственному выбору (доведение до всеобщего сведения). </p>
    <p class="pl-2 offertaText">6.2. В случае обнаружения Заказчиком на Сайте любой информации, в том числе Контента, нарушающей его законные права и интересы, Заказчик вправе обратиться с соответствующим заявлением к Исполнителю путём направления сообщения на адрес электронной почты, указанный в Договоре. В заявлении необходимо изложить суть нарушенных прав и интересов, указав на соответствующую информацию, приложить документы, подтверждающие законность предъявляемых претензий, указать реквизиты, в том числе паспортные данные и контактные данные, предоставить согласие на обработку персональных данных в связи с направлением такого заявления.</p>
    <p class="pl-2 offertaText">6.3. Заказчик посредством Сайта и Личного кабинета также имеет возможность размещать в специальных разделах Сайта информацию и комментарии с учётом следующего:</p>
    <p class="pl-2 offertaText">6.3.1. такая информация должна соответствовать тематике Сайта. Исполнитель по своему усмотрению определяет соответствие такой информации тематике Сайта и имеет право удалять такую информацию, если она не соответствует данному критерию; </p>
    <p class="pl-2 offertaText">6.3.2. запрещается размещение любых ссылок на другие интернет-сайты без согласования с Исполнителем. Исполнитель имеет право удалять такую размещённую Заказчиком информацию;</p>
    <p class="pl-2 offertaText">6.3.3. запрещается размещение информации, нарушающей права и законные интересы третьих лиц, в том числе интеллектуальные, а также информации, которая: </p>
    <ul class="pl-5 offertaText">
      <li>содержит угрозы, дискредитирует, оскорбляет, содержит нецензурную лексику, порочит честь и достоинство или деловую репутацию, или нарушает неприкосновенность частной жизни пользователей Сайта, третьих лиц, Исполнителя; </li>
      <li>нарушает права несовершеннолетних лиц;</li>
      <li>является вульгарной или непристойной, содержит нецензурную лексику, содержит порнографические изображения и тексты или сцены сексуального характера с участием несовершеннолетних; </li>
      <li>содержит сцены насилия либо бесчеловечного обращения с животными; </li>
      <li>содержит описание средств и способов суицида, любое подстрекательство к его совершению;</li>
      <li>пропагандирует и/или способствует разжиганию расовой, религиозной, этнической ненависти или вражды, пропагандирует фашизм или идеологию расового превосходства; </li>
      <li>содержит экстремистские материалы;</li>
      <li>пропагандирует преступную деятельность или содержит советы, инструкции или руководства по совершению преступных действий; </li>
      <li>содержит информацию ограниченного доступа, включая, но не ограничиваясь государственной и коммерческой тайной, информацией о частной жизни третьих лиц; </li>
      <li>содержит рекламу или описывает привлекательность употребления наркотических веществ;</li>
      <li>носит мошеннический характер; </li>
      <li>содержит навязчивые способы привлечения внимания к своей публикации, в том числе (но не исключительно) заголовки заглавными буквами, несколько знаков препинания, нестандартный шрифт и т. п.; </li>
      <li>нарушает иные права и интересы граждан и юридических лиц или требования законодательства Республики Казахстан; </li>
      <li>запрещается размещение рекламной информации без согласования с Исполнителем.</li>

    </ul>
    <p class="pl-2 offertaText">6.3.4. Исполнитель вправе редактировать или удалять информацию, размещенную Заказчиком на Сайте, в целях обеспечения соблюдения законодательства Республики Казахстан и Договора.</p>
    <p class="pl-2 offertaText">6.3.5. Не допускается использовать Сайт:</p>
    <ul class="pl-5 offertaText">
      <li>с целью массового распространения информации (в т. ч. спам-рассылки), а также загрузки, хранения, публикации, распространения, либо предоставления доступа третьим лицам к какому- либо программному обеспечению, позволяющему вносить изменения в нормальное функционирование Сайта;</li>
      <li>для осуществления Заказчиком какой-либо предпринимательской деятельности или любой деятельности, направленной на извлечение дохода, за исключением случаев, прямо указанных в Договоре или иных договорах между Исполнителем и Заказчиком, либо в односторонних документах Исполнителя, условия которых относятся к Заказчику.</li>
    </ul>


    <p class="offertaText" style="text-align:center;font-weight:bold;">7. Конфиденциальность</p>
    <p class="pl-2 offertaText">7.1. К конфиденциальной относится любая информация, относящаяся к процессу оказания Услуг Исполнителем, не опубликованная в открытом доступе и не являющаяся доступной для всеобщего сведения. Заказчик обязуется не разглашать конфиденциальную информацию и иные данные, предоставленные Исполнителем в ходе оказания Услуг (за исключением общедоступной информации), третьим лицам без предварительного письменного согласия Исполнителя.</p>
    <p class="pl-2 offertaText">7.2. В рамках оказания услуг Заказчик может предоставляться доступ к программному обеспечению сторонних правообладателей, в том числе к внутренним информационным системам, сайтам (далее — ПО) через Платформу. В таком случае Заказчик не может совершать с ПО никаких действий за исключением тех, которые необходимы для прохождения Курса. Срок предоставления права использования такого ПО ограничен сроком прохождения Курса. Заказчик обязан сохранять конфиденциальность сведений в отношении ПО и не вправе разглашать их без согласия правообладателя такого ПО. Если при этом происходит создание или переработка какого бы то ни было ПО, Заказчик безвозмездно передаёт исключительное право на переработанное ПО или созданное ПО Компании, предоставившей доступ с момента создания или переработки, если иное не было специально согласовано Сторонами.</p>

    <p class="offertaText" style="text-align:center;font-weight:bold;">8. Ответственность Сторон </p>
    <p class="pl-2 offertaText">8.1. Исполнитель несет ответственность в соответствии с законодательством РК при наличии его вины. </p>
    <p class="pl-2 offertaText">8.2. Исполнитель не несёт ответственности за качество соединения с сетью Интернет и функционирование оборудования и программного обеспечения Заказчика. </p>
    <p class="pl-2 offertaText">8.3. При неисполнении Заказчиком обязательства, предусмотренного пунктами 5.3 Договора, и выявлении Исполнителем факта доступа третьих лиц к содержанию Курса Заказчик обязан во внесудебном порядке по письменному требованию Исполнителя оплатить штраф в размере 500 000 (пятьсот тысяч) тенге за каждый случай несанкционированного предоставления доступа третьим лицам.</p>
    <p class="pl-2 offertaText">8.4. В случае нарушения Заказчиком условий Договора Исполнитель вправе прекратить доступ Заказчика к Личному кабинету, а также заблокировать доступ Заказчика на Платформу или конкретному Курсу (-ам).</p>
    <p class="pl-2 offertaText">8.5. Исполнитель не несет ответственности за содержание информации, размещаемой в чатах мессенджеров («WhatsApp», «Telegram»), в том числе за использование третьими лицами персональных данных, которые Заказчик оставляет в таких чатах. </p>
    <p class="pl-2 offertaText">8.6. Платформа и ее программные средства, в том числе Личный кабинет, предоставляются «Как есть». На Заказчике лежит риск использования Платформы. Исполнитель не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по настоящему Договору, а также за возможный ущерб, возникший в результате:</p>
    <ul class="pl-5 offertaText">
      <li>сбоев в работе Платформы и (или) иного программного обеспечения, вызванных ошибками в коде, компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении; </li>
      <li>отсутствия (невозможности установления, прекращения и пр.) Интернет-соединений;</li>
      <li>установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение настоящего Договора;</li>
      <li>других случаев, связанных с действием (бездействием) пользователей Интернета и/или других субъектов, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования;</li>
      <li>использования (невозможность использования) и какие бы то ни было последствия использования (невозможности использования) Заказчиком выбранной им формы оплаты услуг по Договору. </li>
    </ul>
    <p class="offertaText" style="text-align:center;font-weight:bold;">9. Срок действия Договора. Порядок расторжения </p>
    <p class="pl-2 offertaText">9.1. Договор вступает в силу с даты его акцепта Заказчиком.</p>
    <p class="pl-2 offertaText">9.2. Договор, может быть, в любое время расторгнут по инициативе Исполнителя в одностороннем порядке.</p>
    <p class="pl-2 offertaText">9.3. Договор может быть расторгнут в части доступа к Курсу по инициативе Исполнителя в одностороннем внесудебном порядке по истечению 3 лет с момента начала его действия, в случае отсутствия организационной, технической или юридической возможности предоставлять доступ к этой части Платформы. </p>
    <p class="pl-2 offertaText">9.4. Договор может быть в любое время расторгнут в одностороннем порядке по инициативе Заказчика путем направления Исполнителю уведомления по электронной почте Исполнителя <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a> с указанием в ней причин отказа от Договора, а также своих данных позволяющих Исполнителю однозначно верифицировать владельца Личного кабинета на Платформе, реквизитов и иной информации необходимой для возврата денежных средств</p>
    <p class="pl-2 offertaText">9.5. В случае досрочного расторжения Договора Исполнитель возвращает Заказчику часть стоимости Услуг на условиях, предусмотренных Правилами возврата денежных средств (Приложение №1 к Договору).</p>

    <p class="offertaText" style="text-align:center;font-weight:bold;">10. Изменение условий Договора</p>
    <p class="pl-2 offertaText">10.1. Исполнитель вправе в одностороннем порядке изменять условия Договора, и такие изменения вступают в силу в момент опубликования новой версии Договора в сети Интернет по адресу <a routerLink="/ofertakz" target="_blank" href="">https://prnc2.com/ofertakz</a>. При этом в части величины Вознаграждения за уже предоставленный доступ старая версия Договора продолжает действовать для Сторон без изменений. В остальной части новые положения Договора имеют обратную силу. </p>
    <p class="pl-2 offertaText">10.2. Использование Платформы или направление новых Заявок после опубликования новой версии Договора будет означать согласие Пользователя с условиями новой версии Договора. Если Пользователь не согласен с условиями новой версии Договора, он должен прекратить использовать Платформу или оплачивать Счета.</p>
    <p class="pl-2 offertaText">10.3. Заказчик вправе передать свои права и обязанности по Договору третьей Стороне при условии сохранения доступа Курса только при условии получения письменного согласия Исполнителя и на основании отдельного соглашения, заключенного Сторонами. </p>
    <p class="pl-2 offertaText">10.4. Исполнитель вправе уступать права, переводить долги (в том числе привлекать субагентов и субподрядчиков) по всем обязательствам, возникшим из Договора. Настоящим заказчик дает свое согласие на уступку прав и перевод долга любым третьим лицам. О состоявшейся уступке прав и/или переводе долга Исполнитель информирует Заказчика, размещая соответствующую информацию в Личном кабинете.</p>

    <p class="offertaText" style="text-align:center;font-weight:bold;">11. Рассылки и персональные данные</p>
    <p class="pl-2 offertaText">11.1. Заказчик дает свое согласие Исполнителю на обработку персональных данных Заказчика, указанных им при регистрации на Сайте, а также в Личном кабинете на условиях, предусмотренных Политикой обработки персональных данных, размещенной по адресу: <a routerLink="/oferta" target="_blank" href="">https://prnc2.com/privacy</a></p>
    <p class="pl-2 offertaText">11.2. Заказчик дает согласие на получение от Исполнителя смс-рассылок, а также иных видов рассылок и уведомлений, информационного характера (устных и письменных), с использованием любых средств связи, включая, но не ограничиваясь следующими: электронная почта, телефон, почтовые рассылки. Настоящее согласие может быть в любое время отозвано Заказчиком посредством направления уведомления по электронной почте Исполнителя <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a> С учетом того, что данное согласие необходимо для корректного исполнения Договора со стороны Исполнителя и корректного функционирования Платформы, в случае отзыва согласия по настоящему пункту Заказчик вправе расторгнуть договор в одностороннем (внесудебном) порядке или ограничить доступ к Платформе </p>
    <p class="pl-2 offertaText">11.3. Заказчик дает согласие на получение от Исполнителя смс-рассылок, а также иных видов рассылок и уведомлений, рекламного характера (устных и письменных), с использованием любых средств связи, включая но не ограничиваясь следующими: электронная почта, телефон, почтовые рассылки. Данное согласие является встречным предоставлением Заказчика за возможность использования демонстрационной части Платформы, до момента оплаты Курса без взимания дополнительной платы. Настоящее согласие может быть в любое время отозвано Заказчиком посредством направления уведомления по электронной почте Исполнителя <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a></p>
    <p class="pl-2 offertaText">11.4. Заказчик дает согласие на использование Исполнителем отзывов Заказчика об Исполнителе и оказываемых им услугах, оставленных Заказчиком в официальных группах Исполнителя в социальных сетях, в целях размещения таких отзывов на официальных сайтах Исполнителя, в информационных и рекламных материалах Исполнителя. Настоящее согласие действует с даты заключения Договора. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя, указанному в разделе 15 Договора.</p>
    <p class="pl-2 offertaText">11.5. Заказчик даёт свое согласие Исполнителю на обнародование и дальнейшее использование изображения Заказчика в фото-, видеоматериалах, равно как и зафиксированного в независимых друг от друга кадрах таких видеоматериалов, а также зафиксированного в любых иных объектах изображении в целях размещения такого изображения на официальных сайтах Исполнителя, в информационных и рекламных материалах Исполнителя и любых иных целях, связанных с деятельностью Исполнителя и не противоречащих действующему законодательству. Настоящее согласие действует с даты заключения Договора и распространяется на любые объекты, созданные Исполнителем в период доступа к Платформе Заказчиком, а также полученные от Заказчика в этот период. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя, указанному в разделе 15 Договора. </p>
    <p class="pl-2 offertaText">11.6. Заказчик дает свое согласие на получение рекламно-информационного материала от партнеров Исполнителя в целях возможного получения различных поощрений (бонусы, скидки) от них. Условия получения и размер поощрения определяется партнерами Исполнителя самостоятельно. В случае изменения условий поощрения от партнеров Исполнителя вправе изменять, добавлять и убирать бонусы от партнеров, направляя письменное уведомление Заказчику на электронную почту, указанную в Личном кабинете. В случае таких изменений Заказчик не вправе требовать от Исполнителя оставлять прежние условия поощрений от партнеров и не праве требовать какие-либо выплаты и убытки, связанные с такими изменениями. Заказчик настоящим понимает и принимает, что Исполнитель самостоятельно не вносит какие-либо изменения от партнеров и не несет ответственности и не контролирует такие изменения. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя, указанному в разделе 15 Договора. </p>

    <p class="offertaText" style="text-align:center;font-weight:bold;">12. Порядок разрешения споров </p>
    <p class="pl-2 offertaText">12.1. В случае возникновения любых разногласий между Заказчиком и Исполнителем относительно исполнения каждой из сторон условий Договора, а также любых иных разногласий, такие разногласия должны быть урегулированы с применением обязательного досудебного претензионного порядка. Исполнитель обязуется направить Заказчику претензию в электронном виде на адрес электронной почты, указанный Заказчиком при регистрации на Сайте. Заказчик обязуется направить Исполнителю претензию в электронном виде на адрес электронной почты <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a>, а также продублировать претензию в письменном виде на адрес Исполнителя, указанный в разделе 15 Договора. Срок ответа на претензию - 10 (десять) рабочих дней со дня ее получения. Если Законодательством РК установлен меньший срок, то применяется срок, установленный законодательством. При несоблюдении любой из сторон всех перечисленных выше условий обязательный претензионный порядок не считается соблюденным.</p>

    <p class="offertaText" style="text-align:center;font-weight:bold;">13. Уведомления и электронный документооборот</p>
    <p class="pl-2 offertaText">13.1. Если иное не предусмотрено в Договоре или действующим законодательством, любые уведомления, запросы или иные сообщения (корреспонденция), представляемые Сторонами друг другу, должны быть оформлены в письменном виде и направлены получающей Стороне по почте, путем направления заказной корреспонденции, по электронной почте (на адрес и (или) с адреса Исполнителя, указанного в разделе 15 Договора на адрес и (или) с адреса Заказчика, указанного при регистрации на Платформе) или при помощи курьерской службы. Датой получения корреспонденции считается дата получения уведомления о доставке почтового отправления, в том числе заказной корреспонденции, электронного подтверждения доставки при отправлении электронной почтой (или в отсутствии такового – момент отправления сообщения), или день доставки в случае отправления корреспонденции с курьером. При рассмотрении споров в суде переписка Сторон по электронной почте, а также переписка через Личный кабинет будут признаны Сторонами достаточными доказательствами.</p>
    <p class="pl-2 offertaText">13.2. При исполнении (изменении, дополнении, прекращении) Договора, а также при ведении переписки по указанным вопросам допускается использование аналогов собственноручной подписи Сторон. Стороны подтверждают, что все уведомления, сообщения, соглашения и документы в рамках исполнения Сторонами обязательств, возникших из Договора, подписанные аналогами собственноручной подписи Сторон, имеют юридическую силу и обязательны для исполнения Сторонами. Под аналогами собственноручной подписи понимаются уполномоченные адреса электронной почты и учетные данные к Личному кабинету. </p>
    <p class="pl-2 offertaText">13.3. Стороны признают, что все уведомления, сообщения, соглашения, документы и письма, направленные с использованием уполномоченных адресов электронной почты и Личного кабинета, считаются направленными и подписанными Сторонами, кроме случаев, когда в таких письмах прямо не указано обратное.</p>
    <p class="pl-2 offertaText">13.4. Уполномоченными адресами электронной почты Сторон признаются:</p>
    <ul class="pl-5 offertaText">
      <li>Для Исполнителя: <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a> или иной, указанный в Договоре</li>
      <li>Для Заказчика: адрес электронной почты, указанный при регистрации на Сайте. </li>
    </ul>
    <p class="pl-2 offertaText">13.5. Стороны обязуются обеспечивать конфиденциальность сведений и информации, необходимых для доступа к уполномоченным адресам электронной почты и Личному кабинету, не допускать разглашение такой информации и передачу третьим лицам. Стороны самостоятельно определяют порядок ограничения доступа к такой информации.</p>
    <p class="pl-2 offertaText">13.6. До момента получения от Заказчика информации о нарушения режима конфиденциальности все действия и документы, совершенные и направленные с помощью уполномоченного адреса электронной почты Заказчика и Личного кабинета, даже если такие действия и документы были совершены и направлены иными лицами, считаются совершенными и направленными Заказчиком. В этом случае права и обязанности, а также ответственность наступают у Пользователя. </p>
    <p class="pl-2 offertaText">13.7. До момента получения от Платформы информации о нарушения режима конфиденциальности, все действия и документы, совершенные и направленные с помощью уполномоченного адреса электронной почты Платформы, даже если такие действия и документы были совершены и направлены иными лицами, считаются совершенными и направленными Платформой.</p>

    <p class="offertaText" style="text-align:center;font-weight:bold;">14. Прочие условия </p>

    <p class="pl-2 offertaText">14.1. Заказчик вправе передать свои права и обязанности по Договору третьей Стороне при условии сохранения доступа к Курсу только при условии получения письменного согласия Исполнителя и на основании отдельного соглашения, заключенного Сторонами.</p>
    <p class="pl-2 offertaText">14.2. В рамках Платформы Пользователю предоставлена возможность получать различные поощрения (бонусы, скидки) от партнеров Платформы. Условия получения и размер поощрения определяется партнерами Платформы самостоятельно. В случае изменения условий поощрения от партнеров Платформа вправе изменять, добавлять и убирать бонусы от партнеров, направляя письменное уведомление Пользователю в порядке, предусмотренной настоящим Договором и порядком работы Платформы. В случае таких изменений Пользователь не вправе требовать от Платформы оставлять прежние условия поощрений от партнеров и не праве требовать какие-либо выплаты и убытки, связанные с такими изменениями. Пользователь настоящим понимает и принимает, что Платформа самостоятельно не вносит какие-либо изменения от партнеров и не несет ответственности и не контролирует такие изменения.</p>
    <p class="pl-2 offertaText">14.3. Недействительность одного из условий договора не влечет недействительности всего Договора в целом. В случае признания условия Договора недействительным, стороны обязаны вступить в переговоры и изменить договор таким образом, чтобы он продолжил свое действие.</p>
    <p class="pl-2 offertaText">14.4. Во всем остальном, что не урегулировано Договором, Стороны руководствуются действующим законодательством Республики Казахстан без учета его коллизионных норм.</p>



    <p class="offertaText" style="text-align:center;font-weight:bold;">15. Реквизиты Исполнителя</p>
    <p class="pl-2 offertaText mb-0">Исполнитель:</p>
    <p class="pl-2 offertaText mb-0">ТОО “DragAu”</p>
    <p class="pl-2 offertaText mb-0">Адрес: 050010 Алматы ул.Бегалина д.42</p>
    <p class="pl-2 offertaText mb-0">БИН 041 040 005 066</p>
    <p class="pl-2 offertaText mb-0">ИИК (IBAN) KZ316017131000014558</p>
    <p class="pl-2 offertaText mb-0">БИК: HSBKKZKX</p>
    <p class="pl-2 offertaText mb-0">Банк:АО Народный Банк Казахстана»</p>
    <p class="pl-2 offertaText mb-0">Кбе 17</p>


    <p class="pl-2 offertaText mb-1 mt-3" style="font-weight:bold;">Приложение №1 к Договору</p>


    <p class="offertaText" style="text-align:center;font-weight:bold;">Правила возврата денежных средств</p>
    <p class="pl-2 offertaText">Настоящие Правила возврата денежных средств (далее - Правила) регулируют отношения между Исполнителем и Заказчиком, связанные с возвратом оплаченной стоимости Услуг в случае досрочного расторжения Договора. </p>
    <p class="pl-2 offertaText">1. Возврат денежных средств в соответствии с 454 ГК РК (отказ от Договора) возможен до исполнения сторонами обязательств по Договору, т. е. до момента предоставления доступа к Платформе.</p>
    <p class="pl-2 offertaText">2. В случае отказа Заказчика от Договора и наличии у него права на возврат денежных средств, Исполнитель направляет Заказчику форму заявления о возврате денежных средств (далее - Заявление). Заказчик не позднее 5 (пяти) рабочих дней с момента получения формы Заявления от Исполнителя обязуется корректно заполнить, подписать и направить ее Исполнителю в сканированном виде на электронный адрес <a href="mailto:info@comproducts.ru">info@comproducts.ru.</a> и в оригинале на почтовый адрес: 050010 Алматы ул. Бегалина д.42.</p>

  </div>




  <div class="ab-footer" fxLayout="row" fxFlex="25.55">
    <div fxFlex="25">
      <div fxLayout="column" fxFlexFill>
        <img src="/images/ComProductsLogo.svg" alt="comproductsLogo" style="width: 12em;" />
        <img src="/images/axelos.svg" alt="axelosLogo" style="width: 12em;padding-top: 2.03vh;padding-bottom: 2.03vh;" />
        <div fxFlex fxLayoutAlign="start center">
          <!--<img src="/images/facebook.svg" alt="facebookLogo" class="footer-contact-img" />
        <img src="/images/instagram.svg" alt="instaLogo" class="footer-contact-img" />-->
          <img src="/images/vk.svg" alt="vkLogo" class="footer-contact-img" />
          <img src="/images/telegram.svg" alt="telegramLogo" class="footer-contact-img" />
          <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard" />
          <img *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'" src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa" />

        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="20">
      <div fxLayout="column" fxFlexFill>

        <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown1()">{{'Courses' | translate}}</a>
        <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown2()">{{'Education_process' | translate}}</a>
        <a fxFlex="20" class="mat-title footer-text" style="margin-bottom: 1.20vh;" (click)="goDown3()">{{'Comments' | translate}}</a>
        <a fxFlex="30" class="mat-title footer-text" (click)="goDown4()">{{'About_us' | translate}}</a>

      </div>



    </div>
    <div fxLayout="column" fxFlex="30">
      <div fxLayout="column" fxFlexFill>
        <a fxFlex="20" class="mat-title footer-text" routerLink="/additional-services" style="margin-bottom: 0.55vh;">{{'Additional_services' | translate}}</a>
        <a *ngIf="currentCountry==='Россия'" fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8" style="margin-bottom: 1.20vh;">{{'support' | translate}}</a>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/payment-rules">{{'Pravila_Payment' | translate}}</a>
        </div>
        <div *ngIf="currentCountry==='Россия'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh;color:white !important;" routerLink="/oferta">{{'public_offer_1' | translate}}</a>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="margin-bottom: 1.20vh; color: white !important;" routerLink="/ofertakz">{{'public_offer_1' | translate}}</a>
        </div>
        <div *ngIf="currentCountry==='Россия'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacy">{{'Personal_data_processing_policy' | translate}}</a>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <a fxFlex="100" class="mat-title footer-text ofertaCss" style="color: white !important;" routerLink="/privacykz">{{'Personal_data_processing_policy' | translate}}</a>
        </div>

      </div>
    </div>

    <div fxLayout="column" fxFlex="30">
      <div fxLayout="column" fxFlexFill>
        <div *ngIf="currentCountry==='Россия'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addressru' | translate}}</p>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/locationSmall.svg" alt="locationSmall" class="" style="padding-right: 0.625vw;" />{{'addresskz' | translate}}</p>
        </div>
        <div *ngIf="currentCountry==='Россия'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numru' | translate}}</p>
        </div>
        <div *ngIf="currentCountry ==='Казахстан' || currentCountry ==='Другая'">
          <p class="mat-title  footer-text" style="margin-bottom: 1.20vh;"><img src="/images/phoneSmall.svg" alt="phoneSmall" class="" style="padding-right: 0.625vw;" />{{'phone_numkz' | translate}}</p>
        </div>
        <a fxFlex="20" class="mat-title footer-text" href="mailto:info@comproducts.ru?Subject=%D0%97%D0%B0%D0%BF%D1%80%D0%BE%D1%81%20%D1%82%D0%B5%D1%85%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%BA%D0%B8"><img src="/images/mailSmall.svg" alt="mailLogo" class="" style="padding-right: 0.625vw;" />info@comproducts.ru</a>

      </div>
    </div>

  </div>

</div>



<mat-menu #langMenu="matMenu">
  <button mat-menu-item *ngFor="let language of translate.getLangs()"
          (click)="switchLang(language)">
    <span>{{language | translate}}</span>
  </button>
</mat-menu>

<mat-menu #contactMenu="matMenu">
  <button mat-menu-item
          (click)="cabinet()">
    <span>{{'cabinet' | translate}}</span>
  </button>
  <button mat-menu-item
          (click)="logout()">
    <span>{{'logout' | translate}}</span>
  </button>
</mat-menu>

