/***************************************************************************************************
 * ssLoad `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { enableProdMode, LOCALE_ID } from '@angular/core';
import { ScreenTrackingService } from '@angular/fire/analytics';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { TranslateService, TranslateStore } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthorizeInterceptor } from './api-authorization/authorize.interceptor';
import { Languages } from './app/app.component';

import { AppModule } from './app/app.module';
import { ErrorDialogService } from './app/errordialog.service';
import { GlobalObjectService } from './app/global-object.service';
import { environment } from './environments/environment';

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

const providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    //{ provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    /*ErrorDialogService,
    DeviceDetectorService,
    GlobalObjectService,
    TranslateService,
    TranslateStore,
    {
        provide: LOCALE_ID,
        deps: [TranslateService],
        useFactory: () => localStorage.getItem("lang") ? localStorage.getItem("lang") : Languages[1]
    },
    ScreenTrackingService*/
];

if (environment.production) {
    enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
    return platformBrowserDynamic(providers).bootstrapModule(AppModule)
        .catch(err => console.error(err));
});
