import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, pattern: string = 'longDate'): any {
    var lang = this.translateService.currentLang ?? "ru-RU";
    if (lang.startsWith("en")) {
      lang = "en-US";
    }
    else {
      lang = "ru-RU";
    }
    const datePipe: DatePipe = new DatePipe(lang);
    return datePipe.transform(value, pattern);
  }

}
