<div class="d-sm-block d-md-block d-lg-none" style="">
  <picture>
    <source srcset="/images/mobile-bg-main-table.svg" type="image/svg+xml" style="" media="(min-width:480px) and (orientation: landscape)" />
    <!--source srcset="/images/mobile-bg-main.svg" type="image/svg+xml" media="(max-width:480px)" /-->
    <img class="position-absolute portait-image-table"  src="/images/mobile-bg-main-1.svg" alt="mainBg" style="height: 100%; width: 100%; object-fit: cover; top: 0;" />
  </picture>
  
  <div class="row">
    <div class="col-12" style="margin-top:5vh;text-align: center;">
      <a href="/#main" class="">
        <img src="/images/logo.svg" alt="logo" style="width: 20em;" />
      </a>
    </div>
  </div>


  <div fxLayout="row"
       fxFlexFill
       fxLayoutAlign="center center">
    <mat-card class="mobile-container table-container" style=" margin-top: 8vh;margin-bottom:8vh;">
      <app-verify-phone></app-verify-phone>
    </mat-card>
  </div>
</div>



<div class="d-none d-lg-inline">
  <img class="position-fixed h-100 w-100" alt="loginBg" src="/images/login_bg.svg" />
  <a href="/#main" class="logo">
    <img src="/images/logo.svg" alt="logo" style="width: 8em;" />
  </a>
  <div fxLayout="row"
       fxFlexFill
       fxLayoutAlign="center center">
    <mat-card class="login-card">
      <app-verify-phone></app-verify-phone>
    </mat-card>
  </div>
</div>
