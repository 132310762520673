import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { AuthorizeService } from '../../../../api-authorization/authorize.service';
import { GlobalObjectService } from '../../../global-object.service';

@Component({
  selector: 'shared-foundation-ru',
  templateUrl: './foundation-ru-shared.component.html',
  styleUrls: ['./foundation-ru-shared.component.css']
})
export class FoundationRuSharedComponent implements OnInit {
  @Input() isCabinet: boolean;
  static TABLET_WIDTH = 1025;
  isBrowser = false;
  desktopMenu: boolean = true;

    isAuthorized?: boolean = null;

  constructor(public authService: AuthorizeService, public windowRef: GlobalObjectService,
    @Inject(PLATFORM_ID) private platformId: object,) {
    this.isBrowser = isPlatformBrowser(this.platformId)
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.desktopMenu = event.target.outerWidth >= FoundationRuSharedComponent.TABLET_WIDTH;
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.isBrowser) {
        this.desktopMenu = this.windowRef.getWindow().outerWidth >= FoundationRuSharedComponent.TABLET_WIDTH;
      }
    });
  }

    ngOnInit(): void {
        this.authService.afAuth.authState.subscribe(user => {
            this.isAuthorized = user !== null;
        });
  }

}
