import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ErrorDialogService } from '../app/errordialog.service';
import { GlobalObjectService } from '../app/global-object.service';
import { isPlatformBrowser } from '@angular/common';
import { IpInfo } from '../app/ipinfo-module/ipinfo.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeInterceptor implements HttpInterceptor {
    constructor(private authorize: AuthorizeService,
        public errorDialogService: ErrorDialogService,
        public windowRef: GlobalObjectService,
      @Inject(PLATFORM_ID) private platformId: object,
      public ipInfo: IpInfo
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authorize.getAccessToken()
      .pipe(mergeMap(token => this.processRequestWithToken(token, req, next)));
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequestWithToken(token: string, req: HttpRequest<any>, next: HttpHandler) {
    let lang = this.authorize.translate.getDefaultLang();
    if (this.authorize.translate.currentLang) {
      lang = this.authorize.translate.currentLang;
    }

    //console.log("interceptor lang: " + lang);

    if (req.url.indexOf("ipinfo") === -1) {
        let copyReq = req.clone({ headers: req.headers.set('RE-LOCALE', lang) });

        //console.log("request headers: ", copyReq);

        if (token) {
          copyReq = copyReq.clone({ headers: copyReq.headers.set('Authorization', 'Bearer ' + token) });
        }

        req = copyReq;

        console.log(req);
      }

      return next.handle(req).pipe(
          map((event: HttpEvent<any>) => {
               /*if (event instanceof HttpResponse) {
                   console.log('event--->>>', event);
               }*/
              return event;
          }),
          catchError((response: HttpErrorResponse) => {
              let data = {};
              //console.log("AuthorizeInterceptor error", response, req);

              if (response.error) {
                  data = {
                      reason: response?.error?.text ? response.error.text : response.error.error ?? response.error,
                      status: response.status
                  };
              }
              else {
                  data = {
                      reason: response.message,
                      status: response.status
                  };
            }

              //console.log(response);
            if (isPlatformBrowser(this.platformId)) {
              if (req.url.indexOf("ipinfo") > 0) {
                this.ipInfo.onError();
              }
              else {
                this.errorDialogService.openDialog(data);
              }
              }
              return throwError(response.error);
          }));
  }

    private isSameOriginUrl(req: any) {
        if (isPlatformBrowser(this.platformId)) {
            return true;
        }
    // It's an absolute url with the same origin.
        if (req.url.startsWith(`${this.windowRef.getWindow().location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
        if (req.url.startsWith(`//${this.windowRef.getWindow().location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }

    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }
}
