import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, EventEmitter, Output, Input, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { Languages } from '../app.component';
import { GlobalObjectService } from '../global-object.service';
import { countryService } from '../services/countryService'; // service

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-modal-country-select',
  templateUrl: './modal-country-select.component.html',
  styleUrls: ['./modal-country-select.component.css'],
 
})
export class ModalCountrySelectComponent implements OnInit, AfterViewInit {
  @Input() showModal: boolean;
  @Output() closeModalEvent = new EventEmitter<boolean>();
  get currentCountry(): string {
    return this.countryService.get();
  }

  options = ['Казахстан', 'Россия', 'Другая'];
  shouldDisplayModal: boolean = false;
  currentLang: string;
  /*countrySwap: string = 'RU';*/
  constructor(
    private countryService: countryService,
    private readonly http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object,
    public translate: TranslateService,
    private deviceService: DeviceDetectorService,
    public readonly authService: AuthorizeService,
    public windowRef: GlobalObjectService,

  ) {
    
    


      var userLang = Languages[0].substring(0, 2);

      const savedLang = localStorage.getItem("lang");
      if (savedLang) {
        this.currentLang = savedLang;
      }
      else {
        if (!translate.currentLang) {

          if (Languages.indexOf(userLang) > -1) {
            this.currentLang = Languages[Languages.indexOf(userLang)];
          }
          else {
            this.currentLang = Languages[0];
          }
        }
        else {
          this.currentLang = translate.currentLang;
        }
      }
      //this.currentLang = translate.currentLang;

      translate.onLangChange.subscribe(lang => {
        this.currentLang = lang.lang;
        console.log(lang);
      });
    
  }
    ngOnInit(): void {
      console.log('ModalCountrySelectComponent initialized');

      const showModalValue = localStorage.getItem('showModal');

      if (showModalValue) {
        this.showModal = JSON.parse(showModalValue);
     
      }
    }

 

  onClick() {
    this.countryService.set('Казахстан');
    this.showModal = false;
    /*localStorage.removeItem('showModal');*/
    localStorage.setItem('showModal','false');
  }
  onClick2() {
    this.countryService.set('Россия');
    this.showModal = false;
    /*localStorage.removeItem('showModal');*/
    localStorage.setItem('showModal', 'false');

  }

  onClick3() {
    this.countryService.set('Другая');
    this.showModal = false;
    /*localStorage.removeItem('showModal');*/
    localStorage.setItem('showModal', 'false');

  }

  ngAfterViewInit() {
    
  }

}
