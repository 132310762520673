import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild, HostListener, ViewEncapsulation, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of as observableOf, of } from 'rxjs';
import { ApiService } from '../../app/api.service';
import { map, startWith, merge, debounceTime, distinctUntilChanged, switchMap, catchError, first } from 'rxjs/operators';
import { ConfirmArgs, ConfirmComponent } from '../../app/shared-module/dialogs/confirm/confirm.component';
import { CityModel } from '../../app/models/CityModel';
import { CountryModel } from '../../app/models/CountryModel';
import { FieldOfActivityModel } from '../../app/models/FieldOfActivityModel';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ContactModel } from '../../app/models/ContactModel';
import { AuthorizeService } from '../authorize.service';
import { CampaignModel } from '../../app/models/CampaignModel';
import { BuyCampaignModel } from '../../app/models/BuyCampaignModel';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BankOrderModel } from '../../app/models/BankOrderModel';
import { CampaignProduct } from '../../app/models/enum/CampaignProduct';
import { ErrorDialogService } from '../../app/errordialog.service';
import { PaymentDetailsArgs, PaymentDetailsComponent } from '../../app/shared-module/dialogs/payment-details/payment-details.component';
import { SalesOrderModel } from '../../app/models/SalesOrderModel';
import { Languages } from '../../app/app.component';
import { GlobalObjectService } from '../../app/global-object.service';
import { countryService } from '../../app/services/countryService'; // service


@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    }],
    encapsulation: ViewEncapsulation.Emulated
})


export class RegisterComponent implements OnInit, AfterViewInit  {
  get currentCountry(): string {
    return this.countryService.get();
  }
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    currentLang: string;
    //thirdFormGroup: FormGroup;
    isDesktopDevice = false;

    static MOBILE_WIDTH = 1024;

    filteredCities: CityModel[];
    filteredCountries: CountryModel[];
    filteredOccupations: FieldOfActivityModel[];
    public prefix = "+7";
    public phone_number: string;

    contact: ContactModel;
    country: CountryModel;
    courseId: string;
    campaign: CampaignModel;
    buyModel: BuyCampaignModel = {
        campaignId: "",
        creditCard: {
            cardNumber: "",
            holderName: "",
            expirationMonth: "",
            expirationYear: "",
            cvv: ""
        }
    };
  
    /*private scripts: Scripts[] = [
        { name: 'alfabank', src: 'https://testpay.alfabank.ru/assets/alfa-payment.js' }
    ];*/

    //orderNumber = "test_payment_12";

    isPayed = false;

    @ViewChild('stepper') stepper: MatStepper;
    isLoading: boolean;
    isBrowser: any;
    //@ViewChild('c_number', { static: false }) cardNumber: ElementRef;
    //@ViewChild('validityCvv', { static: false }) validityCvv: ElementRef;

  constructor(private _formBuilder: FormBuilder,
        private countryService: countryService,
        public dialog: MatDialog,
        private router: Router,
        private apiService: ApiService,
        private authService: AuthorizeService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        @Inject(DOCUMENT) private document: Document,
      public windowRef: GlobalObjectService,
      //public dialogRef: MatDialogRef<PaymentDetailsComponent>,
        private errorDialogService: ErrorDialogService,
        @Inject(PLATFORM_ID) private platformId: object,
    ) {
        this.contact = new ContactModel();

        this.isBrowser = isPlatformBrowser(this.platformId);
        //this.loadScript("alfabank");
    }

    /*loadScript(name: string) {
        return new Promise((resolve, reject) => {
                let script = document.createElement('script') as any;
            script.type = 'text/javascript';
            script.id = "alfa-payment-script";
            script.src = "https://testpay.alfabank.ru/assets/alfa-payment.js";
                if (script.readyState) {  //IE
                    script.onreadystatechange = () => {
                        if (script.readyState === "loaded" || script.readyState === "complete") {
                            script.onreadystatechange = null;
                            //this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {  //Others
                    script.onload = () => {
                        //this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
            document.getElementsByTagName('head')[0].appendChild(script);
            console.log("script", script);
            //}
        });
    }*/

 

    ngOnInit(): void {
        this.courseId = this.route.snapshot.paramMap.get('course');
        if (this.courseId) {
          this.apiService.getCampaign(this.courseId).pipe(first()).subscribe(campaign => {
            
              this.campaign = campaign;
              
              this.buyModel.campaignId = this.campaign.id;

            });
        }

        try {
            const savedLang = localStorage.getItem("lang");

            this.translate.onLangChange.subscribe(lang => {
                this.currentLang = lang.lang.substring(0, 2);
            });
            if (savedLang) {
                this.currentLang = savedLang.substring(0, 2);
            }
            else {
                this.currentLang = this.translate.currentLang.substring(0, 2);
            }
            console.log("currentLang", this.currentLang);
        }
        catch (e) {
            console.log("currentLang exception", e);

            var userLang = Languages[0].substring(0, 2); 
            if (isPlatformBrowser(this.platformId)) {
                userLang = this.windowRef.getWindow().navigator.language;
            }
            if (Languages.indexOf(userLang) > -1) {
              this.translate.setDefaultLang(Languages[Languages.indexOf(userLang)]);
                this.currentLang = userLang.substring(0, 2);
            }
            else {
                this.translate.setDefaultLang(Languages[0]);
                this.currentLang = Languages[0].substring(0, 2);
            }
        }



        this.secondFormGroup = this._formBuilder.group({
            name: ['', Validators.required],
            family: ['', Validators.required],
            email: ['', Validators.email],
            mobilePhone: [''],
            occupation: ['', Validators.required],
            country: ['', Validators.required],
            city: ['', Validators.required],
            personalInformation: ['']
        });
        /*this.thirdFormGroup = this._formBuilder.group({
            cardholder: ['', Validators.required],
            validityM: ['', Validators.required],
            validityY: ['', Validators.required],
            cnumber: ['', Validators.required],
            validityCvv: ['', Validators.required],
        });*/

        /*this.authService.contact.subscribe(contact => {
            this.contact = contact;
        });*/

        this.secondFormGroup.get('country').valueChanges
            .pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                map(value => typeof value === 'string' ? value : value.name),
                switchMap(value => {
                    return this.filterCountries(value);
                }),
                catchError(() => {
                    return observableOf([]);
                })
            ).subscribe(data =>
                this.filteredCountries = data as []
            );

        this.secondFormGroup.get('city').valueChanges
            .pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                map(value =>
                    typeof value === 'string' ? value : value.name),
                switchMap(value => {
                    return this.filterCities(value);
                }),
                catchError(() => {
                    return observableOf([]);
                })
            ).subscribe(data => {
                this.filteredCities = data as []
            });

        this.secondFormGroup.get('occupation').valueChanges
            .pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                map(value =>
                    typeof value === 'string' ? value : value.name),
                switchMap(value => {
                    return this.filterOccupations(value);
                }),
                catchError(() => {
                    return observableOf([]);
                })
            ).subscribe(data => {
                this.filteredOccupations = data as []
            });

        //this.authService.reloadContact();
    }

    filterCities(val: string): Observable<CityModel[]> {
        return this.apiService.getCities(this.country?.id, val).pipe(first());
    }  

    filterCountries(val: string): Observable<CountryModel[]> {
        return this.apiService.getCountries(val).pipe(first());
    }  

    filterOccupations(val: string): Observable<FieldOfActivityModel[]> {
        return this.apiService.getFieldOfActivities(val).pipe(first());
    } 

    onCountryChanged(event: MatAutocompleteSelectedEvent) {
        this.country = event.option.value;
        if (this.contact.city?.countryId !== this.country?.id) {
            this.contact.city = null;
            this.contact.cityId = null;
            this.secondFormGroup.get('city').setValue('');
        }

        this.filterCities(this.secondFormGroup.get('city').value).subscribe(data => {
            this.filteredCities = data as []
        });
    }

    onCityChanged(event: MatAutocompleteSelectedEvent) {

        this.contact.city = event.option.value;
        this.contact.cityId = event.option.value.id;

        if (this.contact.city && (event.option.value as CityModel).country) {
            this.country = (event.option.value as CityModel).country;
            this.secondFormGroup.get('country').setValue(this.country);
        }
        else if ((event.option.value as CityModel).id === "") {
            this.country = null;
        }
    }

    onOccupationChanged(event: MatAutocompleteSelectedEvent) {
        this.contact.fieldOfActivity = event.option.value;
        this.contact.fieldOfActivityId = event.option.value.id;
    }

    displayName(item: any): string {
        return item && item.name ? item.name : '';
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.stepper.selectedIndex = 1;
          this.stepper.linear = true;

          this.isLoading = false;
          if (this.isBrowser) {
              this.isDesktopDevice = this.windowRef.getWindow().outerWidth >= RegisterComponent.MOBILE_WIDTH;
          }

            this.authService.contact
                .subscribe((data => {
                    if (!data) {
                        return;
                    }

                    this.contact = data;
                    this.country = data?.city?.country;
                    this.phone_number = data.mobilePhone;

                    if (data?.city?.country)
                        this.secondFormGroup.get('country').setValue(data?.city?.country);

                    if (data?.city)
                        this.secondFormGroup.get('city').setValue(data?.city);

                    if (data?.fieldOfActivity)
                        this.secondFormGroup.get('occupation').setValue(data?.fieldOfActivity);
                }));
        }, 1000);
    }

    public checkProfileError = (controlName: string, errorName: string) => {
        return this.secondFormGroup.controls[controlName].hasError(errorName);
    }

    /*public checkpaymentError = (controlName: string, errorName: string) => {
        return this.thirdFormGroup.controls[controlName].hasError(errorName);
    }*/

    saveProfile() {
        if (!this.validate()) {
            return;
        }

        if (this.country) {
            this.contact.country = this.country;
            this.contact.countryId = this.country.id;
        }

        if (this.phone_number) {
            this.contact.mobilePhone = this.phone_number.startsWith("+7") ? this.phone_number : "+7" + this.phone_number;
        }

        this.apiService.updateContact(this.contact).pipe(first()).subscribe(data => {
            //this.filteredCities = data as []
            this.authService._contact.next(data);
            this.openDialog();
        });
    }

    validate(): boolean {
        return this.secondFormGroup.valid;
    }

    toPayment() {
        this.stepper.linear = false;
        this.stepper.next();
    }


    openDialog() {
        let args = new ConfirmArgs();
        args.isCancelShow = false;
        args.titleRes = "profile_saved";
        args.messageRes = "profile_saved_desc";
        args.okButtonRes = "go_main";

        //const dialogRef = this.dialog.open(ConfirmComponent, {
        //    width: '34.72vw',
        //    data: args
        //});

      console.log("openDialog", this.isDesktopDevice);
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: this.isDesktopDevice === true ? '34.7vw' : '99vw',
        
        data: args
      });

        dialogRef.afterClosed().subscribe(result => {
            this.goToCabinet();
        });
    }

    beforeSelectionChange($event) {
        console.log($event);
    }

    goToCabinet() {
        this.router.navigate([`/cabinet/main`]);
    }

    openPaymentDialog(salesOrder: SalesOrderModel) {
        let args = new PaymentDetailsArgs();
        args.isCancelShow = true;
        args.titleRes = "Order_TrainingCenter";
        args.messageRes = "order details";
        args.okButtonRes = "Pay";
        args.salesOrder = salesOrder;

        console.log("openPaymentDialog", this.isDesktopDevice);
        const dialogRef = this.dialog.open(PaymentDetailsComponent, {
          width: this.isDesktopDevice === true ? '50vw' : '80vw', panelClass: 'trend-dialog',
            data: args
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.event === "Pay") {
                this.doBankPayment(salesOrder.id);
            }
        });
    }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
      if (this.isBrowser) {
          this.isDesktopDevice = event.target.outerWidth >= RegisterComponent.MOBILE_WIDTH;
          console.log("window:resize", event.target.outerWidth);
      }
  }
  

    doPayment() {
        if (!this.validate()) {
            return;
        }

        this.apiService.updateContact(this.contact).pipe(first()).subscribe(data => {
            this.authService._contact.next(data);
            this.createSalesOrder();
        });
    }

    createSalesOrder() {
        this.apiService.createByCampaign(this.campaign.id, CampaignProduct.MainProduct).pipe(first()).subscribe(data => {
            this.openPaymentDialog(data);
        });
    }

    doBankPayment(orderId: string) {
        this.apiService.registerOrder(orderId).pipe(first()).subscribe(response => {
            this.isPayed = true;
            console.log(response);
            if (response.formUrl) {
                this.document.location.href = response.formUrl;
            }
            else {
                let data = {
                    reason: response.errorMessage,
                    status: response.errorCode
                };
                if (isPlatformBrowser(this.platformId)) {
                    this.errorDialogService.openDialog(data);
                }
            }

        });
    }
}

interface Scripts {
    name: string;
    src: string;
}
