import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional, HostListener, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css'],
    encapsulation: ViewEncapsulation.Emulated
})
/** confirm component*/
export class ConfirmComponent {
/** confirm ctor */
  args: ConfirmArgs;
  isDesktopDevice = false;
  isBrowser: any;
  static MOBILE_WIDTH = 1025;
    windowRef: any;
    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public dialogRef: MatDialogRef<ConfirmComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: ConfirmArgs) {
        this.args = data;
    }

    closeDialog() {
        this.dialogRef.close({ event: this.args.okButtonRes });
    }

    cancelDialog() {
        this.dialogRef.close({ event: this.args.cancelButtonRes });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDesktopDevice = event.target.outerWidth >= ConfirmComponent.MOBILE_WIDTH;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.isBrowser) {
        this.isDesktopDevice = this.windowRef.getWindow().outerWidth >= ConfirmComponent.MOBILE_WIDTH;
      }
    }, 200);
  }
}

export class ConfirmArgs {
    titleRes: string;
    messageRes: string;
    okButtonRes = "OK";
    cancelButtonRes = "Cancel";
    isCancelShow = true;
    arguments: any;
}
