
<div class="d-sm-inline d-md-inline d-lg-none" style="">
  <div class=" text-center modal-container">
    <h1 mat-dialog-title class="dark-text-color-table dark-text-color-mobile"><strong>{{args.titleRes | translate}}</strong></h1>
    <div class="d-body mat-dialog-content-table">

      <div class="row">
        <div class="col-2 reg-label dark-text-color-table-small dark-text-color-mobile-small text-left">
          {{'To_whom' | translate}}
        </div>
      </div>
      <div class="row">
        <div class="col-2 mt-2 pb-2">
          <div class="reg-label dark-text-color-table-bold dark-text-color-mobile-bold text-left"><b>{{args.salesOrder?.contact?.fullName}}</b></div>
        </div>
      </div>
      <mat-divider class="divider mt-3"></mat-divider>

      <h1 class="dark-text-color-table dark-text-color-mobile text-left pt-3 mb-4"><strong>{{'Summary' | translate}}</strong></h1>

      <table class="order-table">
        <tr>
          <td class="dark-text-color-table-small dark-text-color-mobile-small">{{'Total_amount' | translate}}</td>
          <td class="cell-seperator"></td>
          
          <td class="reg-label dark-text-color-table-bold dark-text-color-mobile-bold text-left"><b>{{ args.salesOrder?.totalAmount}} {{args.salesOrder?.currency?.isoCode}} {{args.salesOrder?.currency?.symbol}}</b></td>

          <!--<td *ngIf="currentCountry==='RU'" class="reg-label dark-text-color-table-bold dark-text-color-mobile-bold text-left"><b>{{ args.salesOrder?.totalAmount | currency:'RUB' : 'symbol' :'1.2-2'}}</b></td>
  <td *ngIf="currentCountry==='KZ'" class="reg-label dark-text-color-table-bold dark-text-color-mobile-bold text-left"><b>{{ args.salesOrder?.totalAmount | currency:'KZT' : 'symbol' :'1.2-2'}}</b></td>-->

        </tr>
        <tr>
          <td class="dark-text-color-table-small dark-text-color-mobile-small pt-3">{{'Order_number' | translate}}</td>
          <td class="cell-seperator"></td>
          <td class="reg-label dark-text-color-table-bold dark-text-color-mobile-bold text-left pt-3"><b>{{args.salesOrder?.orderNumber}}</b></td>
        </tr>
        <tr>
          <td class="dark-text-color-table-small dark-text-color-mobile-small text-right pt-3">{{'Date' | translate}}</td>
          <td class="cell-seperator"></td>
          <td class="reg-label dark-text-color-table-bold dark-text-color-mobile-bold text-left pt-3"><b>{{args.salesOrder?.orderDate | localizedDate : 'dd.MM.yyyy'}}</b></td>
        </tr>
      </table>
      <mat-divider class="divider mt-4"></mat-divider>

      <h1 class="dark-text-color-table dark-text-color-mobile text-left pt-3"><strong>{{'Details' | translate}}</strong></h1>

      <div class="mt-3 mb-1" *ngFor="let item of args.salesOrder?.salesOrderDetails">
        <div fxLayout="row" style="">
          <div class="col px-0">
            <div class=" dark-text-color-table-bold dark-text-color-mobile-bold text-left"><b>{{'Product' | translate}}</b></div>
            <div class="dark-text-color-table-small dark-text-color-mobile-small text-left">{{item.product?.name}}</div>
            <div class="pt-2 dark-text-color-table-bold  dark-text-color-mobile-bold text-left"><b>{{'Qty' | translate}}</b></div>
            <div class="dark-text-color-table-small dark-text-color-mobile-small text-left">{{item.quantity | number:'1.2-3'}}</div>
            <div class="pt-2 dark-text-color-table-bold dark-text-color-mobile-bold text-left"><b>{{'Price' | translate}}</b></div>

            <div class="dark-text-color-table-small dark-text-color-mobile-small text-left">{{item.pricePerUnit}} {{args.salesOrder?.currency?.isoCode}} {{args.salesOrder?.currency?.symbol}}</div>

            <!--<div *ngIf="currentCountry==='RU'" class="dark-text-color-table-small dark-text-color-mobile-small text-left">{{item.pricePerUnit | currency:'RUB' : 'symbol' :'1.2-2'}}</div>
  <div *ngIf="currentCountry==='KZ'" class="dark-text-color-table-small dark-text-color-mobile-small text-left">{{item.pricePerUnit | currency:'KZT' : 'symbol' :'1.2-2'}}</div>-->

            <div class="pt-2 dark-text-color-table-bold dark-text-color-mobile-bold text-left"><b>{{'Subtotal' | translate}}</b></div>
            <div class="dark-text-color-table-small dark-text-color-mobile-small text-left">{{item.extendedAmount}} {{args.salesOrder?.currency?.isoCode}} {{args.salesOrder?.currency?.symbol}}</div>

            <!--<div *ngIf="currentCountry==='RU'" class="dark-text-color-table-small dark-text-color-mobile-small text-left">{{item.extendedAmount | currency:'RUB' : 'symbol' :'1.2-2'}}</div>
            <div *ngIf="currentCountry==='KZ'" class="dark-text-color-table-small dark-text-color-mobile-small text-left">{{item.extendedAmount | currency:'KZT' : 'symbol' :'1.2-2'}}</div>-->

          </div>




        </div>
        <mat-divider class="divider mt-3"></mat-divider>
      </div>
      <div fxLayout="row" class="mt-3" fxLayoutAlign="">
        <div class="dark-text-color-table-bold dark-text-color-mobile-bold"><b>{{'Total' | translate}}</b></div>

        <div class="dark-text-color-table-bold dark-text-color-mobile-bold ml-3"><b>{{args.salesOrder?.totalAmount}} {{args.salesOrder?.currency?.isoCode}} {{args.salesOrder?.currency?.symbol}}</b></div>

  <!--<div *ngIf="currentCountry==='RU'" class="dark-text-color-table-bold dark-text-color-mobile-bold ml-3"><b>{{args.salesOrder?.totalAmount | currency:'RUB' : 'symbol' :'1.2-2'}}</b></div>
  <div *ngIf="currentCountry==='KZ'" class="dark-text-color-table-bold dark-text-color-mobile-bold ml-3"><b>{{args.salesOrder?.totalAmount | currency:'KZT' : 'symbol' :'1.2-2'}}</b></div>-->

      </div>
      <mat-divider class="divider mt-3 mb-3"></mat-divider>
      <div class="mt-4">
        
        <button mat-flat-button class="b-secondary-table b-secondary-mobile" color="secondary" (click)="closeDialog()">{{args.okButtonRes | translate}}</button>

      </div>
      <div class="">
        <button mat-flat-button *ngIf="args.isCancelShow" class="b-secondary-table-transparent b-secondary-mobile-transparent" (click)="cancelDialog()">{{args.cancelButtonRes | translate}}</button>
      </div>
    </div>
    
  </div>
</div>

<div class="d-none d-lg-inline">
  <div class="text-center modal-container">
    <h1 mat-dialog-title class="dark-text-color"><strong>{{args.titleRes | translate}}</strong></h1>
    <div mat-dialog-content class="d-body">

      <div class="row">
        <div class="col-2 reg-label dark-text-color text-left">
          {{'To_whom' | translate}}
        </div>
      </div>
      <div class="row">
        <div class="col-2 mt-2 pb-2">
          <div class="reg-label dialog-text text-left"><b>{{args.salesOrder?.contact?.fullName}}</b></div>
        </div>
      </div>
      <mat-divider class="divider mt-3"></mat-divider>

      <h1 class="dark-text-color text-left pt-3 mb-4"><strong>{{'Summary' | translate}}</strong></h1>

      <table class="order-table">
        <tr>
          <td class="dialog-text">{{'Total_amount' | translate}}</td>
          <td class="cell-seperator"></td>
         
          <td class="reg-label dialog-text text-left"><b>{{ args.salesOrder?.totalAmount}} {{args.salesOrder?.currency?.isoCode}} {{args.salesOrder?.currency?.symbol}}</b></td>
          <!--<td *ngIf="currentCountry==='RU'" class="reg-label dialog-text text-left"><b>{{ args.salesOrder?.totalAmount | currency:'RUB' : 'symbol' :'1.2-2'}}</b></td>
          <td *ngIf="currentCountry==='KZ'" class="reg-label dialog-text text-left"><b>{{ args.salesOrder?.totalAmount | currency:'KZT' : 'symbol' :'1.2-2'}}</b></td>-->

        </tr>
        <tr>
          <td class="dialog-text pt-3">{{'Order_number' | translate}}</td>
          <td class="cell-seperator"></td>
          <td class="reg-label dialog-text text-left pt-3"><b>{{args.salesOrder?.orderNumber}}</b></td>
        </tr>
        <tr>
          <td class="dialog-text text-right pt-3">{{'Date' | translate}}</td>
          <td class="cell-seperator"></td>
          <td class="reg-label dialog-text text-left pt-3"><b>{{args.salesOrder?.orderDate | localizedDate : 'dd.MM.yyyy'}}</b></td>
        </tr>
      </table>
      <mat-divider class="divider mt-4"></mat-divider>

      <h1 class="dark-text-color text-left pt-3"><strong>{{'Details' | translate}}</strong></h1>

      <div class="mt-4">
        <div fxLayout="row">
          <div fxFlex="37" class="dialog-text text-left"><b>{{'Product' | translate}}</b></div>
          <div fxFlex="13" class="dialog-text text-center"><b>{{'Qty' | translate}}</b></div>
          <div fxFlex="25" class="dialog-text text-right"><b>{{'Price' | translate}}</b></div>
          <div fxFlex="25" class="dialog-text text-right"><b>{{'Subtotal' | translate}}</b></div>
        </div>
        <mat-divider class="divider mt-2"></mat-divider>
        <div class="mt-3 mb-1" *ngFor="let item of args.salesOrder?.salesOrderDetails">
          <div fxLayout="row">
            <div fxFlex="37" class="text-left">{{item.product?.name}}</div>
            <div fxFlex="13" class="text-center">{{item.quantity | number:'1.2-3'}}</div>

            <div fxFlex="25" class="text-right">{{item.pricePerUnit}} {{args.salesOrder?.currency?.isoCode}} {{args.salesOrder?.currency?.symbol}}</div>
            <div fxFlex="25" class="text-right">{{item.extendedAmount}} {{args.salesOrder?.currency?.isoCode}} {{args.salesOrder?.currency?.symbol}}</div>
            <!--<div *ngIf="currentCountry==='RU'" fxFlex="25" class="text-right">{{item.pricePerUnit | currency:'RUB' : 'symbol' :'1.2-2'}}</div>
  <div *ngIf="currentCountry==='KZ'" fxFlex="25" class="text-right">{{item.pricePerUnit | currency:'KZT' : 'symbol' :'1.2-2'}}</div>

  <div *ngIf="currentCountry==='RU'" fxFlex="25" class="text-right">{{item.extendedAmount | currency:'RUB' : 'symbol' :'1.2-2'}}</div>
  <div *ngIf="currentCountry==='KZ'" fxFlex="25" class="text-right">{{item.extendedAmount | currency:'KZT' : 'symbol' :'1.2-2'}}</div>-->

          </div>
          <mat-divider class="divider mt-3"></mat-divider>
        </div>
        <div fxLayout="row" class="mt-3" fxLayoutAlign="end center">
          <div class="dialog-text"><b>{{'Total' | translate}}</b></div>
          <div class="dialog-text ml-3"><b>{{args.salesOrder?.totalAmount}} {{args.salesOrder?.currency?.isoCode}} {{args.salesOrder?.currency?.symbol}}</b></div>

          <!--<div *ngIf="currentCountry==='RU'" class="dialog-text ml-3"><b>{{args.salesOrder?.totalAmount | currency:'RUB' : 'symbol' :'1.2-2'}}</b></div>
          <div *ngIf="currentCountry==='KZ'" class="dialog-text ml-3"><b>{{args.salesOrder?.totalAmount | currency:'KZT' : 'symbol' :'1.2-2'}}</b></div>-->

        </div>
        <mat-divider class="divider mt-3 mb-3"></mat-divider>
      </div>
    </div>
    <div class="buttons-panel" fxLayout="row"
         fxLayoutAlign="end center">

      <button mat-flat-button fxFlex="24" class="b-secondary-dialog" color="secondary" (click)="closeDialog()">{{args.okButtonRes | translate}}</button>
      <button mat-flat-button fxFlex="24" *ngIf="args.isCancelShow" class="b-secondary-dialog-transparent ml-4" (click)="cancelDialog()">{{args.cancelButtonRes | translate}}</button>

    </div>
  </div>
</div>

