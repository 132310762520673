import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GlobalObjectService } from '../../../global-object.service';
import { SalesOrderModel } from '../../../models/SalesOrderModel';
import { countryService } from '../../../../app/services/countryService'; // service


@Injectable({
  providedIn: 'root'
})
@Component({
    selector: 'app-payment-details',
    templateUrl: './payment-details.component.html',
    styleUrls: ['./payment-details.component.css']
})
/** PaymentDetails component*/
export class PaymentDetailsComponent implements AfterViewInit {
  /** PaymentDetails ctor */
  get currentCountry(): string {
    return this.countryService.get();
  }
  args: PaymentDetailsArgs;
    isDesktopDevice = false;
    isBrowser: any;

  static MOBILE_WIDTH = 1024;
  constructor(
    private countryService: countryService,
        public translate: TranslateService,
        private http: HttpClient,
        public dialogRef: MatDialogRef<PaymentDetailsComponent>,
        public windowRef: GlobalObjectService,
        @Inject(PLATFORM_ID) private platformId: object,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: PaymentDetailsArgs) {
        this.args = data;
    }

    closeDialog() {
        this.dialogRef.close({ event: this.args.okButtonRes });
    }

    cancelDialog() {
        this.dialogRef.close({ event: this.args.cancelButtonRes });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isDesktopDevice = event.target.outerWidth >= PaymentDetailsComponent.MOBILE_WIDTH;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.isBrowser) {
                this.isDesktopDevice = this.windowRef.getWindow().outerWidth >= PaymentDetailsComponent.MOBILE_WIDTH;
            }
        }, 200);
    }
}

export class PaymentDetailsArgs {
    titleRes: string;
    messageRes: string;
    okButtonRes = "OK";
    cancelButtonRes = "Cancel";
    isCancelShow = true;
    salesOrder: SalesOrderModel;
}
