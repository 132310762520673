import { Component, EventEmitter, Inject, Injectable, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizeService } from '../../../api-authorization/authorize.service';
import { Languages } from '../../app.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { GlobalObjectService } from '../../global-object.service';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { countryService } from '../../services/countryService'; // service

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-oferta-kz',
  templateUrl: './oferta-kz.component.html',
  styleUrls: ['./oferta-kz.component.css']
})
export class OfertaKZComponent implements OnInit {
  options = ['Казахстан', 'Россия', 'Другая'];
  countrySwap: string = 'RU';
  firstName: any;
  lastName: any;
  currentLang: any;
  fullpage_api: any;
  @Output() sidenavClose = new EventEmitter();
  get currentCountry(): string {
    return this.countryService.get();
  }

  constructor(
    private countryService: countryService,
    public translate: TranslateService,
    private router: Router,
    private readonly http: HttpClient,
    public readonly authService: AuthorizeService,
    private apiService: ApiService,
    public windowRef: GlobalObjectService,
    private scroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: object,
    private meta: Meta,
    private title: Title
  ) {
    this.countrySwap = localStorage.getItem('option');
    this.title.setTitle("Онлайн-обучение и экзамен PRINCE2");
    this.meta.updateTag(
      { name: 'description', content: 'Управление проектами на основе метода PRINCE2. Видео курс и сертификация PRINCE2 Foundation на русском языке' });
    this.meta.updateTag(
      { name: 'keywords', content: '' });
    var userLang = Languages[0].substring(0, 2);
    if (isPlatformBrowser(this.platformId)) {
      userLang = this.windowRef.getWindow().navigator.language;
    }

    const savedLang = localStorage.getItem("lang");
    if (savedLang) {
      this.currentLang = savedLang;
    }
    else {
      if (!translate.currentLang) {

        if (Languages.indexOf(userLang) > -1) {
          this.currentLang = Languages[Languages.indexOf(userLang)];
        }
        else {
          this.currentLang = Languages[0];
        }
      }
      else {
        this.currentLang = translate.currentLang;
      }
    }
    //this.currentLang = translate.currentLang;

    translate.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
      console.log(lang);
    });


    this.authService.contact.subscribe(contact => {
      this.firstName = contact?.firstName;
      this.lastName = contact?.lastName;
    });


  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  goDown1() {
    this.router.navigate(['/'], { fragment: 'courses' });
  }
  goDown2() {
    this.router.navigate(['/'], { fragment: 'education' });
  }
  goDown3() {
    this.router.navigate(['/'], { fragment: 'comments' });
  }
  goDown4() {
    this.router.navigate(['/'], { fragment: 'about' });
  }

  ngOnInit(): void {
  }
  getRef(servicesPage) {
    this.fullpage_api = servicesPage;
  }

  cabinet() {
    this.router.navigate([`cabinet`]);
  }

  logout() {
    this.authService.logout();
  }

  onCountryChange() {

    this.countrySwap = localStorage.getItem('option');
    /*alert(this.countrySwap);*/
  }
  switchLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
  }

  login() {
    this.router.navigate([`authentication/login`]);
  }

}
