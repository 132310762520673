

<div class="d-sm-inline d-md-inline d-lg-none">
  <img class="position-absolute" alt="loginBg" style=" width: 100%; height: 100%; object-fit: fill;" src="/images/login-bg-mobile.svg" />
  <div class="row">
    <div class="col-12" style="margin-top:5vh;margin-bottom: 5vh;text-align: center;">
      <a href="/#main" class="">
        <img src="/images/logo.svg" alt="logo" style="width: 20em;" />
      </a>
    </div>
  </div>
  <div class="">
    <mat-card class="register-container-card-table register-container-card-mobile">
      <div class="">
        

        
        
        <div class="" style="text-align:center;"><img class="register-icon-table register-icon-mobile" src="/images/payment-mobile-icon.svg" alt="profileIcon" style="" /><span class="profile-title-table profile-title-mobile">{{'payment' | translate}}</span></div>
          <div class="welcom-text-table welcom-text-mobile" style="margin-top: 2.2vh;">{{'you_payed' | translate}}</div>
          <div fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" class="" fxLayoutAlign="start center">
              <img src="/images/complete.svg" alt="complete" />
              <div fxFlex class="ml-3 success-text-table success-text-mobile">{{'payment_successful' | translate}}</div>
            </div>
            <div class="success-text-black-table success-text-black-mobile pt-4">{{'you_payed' | translate}}: {{productNames}}</div>
            <div class="success-text-black-table success-text-black-mobile">{{'paymant_amount' | translate}}  <b>{{paymentResult?.totalAmount}} {{paymentResult?.currency.isoCode}} {{paymentResult?.currency.symbol}}</b></div>

            <a class="success-text-black-table success-text-black-mobile mt-4 hand" style="text-decoration:underline;" (click)="orderDetails(paymentResult.id)">{{'payment_info' | translate}}</a>

            <div fxLayout="row" fxLayoutAlign="start stretch" style="margin-top: 4.73vh;margin-bottom:2vh;">
              <button *ngIf="paymentResult?.isExamProductPresent === false" mat-raised-button mat-flat-button color="secondary"
                      class="b-primary success-btn-text-table b-registration-mobile" (click)="goToCourse()">
                {{'start_study' | translate}}
              </button>
              <button *ngIf="paymentResult?.isExamProductPresent" mat-raised-button mat-flat-button color="secondary"
                      class="b-primary b-registration" (click)="goToCabinet()">
                {{'Go_to_the_cabinet' | translate}}
              </button>
            </div>
          </div>
        

      </div>
    </mat-card>
  </div>

</div>



<div class="d-none d-lg-inline">
  <img class="position-fixed h-100 w-100" alt="logoBG" src="/images/login_bg.svg" />
  <a href="/#main" class="logo-img">
    <img src="/images/logo.svg" alt="logo" style="width: 8em;" />
  </a>
  <div fxLayout="row"
       fxFlexFill
       fxLayoutAlign="center center">
    <mat-card class="register-card">
      <mat-horizontal-stepper #stepper>
        <ng-template matStepperIcon="registration"><img src="/images/edit.svg" alt="edit" /></ng-template>
        <ng-template matStepperIcon="listener_data"><img src="/images/id-card.svg" alt="idCard" /></ng-template>
        <ng-template matStepperIcon="payment"><img src="/images/credit-card.svg" alt="creditCard" /></ng-template>

        <mat-step completed="false" editable="false" state="registration" label="{{'registration' | translate}}" errorMessage="">
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" completed="false" editable="false" state="listener_data" label="{{'listener_data' | translate}}" errorMessage="">
        </mat-step>
        <mat-step state="payment" label="{{'payment' | translate}}" errorMessage="">
          <div class="mat-body-1 font-weight-bold dark-text-color" style="margin-top: 2.2vh;">{{'you_payed' | translate}}</div>
          <div fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" class="my-4" fxLayoutAlign="start center">
              <img src="/images/complete.svg" alt="complete" />
              <div fxFlex class="ml-3 reg-label dark-text-color font-weight-bold">{{'payment_successful' | translate}}</div>
            </div>
            <div class="reg-label dark-text-color">{{'you_payed' | translate}}: {{productNames}}</div>
            <div  class="reg-label dark-text-color mt-2">{{'paymant_amount' | translate}}  <b>{{paymentResult?.totalAmount}} {{paymentResult?.currency.isoCode}} {{paymentResult?.currency.symbol}}</b></div>

            <a class="reg-label dark-text-color mt-4 hand" (click)="orderDetails(paymentResult.id)">{{'payment_info' | translate}}</a>

            <div fxLayout="row" fxLayoutAlign="start stretch" style="margin-top: 4.73vh;">
              <button *ngIf="paymentResult?.isExamProductPresent === false" mat-raised-button mat-flat-button color="secondary"
                      class="b-primary b-registration" (click)="goToCourse()">
                {{'start_study' | translate}}
              </button>
              <button *ngIf="paymentResult?.isExamProductPresent" mat-raised-button mat-flat-button color="secondary"
                      class="b-primary b-registration" (click)="goToCabinet()">
                {{'Go_to_the_cabinet' | translate}}
              </button>
            </div>
          </div>
        </mat-step>

      </mat-horizontal-stepper>
    </mat-card>
  </div>
</div>


