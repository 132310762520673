import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Request } from '../app/models/Request';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CityModel } from './models/CityModel';
import { CountryModel } from './models/CountryModel';
import { FieldOfActivityModel } from './models/FieldOfActivityModel';
import { ContactModel } from './models/ContactModel';
import { ContactCourseModel } from './models/ContactCourseModel';
import { CampaignModel } from './models/CampaignModel';
import { CourseFeedBackModel } from './models/CourseFeedBackModel';
import { TopicMaterialModel } from './models/TopicMaterialModel';
import { TrainingStageModel } from './models/TrainingStageModel';
import { PracticeTestQuestionModel } from './models/PracticeTestQuestionModel';
import { MessageModel } from './models/MessageModel';
import { CourseTopicQuestionModel } from './models/CourseTopicQuestionModel';
import { CourseTopicFaqModel } from './models/CourseTopicFaqModel';
import { PracticeTestResultModel } from './models/PracticeTestResultModel';
import { PracticeTestModel } from './models/PracticeTestModel';
import { BuyCampaignModel } from './models/BuyCampaignModel';
import { MySummaryStateModel } from './models/MySummaryStateModel';
import { SalesOrderModel } from './models/SalesOrderModel';
import { RegisterBankOrderResponse } from './models/RegisterBankOrderResponse';
import { BankOrderModel } from './models/BankOrderModel';
import { CampaignProduct } from './models/enum/CampaignProduct';
import { DataChangeRequestModel } from './models/DataChangeRequestModel';
import { SupportRequestModel } from './models/SupportRequestModel';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
    apiURL: string = '../api';
    constructor(public httpClient: HttpClient) {
        
    }

    public static GUID_EMPTY = "00000000-0000-0000-0000-000000000000";
    public setContact(user: any): Observable<ContactModel> {
        return this.httpClient.post<ContactModel>(`${this.apiURL}/Contact/Set`, user);
    }

    public getContact(): Observable<ContactModel> {
        return this.httpClient.get<ContactModel>(`${this.apiURL}/Contact/Get`);
    }

    public updateContact(contact: ContactModel): Observable<ContactModel> {
        //console.log(JSON.stringify(contact));
        return this.httpClient.post<ContactModel>(`${this.apiURL}/Contact/Update`, contact);
    }
    

    public findCities(value: string): Observable<Object> {
        return this.httpClient.get<any>(`${this.apiURL}/Dict/GetCities`);
    }

    public getCities(countryId: string, filter: string): Observable<CityModel[]> {
        var id = ApiService.GUID_EMPTY;
        if (countryId) {
            id = countryId;
        }
        return this.httpClient.get<CityModel[]>(`${this.apiURL}/Dict/GetCities?countryId=${encodeURIComponent(id)}&filter=${encodeURIComponent(filter)}`);
    }

    public getCountries(filter: string): Observable<CountryModel[]> {
        return this.httpClient.get<CountryModel[]>(`${this.apiURL}/Dict/GetCountries?filter=${encodeURIComponent(filter)}`);
    }

    public getFieldOfActivities(filter: string): Observable<FieldOfActivityModel[]> {
        return this.httpClient.get<FieldOfActivityModel[]>(`${this.apiURL}/Dict/GetFieldOfActivities?filter=${encodeURIComponent(filter)}`);
    }

    public getContactCourseList(): Observable<ContactCourseModel[]> {
        return this.httpClient.get<ContactCourseModel[]>(`${this.apiURL}/ContactCourse/GetList`);
    }

    public freeze(contactCourseId: string): Observable<ContactCourseModel> {
        return this.httpClient.get<ContactCourseModel>(`${this.apiURL}/ContactCourse/Freeze?contactCourseId=${encodeURIComponent(contactCourseId)}`);
    }

    public unFreeze(contactCourseId: string): Observable<ContactCourseModel> {
        return this.httpClient.get<ContactCourseModel>(`${this.apiURL}/ContactCourse/UnFreeze?contactCourseId=${encodeURIComponent(contactCourseId)}`);
    }

    public getMyCourse(contactCourseId: string): Observable<ContactCourseModel> {
        return this.httpClient.get<ContactCourseModel>(`${this.apiURL}/ContactCourse/GetMyCourse?contactCourseId=${encodeURIComponent(contactCourseId)}`);
    }

    public getCampaign(campaignId: string): Observable<CampaignModel> {
        return this.httpClient.get<CampaignModel>(`${this.apiURL}/Campaign/Get?campaignId=${encodeURIComponent(campaignId)}`);
    }

    public getCampaignList(): Observable<CampaignModel[]> {
        return this.httpClient.get<CampaignModel[]>(`${this.apiURL}/Campaign/GetList`);
    }

    public getCourseFeedbackList(courseId: string, page: number, pageSize: number): Observable<CourseFeedBackModel[]> {
        return this.httpClient.get<CourseFeedBackModel[]>(`${this.apiURL}/CourseFeedBack/GetList?courseId=${encodeURIComponent(courseId)}&page=${page}&pageSize=${pageSize}`);
    }

    public getAllFeedbackList(page: number, pageSize: number): Observable<CourseFeedBackModel[]> {
        return this.httpClient.get<CourseFeedBackModel[]>(`${this.apiURL}/CourseFeedBack/GetList?&page=${page}&pageSize=${pageSize}`);
    }

    public addCourseFeedback(feedBack: CourseFeedBackModel): Observable<string> {
        if (!feedBack.contactId) {
            feedBack.contactId = ApiService.GUID_EMPTY;
        }
        return this.httpClient.post<string>(`${this.apiURL}/CourseFeedBack/Add`, feedBack);
    }

    public getMyCourseFeedBack(courseId: string): Observable<CourseFeedBackModel> {
        return this.httpClient.get<CourseFeedBackModel>(`${ this.apiURL }/CourseFeedBack/GetMyCourseFeedBack?courseId=${encodeURIComponent(courseId)}`);
    }

    public getTopicMaterial(topicMaterialId: string): Observable<any> {
        return this.httpClient.get<any>(`${this.apiURL }/Download/GetTopicMaterial?topicMaterialId=${encodeURIComponent(topicMaterialId)}`);
    }

    public getStageMaterials(trainingStageId: string): Observable<TopicMaterialModel[]> {
        return this.httpClient.get<TopicMaterialModel[]>(`${this.apiURL }/TrainingStage/GetStageMaterials?trainingStageId=${encodeURIComponent(trainingStageId)}`);
    }

    public getCourseTopicZip(courseTopicId: string): Observable<any> {
        return this.httpClient.get<any>(`${this.apiURL }/Download/GetCourseTopicZip?courseTopicId=${encodeURIComponent(courseTopicId)}`);
    }

    public getCourseZip(courseId: string): Observable<any> {
        return this.httpClient.get<any>(`${this.apiURL }/Download/GetCourseZip?courseId=${encodeURIComponent(courseId)}`);
    }

    public getDetailedStage(trainingStageId: string): Observable<TrainingStageModel> {
        return this.httpClient.get<TrainingStageModel>(`${this.apiURL}/TrainingStage/GetDetailedStage?trainingStageId=${encodeURIComponent(trainingStageId)}`);
    }

    public getStageExam(trainingStageId: string): Observable<PracticeTestQuestionModel[]> {
        return this.httpClient.get<PracticeTestQuestionModel[]>(`${this.apiURL}/TrainingStage/GetStageExam?trainingStageId=${encodeURIComponent(trainingStageId)}`);
    }

    public setStagePassed(trainingStageId: string): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.apiURL}/ContactCourse/SetStagePassed?trainingStageId=${encodeURIComponent(trainingStageId)}`);
    }

    public getMyNotifications(page: number, pageSize: number): Observable<MessageModel[]> {
        return this.httpClient.get<MessageModel[]>(`${this.apiURL}/Notification/GetMyNotifications?page=${page}&pageSize=${pageSize}`);
    }

    public getMyUnreadNotifications(): Observable<MessageModel[]> {
        return this.httpClient.get<MessageModel[]>(`${this.apiURL}/Notification/GetMyUnreadNotifications`);
    }

    public markMessageAsRead(messageId: string): Observable<void> {
        return this.httpClient.get<void>(`${this.apiURL}/Notification/MarkMessageAsRead?messageId=${encodeURIComponent(messageId)}`);
    }

    public addTopicQuestion(question: CourseTopicQuestionModel): Observable<CourseTopicQuestionModel> {
        return this.httpClient.post<CourseTopicQuestionModel>(`${this.apiURL}/CourseTopicQuestion/Add`, question);
    }

    public getList(courseTopicId: string, page: number, pageSize: number): Observable<CourseTopicQuestionModel[]> {
        return this.httpClient.get<CourseTopicQuestionModel[]>(`${this.apiURL}/CourseTopicQuestion/GetList?courseTopicId=${encodeURIComponent(courseTopicId)}&page=${page}&pageSize=${pageSize}`);
    }

    public getMyQuestions(page: number, pageSize: number): Observable<CourseTopicQuestionModel[]> {
        return this.httpClient.get<CourseTopicQuestionModel[]>(`${this.apiURL}/CourseTopicQuestion/GetMyQuestions?page=${page}&pageSize=${pageSize}`);
    }

    public getStageFaq(trainingStageId: string, page: number, pageSize: number): Observable<CourseTopicFaqModel[]> {
        return this.httpClient.get<CourseTopicFaqModel[]>(`${this.apiURL}/TrainingStage/GetStageFaq?trainingStageId=${encodeURIComponent(trainingStageId)}&page=${page}&pageSize=${pageSize}`);
    }

    public getCourseExam(contactCourseId: string): Observable<PracticeTestModel> {
        return this.httpClient.get<PracticeTestModel>(`${this.apiURL}/ContactCourse/GetCourseExam?contactCourseId=${encodeURIComponent(contactCourseId)}`);
    }

    public setEmailNotify(isNotify: boolean): Observable<void> {
        return this.httpClient.get<void>(`${this.apiURL}/Contact/SetEmailNotify?isNotify=${isNotify}`);
    }

    public saveCourseExamResults(result: PracticeTestResultModel): Observable<PracticeTestModel> {
        return this.httpClient.post<PracticeTestModel>(`${this.apiURL}/ContactCourse/SaveCourseExamResults`, result);
    }

    public getCourseExamResults(contactCourseId: string): Observable<PracticeTestModel> {
        return this.httpClient.get<PracticeTestModel>(`${this.apiURL}/ContactCourse/GetCourseExamResults?contactCourseId=${encodeURIComponent(contactCourseId)}`);
    }

    public processPayment(bcm: BuyCampaignModel): Observable<void> {
        return this.httpClient.post<void>(`${this.apiURL}/Payment/ProcessPayment`, bcm);
    }

    public orderExam(contactCourseId: string): Observable<PracticeTestModel> {
        return this.httpClient.get<PracticeTestModel>(`${this.apiURL}/Exam/OrderExam?contactCourseId=${encodeURIComponent(contactCourseId)}`);
    }

    public getExamInfo(contactCourseId: string): Observable<PracticeTestModel> {
        return this.httpClient.get<PracticeTestModel>(`${this.apiURL}/Exam/GetExamInfo?contactCourseId=${encodeURIComponent(contactCourseId)}`);
    }

    public getMySummaryState(): Observable<MySummaryStateModel> {
        return this.httpClient.get<MySummaryStateModel>(`${this.apiURL}/Contact/GetMySummaryState`);
    }

    public getMyOrders(): Observable<SalesOrderModel[]> {
        return this.httpClient.get<SalesOrderModel[]>(`${this.apiURL}/SalesOrder/GetMyOrders`);
    }

    public registerOrder(orderId: string): Observable<RegisterBankOrderResponse> {
        const formData = new FormData();
        formData.append('orderId', orderId);
        return this.httpClient.post<RegisterBankOrderResponse>(`${this.apiURL}/Payment/RegisterOrder`, formData);
    }

    public processBankPayment(orderId: string): Observable<SalesOrderModel> {
        const formData = new FormData();
        formData.append('orderId', orderId);
        return this.httpClient.post<SalesOrderModel>(`${this.apiURL}/Payment/ProcessPayment`, formData);
    }

    public createByCampaign(campaignId: string, product: CampaignProduct): Observable<SalesOrderModel> {
        const formData = new FormData();
        formData.append('campaignId', campaignId);
        formData.append('campaignProduct', product.toString());
        return this.httpClient.post<SalesOrderModel>(`${this.apiURL}/SalesOrder/CreateByCampaign`, formData);
    }

    public createByContactCourse(contactCourseId: string, product: CampaignProduct): Observable<SalesOrderModel> {
        const formData = new FormData();
        formData.append('contactCourseId', contactCourseId);
        formData.append('campaignProduct', product.toString());
        return this.httpClient.post<SalesOrderModel>(`${this.apiURL}/SalesOrder/CreateByContactCourse`, formData);
    }

    public changeAnthroponym(request: DataChangeRequestModel): Observable<string> {
        return this.httpClient.post<string>(`${this.apiURL}/Contact/ChangeAnthroponym`, request);
    }

    public createSupportRequest(request: SupportRequestModel): Observable<string> {
        return this.httpClient.post<string>(`${this.apiURL}/Support/CreateSupportRequest`, request);
    }
    
}
