
<div class="rightimage">
  <img src="/images/rightImage.svg" alt="rightImage"/>
</div>


<app-courses-toolbar></app-courses-toolbar>

<div class="" id="" style="padding-right: 28.80vw;
    padding-left: 7.70vw;">
  <div class="row" style="
    margin-top: 150px;
    margin-bottom: 20px;
">
    <div class="col">
      <div class="mc-1 mb-3 mat-display-4">PRINCE2 PRACTITIONER</div>
    </div>
  </div>


  <shared-practitioner-en></shared-practitioner-en>


  <div class="row" style="margin-bottom:17.22vh;">
    <div class="col">
      <button class="px-0 buttonstyle" mat-button color="accent" (click)="buyCourse('19D5FCF4-7D51-EB11-80E1-00155D784603')" style="width: 15vw;font-size: 1.125em;">Take Training</button>
    </div>
  </div>
</div>

<app-courses-footer></app-courses-footer>


