
<div class="d-sm-inline d-md-inline d-lg-none">
  <div class="text-center modal-container p-3">
    <h1 mat-dialog-title class="dark-text-color-table-bold dark-text-color-mobile-bold"><strong>{{args.titleRes | translate}}</strong></h1>
    <div mat-dialog-content class="d-body">
      <pre class="dark-text-color-table-small dark-text-color-mobile-small overflow-hidden">{{args.messageRes | translate: args.arguments}}</pre>
    </div>
    <div class="col-12 buttons-panel px-0" fxLayout="row"
         fxLayoutAlign="{{ !args.isCancelShow ? 'center center' : 'space-between center'}}">

      <button mat-flat-button fxFlex="45" class="b-secondary-table b-secondary-mobile  " color="secondary" (click)="closeDialog()">{{args.okButtonRes | translate}}</button>
      <button mat-flat-button fxFlex="45" *ngIf="args.isCancelShow" class="b-secondary-dialog-transparent-table b-secondary-dialog-transparent-mobile" (click)="cancelDialog()">{{args.cancelButtonRes | translate}}</button>

    </div>
  </div>
</div>

<div class="d-none d-lg-inline">
  <div class="text-center modal-container p-3">
    <h1 mat-dialog-title class="dark-text-color"><strong>{{args.titleRes | translate}}</strong></h1>
    <div mat-dialog-content class="d-body">
      <pre class="mat-body overflow-hidden">{{args.messageRes | translate: args.arguments}}</pre>
    </div>
    <div class="col-12 buttons-panel" fxLayout="row"
         fxLayoutAlign="{{ !args.isCancelShow ? 'center center' : 'space-between center'}}">

      <button mat-flat-button fxFlex="47" class="b-secondary-dialog" color="secondary" (click)="closeDialog()">{{args.okButtonRes | translate}}</button>
      <button mat-flat-button fxFlex="47" *ngIf="args.isCancelShow" class="b-secondary-dialog-transparent" (click)="cancelDialog()">{{args.cancelButtonRes | translate}}</button>

    </div>
  </div>
</div>
