import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class countryService {
  
  constructor() { }

  set(country:string) {
    localStorage.setItem('currentCountry', country); 
  }
  get() {
    var country = localStorage.getItem('currentCountry');
    if (country == null) {
      country = 'Россия';
    }

    return country;
  }


}
