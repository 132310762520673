import { HttpClient } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-not-desktop-dialog',
    templateUrl: './not-desktop-dialog.component.html',
    styleUrls: ['./not-desktop-dialog.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
/** NotDesktopDialog component*/
export class NotDesktopDialogComponent {
    /** NotDesktopDialog ctor */
    constructor(public translate: TranslateService,
        private http: HttpClient,
        public dialogRef: MatDialogRef<NotDesktopDialogComponent>,) {
    }

    closeDialog() {
        this.dialogRef.close();
    }
}