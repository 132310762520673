import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, HostListener, AfterViewInit, PLATFORM_ID, EventEmitter, Output, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AuthorizeService } from '../../../api-authorization/authorize.service';
import { ApiService } from '../../api.service';
import { Languages } from '../../app.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalObjectService } from '../../global-object.service';
import { SupportRequestModel } from '../../models/SupportRequestModel';
import { ConfirmArgs, ConfirmComponent } from '../../shared-module/dialogs/confirm/confirm.component';
import { Meta, Title } from '@angular/platform-browser';
import { countryService } from '../../services/countryService'; // service
import { MetaService } from '../../services/meta.service';


interface Subject {
    //value: string;
    viewValue: string;
}

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-additional-services',
  templateUrl: './additional-services.component.html',
  styleUrls: ['./additional-services.component.css']
})
export class AdditionalServicesComponent implements OnInit, AfterViewInit {
  get currentCountry(): string {
    return this.countryService.get();
  }
  options = ['Казахстан', 'Россия', 'Другая'];
  countrySwap: string = 'RU';
    config: any;
    fullpage_api: any;
    currentLang: string;
    firstName: string;
    lastName: string;
    public prefix = "+7";
    public phone_number: string;
    model: SupportRequestModel = {
        companyName: "",
        emailAddress: "",
        firstName: "",
        lastName: "",
        mobilePhone: "",
        subject: "",
        text: ""
    };
  selectedValue: string;
  isDesktopDevice = true;
    static TABLET_WIDTH = 992;
  isBrowser = false;

  @Output() sidenavClose = new EventEmitter();
  

    requestForm: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        companyName: new FormControl('', []),
        surname: new FormControl('', []),
        phoneNumber: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.email]),
        subject: new FormControl('', [Validators.required]),
        comment: new FormControl('', []),
    });

    subjects: string[] = [
      'Corporate_training_and',
      'Implementation_of_the_PRINCE2',
      'Implementation_of_an_Information_1',
      'Other'
    ];

  constructor(
    private countryService: countryService,
    private metaService: MetaService,
        public dialog: MatDialog,
        public translate: TranslateService,
        private router: Router,
        private readonly http: HttpClient,
        public readonly authService: AuthorizeService,
        private apiService: ApiService,
      public windowRef: GlobalObjectService,
     
      @Inject(PLATFORM_ID) private platformId: object,

      private deviceService: DeviceDetectorService,
      private scroller: ViewportScroller,
      private meta: Meta,
      private title: Title
    ) {
      this.countrySwap = localStorage.getItem('option');
      this.title.setTitle("Дополнительные услуги");
      this.meta.updateTag(
        { name: 'description', content: 'Управление проектами на основе метода PRINCE2. Видео курс и сертификация PRINCE2 Foundation на русском языке' });
      this.meta.updateTag(
        { name: 'keywords', content: 'Управление проектами, Стандарт PRINCE2, Методология PRINCE2, Управление проектами PRINCE2, Методы управления проектами, Стандарты управления проектами, Основы управления проектами, Проектный менеджмент, Портфолио менеджмент,  Управление проектной средой, Управление портфелем проектов' });
         
        this.isBrowser = isPlatformBrowser(this.platformId);
        var userLang = Languages[0].substring(0, 2);
        if (isPlatformBrowser(this.platformId)) {
            userLang = this.windowRef.getWindow().navigator.language;
        }

        const savedLang = localStorage.getItem("lang");
        if (savedLang) {
            this.currentLang = savedLang;
        }
        else {
            if (!translate.currentLang) {

                if (Languages.indexOf(userLang) > -1) {
                  this.currentLang = Languages[Languages.indexOf(userLang)];
                }
                else {
                    this.currentLang = Languages[0];
                }
            }
            else {
                this.currentLang = translate.currentLang;
            }
        }
        //this.currentLang = translate.currentLang;

        translate.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang;
            console.log(lang);
        });

        this.config = {
            licenseKey: 'YOUR LICENSE KEY HERE',
          anchors: ['additionalservice', 'corp', 'methodology', 'informationsystem', 'getaply'],
            menu: '#menu',
            afterResize: () => {
                //console.log("After resize");
            },
            afterLoad: (origin, destination, direction) => {
                //console.log(origin.index);
            }
        };

        this.authService.contact.subscribe(contact => {
            this.firstName = contact?.firstName;
            this.lastName = contact?.lastName;
        });
        //this.isDesktopDevice = this.deviceService.isDesktop();
        //console.log("isDesktopDevice", this.isDesktopDevice);
  }
    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.isBrowser) {
                this.isDesktopDevice = this.windowRef.getWindow().outerWidth >= AdditionalServicesComponent.TABLET_WIDTH;
            }
        });
                //this.isDesktopDevice = this.deviceService.isDesktop();
        //console.log("isDesktopDevice", this.isDesktopDevice);
  }


  onCountryChange() {

    this.countrySwap = localStorage.getItem('option');
    /*alert(this.countrySwap);*/
  }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.isBrowser) {
            this.isDesktopDevice = event.target.outerWidth >= AdditionalServicesComponent.TABLET_WIDTH;
        }
  }
    @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnInit(): void {

    const canonicalURL = 'https://prnc2.com/additional-services'; // Здесь задайте уникальный URL для страницы
    this.metaService.setCanonicalURL(canonicalURL);

    }

    getRef(servicesPage) {
        this.fullpage_api = servicesPage;
    }

    cabinet() {
        this.router.navigate([`cabinet`]);
    }

    logout() {
        this.authService.logout();
    }


    switchLang(lang: string) {
        this.translate.use(lang);
        localStorage.setItem("lang", lang);
    }

    login() {
        this.router.navigate([`authentication/login`]);
    }

    public checkFormError = (controlName: string, errorName: string) => {
        return this.requestForm.controls[controlName].hasError(errorName);
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  goDown1() {
    this.router.navigate(['/'], { fragment: 'courses' });
  }
  goDown2() {
    this.router.navigate(['/'], { fragment: 'education' });
  }
  goDown3() {
    this.router.navigate(['/'], { fragment: 'comments' });
  }
  goDown4() {
    this.router.navigate(['/'], { fragment: 'about' });
  }

    sendApplication() {
        if (!this.validate()) {
            return;
        }

        if (this.phone_number) {
            this.model.mobilePhone = this.phone_number.startsWith("+7") ? this.phone_number : "+7" + this.phone_number;
        }

        this.translate.get(this.selectedValue).subscribe((res: string) => {
            this.model.subject = res;
            this.apiService.createSupportRequest(this.model).pipe(first()).subscribe(data => {
                this.showDialog();
            });
        });

        console.log("sendApplication", this.model);
        return;



    }

    validate(): boolean {
        return this.requestForm.valid;
    }

    showDialog() {
        let args = new ConfirmArgs();
        args.isCancelShow = false;
        args.titleRes = "Approve";
        args.messageRes = "application_accepted";
        args.okButtonRes = "Close";

        const dialogRef = this.dialog.open(ConfirmComponent, {
          //width:  '30vw',
            data: args
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.event === "Close") {
                this.phone_number = "";
                this.selectedValue = null;
                this.model = {
                    companyName: "",
                    emailAddress: "",
                    firstName: "",
                    lastName: "",
                    mobilePhone: "",
                    subject: "",
                    text: ""
                };
            }
        });
  }


}
