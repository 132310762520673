<div class="row">
  <div class="col mb-4">
    <p class="course-text-700">
      Purpose of Practitioner Certification
    </p>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <p class="mb-4 ptext">
      The purpose of the Practitioner certification is to ensure that the candidate has sufficient knowledge of the application and <strong>adaptation of PRINCE2® </strong>to a specific situation. A successful Practitioner should, with proper guidance, be able to apply the PRINCE2® methodology in a real project.
    </p>
  </div>
</div>

<div class="row mc-7">
  <div class="col ">
    <p class="course-text-700 mb-4">Who is this course for?</p>
    <p class="mb-2 ptext">This certification is <strong>for project managers </strong>and applicants for this position. It is also intended for other key people involved in the project activities, including: members of the project management board (for example, senior responsible owners), team managers (for example, a product delivery manager), internal project control (for example, business change analysts), project support (for example, office staff associated with the project and program), and other managers and personnel.</p>
  </div>

</div>
<div class="row mt-3 ">
  <div class="col ">
    <p class="course-text-700 mb-4">Prerequisite</p>
    <p class="mb-4 ptext">
      To take the PRINCE2® Practitioner exam, you need a valid certificate from the <a href="#" class="underline">list.</a> However, we recommend that you pre-qualify for the PRINCE2® Foundation to become familiar with the basics of PRINCE2® and be better prepared for the Practitioner level.
    </p>
  </div>

</div>
<div class="row">
  <div class="col">
    <p class="course-text-700 mb-3">After certification</p>
    <p class="mb-2 ptext">
      Candidates who successfully pass the PRINCE2® Practitioner Exam must be able to apply and effectively adapt PRINCE2 to the project in the environment of the PRINCE2® supporting organization.
    </p>
    <p class="ptext">Candidates must:</p>
    <ul class="" style="padding-left: 20px;margin-bottom: 30px;">
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Understand the relationships between roles, management products, principles, themes, techniques and processes
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Be able to apply these principles, themes and processes to the project
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Be able to create and evaluate management products
      </li>
    </ul>
  </div>
</div>
<div class="" *ngIf="isAuthorized === false">

  <div class="bg" style="margin-top: 6.85vh;margin-bottom: 25px;
">
    <div class="row">
      <div class="col">
        <p class="pwite" style="padding-top:9px;padding-left: 10px;
">The PRINCE2® Practitioner at «ComProducts» is:</p>
      </div>
    </div>
  </div>
  <ul class="" style="padding-left: 20px;margin-bottom: 30px;">
    <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
      Format – <strong>online, at a convenient time;</strong>
    </li>
    <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
      <strong>Access to the course - 2 months;</strong>
    </li>
    <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
      Duration of training - <strong>30 hours;</strong>
    </li>
    <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
      The course contains – <strong>17 sections;</strong>
    </li>
    <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
      In each section – <strong>video, presentation, practical tasks, examples;</strong>
    </li>
    <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
      Cost per user – <strong>400 000 tenge.</strong>
    </li>
  </ul>
  <div class="row">
    <div class="col">
      <p class="course-text-700 mb-3">PRINCE2® Practitioner certification costs include:</p>
      <ul class="" style="padding-left: 20px;margin-bottom: 30px;">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          Access to the course and all training materials for <strong>2 months, with the possibility of freezing for 30 days</strong>
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          <strong>Voucher</strong> for the PRINCE2® Practitioner exam with online proctor, valid <strong>for 1 year;</strong>
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          Electronic <strong>certificate</strong> from <strong>AXELOS and PeopleCert corporations.</strong>
        </li>
      </ul>
    </div>
  </div>
</div>

