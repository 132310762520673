<body>
  <!--app-nav-menu></!--app-nav-menu-->
  <div class="main-loader" *ngIf="loadingRouteConfig">
    <mat-progress-bar [mode]="'indeterminate'">
    </mat-progress-bar>

  </div>
  <div *ngIf="country != null" class="main-container " [ngClass]="{'router-wrapper': isBrowser === true, 'router-wrapper-server': isBrowser === false }"
       [@mainTransition]="getState(o)">
    <router-outlet #o="outlet"></router-outlet>
  </div>
  <div>
    <app-modal-country-select [showModal]="showModal"></app-modal-country-select>
  </div>
</body>
