<div id="notfound">
    <div class="notfound">
        <div>
            <div class="notfound-404">
                <h1>!</h1>
            </div>
            <h2>Ошибка<br>404</h2>
        </div>
        <p>Страница, которую вы ищите не существует или временно недоступна. <a href="#">Вернуться на главную</a></p>
    </div>
</div>
