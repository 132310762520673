import { trigger, transition, useAnimation } from "@angular/animations";
import { Component, Inject, OnInit, AfterViewInit, ViewChild, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivationEnd, ActivationStart, ChildActivationEnd, ChildActivationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';
import { fromRightEasing, fromLeftEasing, moveFromRightFade, moveFromLeftFade, fromBottomEasing } from "ngx-router-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from "@angular/material/dialog";
import { NotDesktopDialogComponent } from "./shared-module/dialogs/not-desktop-dialog/not-desktop-dialog.component";
import { GlobalObjectService } from "./global-object.service";
import { isPlatformBrowser } from "@angular/common";
import { IpInfo } from "./ipinfo-module/ipinfo.service";
import { deepCopy } from "./utils/deep-copy";
import { ModalCountrySelectComponent } from './modal-country-select/modal-country-select.component';




@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    animations: [
        trigger('mainTransition', [
            transition('main => authentication', useAnimation(fromRightEasing)),
            transition('authentication => register', useAnimation(fromRightEasing)),
            transition('home => course', useAnimation(fromRightEasing)),
            transition('main => course', useAnimation(moveFromRightFade)),
            transition('course => main', useAnimation(fromLeftEasing)),
            transition('main => privacy', useAnimation(fromBottomEasing)),
            //transition('authentication => main', useAnimation(fromLeftEasing)),
        ])
    ]
})

export class AppComponent implements OnInit, AfterViewInit{
  showModal: boolean;
    loadingRouteConfig: boolean;
    isDesktopDevice = true;
    isBrowser = false;
  country: string = null;
  options = ['KZ', 'RU'];
  countryResult = 'RU';
  

    constructor(
        public translate: TranslateService,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private apiService: ApiService,
        private deviceService: DeviceDetectorService,
        public dialog: MatDialog,
      public windowRef: GlobalObjectService,
      public modalCountrySelectComponent: ModalCountrySelectComponent,
      @Inject(PLATFORM_ID) private platformId: object,
      readonly ipinfo$: IpInfo,
      
    ) {
      
      const visited = localStorage.getItem('visited');
      if (!visited) {
        this.showModal = true;
        localStorage.setItem('visited', 'true');
      
    }
      this.isBrowser = isPlatformBrowser(this.platformId);

      //translate.addLangs(Languages);

      if (isPlatformBrowser(this.platformId)) {
        this.country = localStorage.getItem("country");
        console.log("country: ", this.country);
        console.log("navigator lang: ", this.windowRef.getWindow().navigator.language);

        if (this.country == null) {
          this.ipinfo$.checkIP();
          this.ipinfo$.subscribe(ipResult => {
            localStorage.setItem("country", ipResult.country);
            console.log("ipResult country: ", ipResult.country);

            this.country = ipResult.country;
            this.setLanguage(this.country);
          });

          ipinfo$.error$.subscribe(error => {
            this.country = this.windowRef.getWindow().navigator.language.substring(0, 2);
            this.setLanguage(this.windowRef.getWindow().navigator.language.substring(0, 2));
          });
        }
        else {
          this.setLanguage(this.country);
        }




        /* var userLang = Languages[0].substring(0, 2);
 
         const savedLang = localStorage.getItem("lang");
         if (savedLang) {
           translate.setDefaultLang(savedLang);
         }
         else {
           if (Languages.indexOf(userLang) > -1) {
             translate.setDefaultLang(userLang);
           }
           else {
             translate.setDefaultLang(Languages[0]);
           }
         }
       }
       else {
         var userLang = Languages[0].substring(0, 2);
         if (isPlatformBrowser(this.platformId)) {
           userLang = this.windowRef.getWindow().navigator.language;
         }
 
         const savedLang = localStorage.getItem("lang");
         if (savedLang) {
           translate.setDefaultLang(savedLang);
         }
         else {
           if (Languages.indexOf(userLang) > -1) {
             translate.setDefaultLang(userLang);
           }
           else {
             translate.setDefaultLang(Languages[0]);
           }
         }
       */
      }
      else {
          translate.setDefaultLang(Languages[0]);
      }

        /*const firebaseConfig = {
            apiKey: 'AIzaSyB6O-vUDIJw3LOyGl_eZL4zueJpV2saP5g',
            authDomain: 'localhost',
            projectId: 'remoteeducation',
            storageBucket: 'remoteeducation.appspot.com',
            messagingSenderId: '590966757827'
        };

        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }
        firebase.auth().useDeviceLanguage();

        translate.addLangs(Languages);
        if (Languages.indexOf(firebase.auth().languageCode) > -1) {
            translate.setDefaultLang(firebase.auth().languageCode);
        }
        else {
            translate.setDefaultLang(Languages[0]);
        }*/

        /*this.apiService.createCustomer(customer).subscribe((res) => {
        });*/
        

        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                this.loadingRouteConfig = true;
            } else if (event instanceof RouteConfigLoadEnd) {
                this.loadingRouteConfig = false;
            }
        });

        this.isDesktopDevice = this.deviceService.isDesktop();
        

        
  }
  
  


  setLanguage(country: string) {
    console.log("setLanguage:", country)
      var userLang: string;
      if (country == "KZ") {
        Languages[0] = 'ru-KZ';
        Languages[1] = 'en-KZ';
        console.log("setLanguage:", "country = " + country);
      }
    //this.translate.addLangs(Languages);
    //this.translate.langs = [];
    //this.translate.langs =
    this.translate.langs = deepCopy(Languages);


      userLang = Languages[0].substring(0, 2);

      var savedLang = localStorage.getItem("lang");
      if (savedLang?.startsWith("kz") === true) {
        localStorage.setItem("lang", "ru-KZ");
        savedLang = "ru-KZ";
      }
      if (savedLang) {
        this.translate.setDefaultLang(savedLang);
      }
      else {
        if (Languages.indexOf(userLang) > -1) {
          this.translate.setDefaultLang(Languages[Languages.indexOf(userLang)]);
        }
        else {
          this.translate.setDefaultLang(Languages[0]);
        }
      }
  }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        setTimeout(() => {
            console.log("isDesktopDevice", this.isDesktopDevice);
            /*if (!this.isDesktopDevice) {
               *if (localStorage.getItem("is_not_desktop_showed") === "yes") {
                    return;
                }

                
                const dialogRef = this.dialog.open(NotDesktopDialogComponent, {
                    width: '72vw',
                    disableClose: true
                });

                dialogRef.afterClosed().subscribe(result => {
                    localStorage.setItem("is_not_desktop_showed", "yes");
                });
            }*/
        }, 200);



      const saveCountryNav = localStorage.getItem("options");
      if (saveCountryNav) {
        this.countryResult = saveCountryNav;
      }

      var currentOption = localStorage.getItem("option");
      /*alert(currentOption);*/
      if (currentOption) {
        this.countryResult = currentOption;

      }
  }

  


    getState(outlet: RouterOutlet) {
        return outlet.activatedRouteData.state;
    }
}

export const Languages = ['ru-RU', 'en-US'];
