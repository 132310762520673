import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, Inject, Injectable, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { throwError } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { ApiService } from '../api.service';
import { Languages } from '../app.component';
import { GlobalObjectService } from '../global-object.service';
import { CourseFeedBackModel } from '../models/CourseFeedBackModel';
import { CoursesToolbarComponent } from '../courses/courses-toolbar/courses-toolbar.component';
import { countryService } from '../services/countryService'; // service


export interface PhotosApi {
    albumId?: number;
    id?: number;
    title?: string;
    url?: string;
    thumbnailUrl?: string;
}
@Injectable({
  providedIn: 'root'
})
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, AfterViewInit{
  shouldDisplayModal: boolean = true;
  options = ['Казахстан', 'Россия','Другая'];

  countrySwap: string;

  get currentCountry(): string {
   return this.countryService.get();
  }
    config: any;
    fullpage_api: any;
    currentLang: string;
    currentRate = 4.1;
    firstName: string;
    lastName: string;
    isLoading = true;
    isFetchingComments = false;
  static TABLET_WIDTH = 1025;
  isBrowser = false;
  desktopMenu = false;
    limit = 10;
    commentPageIndex = 1;
    comments = new Array<CourseFeedBackModel>();
    isResized = false;
    private fragment: string;

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 600,
        center: true,
        autoplay: false,
        autoHeight: false,
        autoWidth: true,
        items: 1.6,
        margin: 0,
        navText: ["<i [id]='testL' class='fa fa-angle-left' ></i>", "<i [id]='testR' class='fa fa-angle-right'></i >"],
        nav: true,
    }

  customOptionsMobile: OwlOptions = {
      loop: true,
    margin:0,
    mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
    dots: false,
    navSpeed: 600,
    center: true,
      autoplay: false,
      autoHeight: false,
      autoWidth: true,
    navText: ["<i [id]='testL' class='fa fa-angle-left' ></i>", "<i [id]='testR' class='fa fa-angle-right'></i >"],
      nav: true,
      items: 1
  }
  customOptionsMobileVideo: OwlOptions = {
    loop: true,
    margin: 0,
    mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
    dots: false,
    navSpeed: 600,
    center: true,
    autoplay: false,
      autoHeight: false,
      autoWidth: true,
    items: 1,

    navText: ["<i [id]='testL' class='fa fa-angleVid-left' ></i>", "<i [id]='testR' class='fa fa-angleVid-right'></i >"],

    nav: true
  }
 
  constructor(
        private countryService: countryService,
        public translate: TranslateService,
        private router: Router,
        private readonly http: HttpClient,
        public readonly authService: AuthorizeService,
        private apiService: ApiService,
        public windowRef: GlobalObjectService,
        @Inject(PLATFORM_ID) private platformId: object,
        private deviceService: DeviceDetectorService,
      private route: ActivatedRoute,
      public сoursesToolbarComponent: CoursesToolbarComponent,
      
    ) {
      
        var userLang = Languages[0].substring(0, 2);
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (this.isBrowser) {
            userLang = this.windowRef.getWindow().navigator.language;
        }

        const savedLang = localStorage.getItem("lang");
        if (savedLang) {
            this.currentLang = savedLang;
        }
        else {
            if (!translate.currentLang) {

                if (Languages.indexOf(userLang) > -1) {
                  this.currentLang = Languages[Languages.indexOf(userLang)];
                }
                else {
                    this.currentLang = Languages[0];
                }
            }
            else {
                this.currentLang = translate.currentLang;
            }
        }
        //this.currentLang = translate.currentLang;

        translate.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang;
            console.log(lang);
        });

        this.config = {
            licenseKey: 'YOUR LICENSE KEY HERE',
            anchors: ['main', 'courses', 'education', 'comments', 'about'],
            menu: '#menu',
            afterResize: () => {
            },
            afterLoad: (origin, destination, direction) => {
                //console.log(origin.index);
            }
        };

        this.authService.contact.subscribe(contact => {
            this.firstName = contact?.firstName;
            this.lastName = contact?.lastName;
        });

      //this.desktopMenu = this.deviceService.isDesktop();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (!this.isResized) {
            this.isResized = true;
            console.log("this.isResized", this.isResized);
            setTimeout(() => {
                this.isResized = false;
                console.log("this.isResized timeout", this.isResized);
            },500);
        }
            /*this.customOptions = { ...this.customOptions };
            this.customOptionsMobile = { ...this.customOptionsMobile };
            this.customOptionsMobileVideo = { ...this.customOptionsMobileVideo };*/

      this.desktopMenu = event.target.outerWidth >= HomeComponent.TABLET_WIDTH;

      console.log(this.desktopMenu)
      
    }

    getRef(fullPageRef) {
        this.fullpage_api = fullPageRef;
    }

    switchLang(lang: string) {
        this.translate.use(lang);
        localStorage.setItem("lang", lang);
    }

    ngOnInit() {
        this.fetch();
      this.route.fragment.subscribe(fragment => { this.fragment = fragment; });


      /*this.localcountryTest = this.сoursesToolbarComponent.countryTest;*/

    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.isLoading = false;
            if (this.isBrowser) {
                this.desktopMenu = this.windowRef.getWindow().outerWidth >= HomeComponent.TABLET_WIDTH;
                try {
                    if (this.fragment && !this.desktopMenu) {
                        setTimeout(() => {
                            document.querySelector('#' + this.fragment).scrollIntoView();
                        }, 300);
                    }
                } catch (e) { }
            }
        });

      
    }
   
    getPassedData(data: any) {
       // this.activeSlides = data;
        const count = this.comments.length;
        if (!this.isFetchingComments && (count === 0 || data.startPosition > count - 2))
        {
            console.log(data);
            this.fetch();
        }
    }

    fetch() {
        this.isFetchingComments = true;
        this.apiService.getAllFeedbackList(this.commentPageIndex, this.limit).pipe(first()).subscribe(data => {
            console.log(data);
            if (data) {
                data.forEach(comment => {
                    this.comments.push(comment);
                });
                this.commentPageIndex++;
            }
            this.isFetchingComments = false;
        },
            e => {
                console.log(e);
                this.isFetchingComments = false;
        });
  }



  

    login() {
        this.router.navigate([`authentication/login`]);
       //router  /authentication/login
    }

    cabinet() {
        this.router.navigate([`cabinet`]);
    }

    logout() {
        this.authService.logout();
    }

    buyCourse(course: string) {
        this.router.navigate(['register', { course: course }]);
    }

    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
        event.preventDefault();
  }

  public CountryResult() {
    if (this.сoursesToolbarComponent.countryTest) {
      this.сoursesToolbarComponent.countryTest = false;
      console.log(this.CountryResult);
    }
    else if (this.сoursesToolbarComponent.countryTest){
      this.сoursesToolbarComponent.countryTest = true;
      console.log(this.CountryResult);
    }
    /*return this.сoursesToolbarComponent.countryTest;*/
    
  }

  
    //else if (this.сoursesToolbarComponent.condition) {
    //  this.сoursesToolbarComponent.countryTest = true;
    //  console.log(this.CountryResult);
    //}
    /*return this.сoursesToolbarComponent.countryTest;*/

}
