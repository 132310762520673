import { Pipe, PipeTransform } from '@angular/core';
import { ContactModel } from '../../models/ContactModel';

@Pipe({ name: 'langMenu' })
export class LangMenuPipe implements PipeTransform {
    transform(value: string) {
        if (!value) {
            return null;
        }

        switch (value) {
            case "ru-RU":
              return "cabinet_lang_ru";
            case "en-US":
              return "cabinet_lang_en";
            case "ru-KZ":
              return "cabinet_lang_ru";
            case "en-KZ":
              return "cabinet_lang_en";
            default:
                return value;
        }
    }
}
