import { Pipe, PipeTransform } from '@angular/core';
import { ContactModel } from '../../models/ContactModel';

@Pipe({ name: 'lastname' })
export class LastnamePipe implements PipeTransform {
    transform(model: any) {
        console.log("LastnamePipe");
        console.log(model);
        if (!model || model === undefined) {
            return null;
        }
        return model?.contact?.lastName;
    }
}