<div class="row">
    <div class="col mb-4">
      <p class="course-text-700">
        Цель сертификации Practitioner
      </p>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <p class="mb-4 ptext">
        Цель сертификации Practitioner –– убедиться, что кандидат имеет достаточные знания о применении и <strong>адаптации PRINCE2®</strong> в конкретной ситуации. Кандидат, успешно сдавший уровень Practitioner должен, при надлежащем руководстве, уметь применять методологию PRINCE2® в реальном проекте.
      </p>
    </div>
  </div>

  <div class="row mc-7">
    <div class="col ">
      <p class="course-text-700 mb-4">Целевая аудитория </p>
      <p class="mb-2 ptext">Эта сертификация <strong>предназначена для менеджеров</strong> проекта и претендентов на эту должность. Она также предназначена для других ключевых сотрудников, вовлеченных в проектную деятельность, в том числе: членов управляющего совета проекта (например, старшие ответственные владельцы), менеджеров команды (например, менеджер по поставке продукта), внутреннего контроля проекта (например, аналитики изменений в бизнесе), поддержки проекта (например, офисный персонал, связанный с проектом и программой), а также других менеджеров и персонала.</p>
    </div>

  </div>
  <div class="row mt-3 ">
    <div class="col ">
      <p class="course-text-700 mb-4">Требования</p>
      <p class="mb-4 ptext">
        Для сдачи экзамена PRINCE2® Practitioner Вам необходимо наличие действительного сертификата из <a href="#" class="underline">списка.</a> Однако, мы рекомендуем предварительно пройти сертификацию PRINCE2® Foundation, чтобы познакомиться с основами PRINCE2® и быть лучше подготовленным к уровню Practitioner.
      </p>
    </div>

  </div>
  <div class="row">
    <div class="col">
      <p class="course-text-700 mb-3">После сертификации</p>
      <p class="mb-2 ptext">
        Кандидаты, успешно сдавшие экзамен PRINCE2® Practitioner должны уметь применять и эффективно адаптировать PRINCE2 к проекту в среде организации, поддерживающей PRINCE2®.
      </p>
      <p class="ptext">А именно, кандидаты должны:</p>
      <ul class="" style="padding-left: 20px;margin-bottom: 30px;">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          Понимать отношения между ролями, управленческими продуктами, принципами, темами,техниками и процессами
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          Уметь применять эти принципы, темы и процессы к проекту
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          Уметь создавать и оценивать управленческие продукты
        </li>
      </ul>
    </div>
  </div>
  <div class="" *ngIf="isAuthorized === false">

    <div class="bg" style="margin-top: 6.85vh;margin-bottom: 25px;
">
      <div class="row">
        <div class="col">
          <p class="pwite" style="padding-top:9px;padding-left: 10px;
">PRINCE2® Practitioner в «Com Products» это:</p>
        </div>
      </div>
    </div>
    <ul class="" style="padding-left: 20px;margin-bottom: 30px;">
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Формат – <strong>онлайн, в удобное время;</strong>
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        <strong>Доступ к курсу – 2 месяца;</strong>
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Продолжительность обучения – <strong>30 часов;</strong>
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Курс содержит – <strong>17 разделов;</strong>
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        В каждом разделе – <strong>видео, презентация, практические задания, примеры;</strong>
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Стоимость на одного пользователя – <strong>400 000 тенге.</strong>
      </li>
    </ul>
    <div class="row">
      <div class="col">
        <p class="course-text-700 mb-3">В стоимость сертификации PRINCE2® Practitioner входит:</p>
        <ul class="" style="padding-left: 20px;margin-bottom: 30px;">
          <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
            Доступ к курсу и всем обучающим материалам на <strong>2 месяца, с возможностью заморозки на 30  дней</strong>
          </li>
          <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
            <strong>Ваучер</strong> для сдачи экзамена PRINCE2® Foundation с проктором онлайн, сроком <strong>на 1 год;</strong>
          </li>
          <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
            Электронный <strong>сертификат</strong> от корпораций <strong>AXELOS и PeopleCert.</strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
