import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-error-dialog',
    templateUrl: './errordialog.component.html',
    styleUrls: ['./errordialog.component.css']
})
export class ErrorDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}