import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Injectable, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ApiService } from '../../../app/api.service';
import { ContactModel } from '../../../app/models/ContactModel';
import { SalesOrderModel } from '../../../app/models/SalesOrderModel';
import { AuthorizeService } from '../../authorize.service';
import { countryService } from '../../../app/services/countryService'; // service

@Injectable({
  providedIn: 'root'
})

@Component({
    selector: 'app-fail',
    templateUrl: './fail.component.html',
    styleUrls: ['./fail.component.css'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
    }],
    encapsulation: ViewEncapsulation.Emulated
})
/** fail component*/
export class FailComponent {

  get currentCountry(): string {
    return this.countryService.get();
  }
    /** fail ctor */
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    private id: string;
    private orderId: string;
    paymentResult: SalesOrderModel;

    contact: ContactModel;

    @ViewChild('stepper') stepper: MatStepper;

    constructor(private router: Router,
        private apiService: ApiService,
        private route: ActivatedRoute,
      private authService: AuthorizeService,
      private countryService: countryService    ) {
    }

    ngOnInit() {
        this.route.params.pipe(first()).subscribe(params => {
            this.id = params['id'];
            this.processBankPayment();
        });

        this.route.queryParams.pipe(first()).subscribe(params => {
            this.orderId = params['orderId'];
        });

    }

    processBankPayment() {
        this.apiService.processBankPayment(this.id).pipe(first()).subscribe(result => {
            this.paymentResult = result;
            console.log("processBankPayment", result);
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.stepper.selectedIndex = 2;
            this.stepper.linear = true;

            this.authService.contact
                .subscribe((data => {
                    if (!data) {
                        return;
                    }

                    this.contact = data;
                }));
        }, 1000);
    }

    goToCabinet() {
        this.router.navigate([`/cabinet/main`]);
    }
}
