import { Pipe, PipeTransform } from '@angular/core';



@Pipe({ name: 'shortLang' })
export class ShortLang implements PipeTransform {
    transform(value: string) {
        if (!value) {
            return null;
        }

        switch (value) {
            case "ru-RU":
              return "RU";
            case "en-US":
              return "EN";
            case "ru-KZ":
              return "RU";
            case "en-KZ":
              return "EN";
            default:
                return value;
        }
    }
}
