import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'name' })
export class NamePipe implements PipeTransform {
    transform(model: NamedObject) {
        if (!model || model === undefined) {
            return null;
        }
        return model?.name;
    }
}

export interface NamedObject {
    name: string;
}
