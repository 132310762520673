import { Pipe, PipeTransform } from '@angular/core';



@Pipe({ name: 'short' })
export class ShortString implements PipeTransform {
    transform(value: string) {
        if (!value) {
            return null;
        }

        if (value.length > 20) {
            return value.substring(0, 20) + "...";
        }
        else {
            return value;
        }
    }
}