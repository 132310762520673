import { BrowserModule, TransferState } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent, Languages } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
//import { NgxDropzoneModule } from 'ngx-dropzone/lib/ngx-dropzone.module';
//import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb/lib/ng-dynamic-breadcrumb.module';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRouters } from './app.routes';
import { ErrorDialogService } from './errordialog.service';
import { ErrorDialogComponent } from './errordialog/errordialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { SharedModule } from './shared-module/shared-module.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatSidenavModule } from '@angular/material/sidenav';
//import { VerifyPhoneComponent } from './verify-phone/verify-phone.component';
import { LayoutModule } from '@angular/cdk/layout';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { MatMenuModule } from '@angular/material/menu';
import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatCardModule } from '@angular/material/card';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizeInterceptor } from '../api-authorization/authorize.interceptor';
import { ApiAuthorizationModule } from '../api-authorization/api-authorization.module';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { CountdownModule } from 'ngx-countdown';
import { FoundationRuComponent } from './courses/foundation-ru/foundation-ru.component';
import { FoundationEnComponent } from './courses/foundation-en/foundation-en.component';
import { PractitionerEnComponent } from './courses/practitioner-en/practitioner-en.component';
import { PractitionerRuComponent } from './courses/practitioner-ru/practitioner-ru.component';
import { CoursesToolbarComponent } from './courses/courses-toolbar/courses-toolbar.component';
import { CoursesFooterComponent } from './courses/courses-footer/courses-footer.component';
import { MatProgressBarModule } from "@angular/material/progress-bar"
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeEN from '@angular/common/locales/en';
import { SuccessComponent } from '../api-authorization/payment-result/success/success.component';
import { FailComponent } from '../api-authorization/payment-result/fail/fail.component';
import { MatStepperModule } from '@angular/material/stepper';
import { AdditionalServicesComponent } from './components/additional-services/additional-services.component';
import { MatSelectModule } from '@angular/material/select';
import { OfertaComponent } from './support/oferta/oferta.component';
import { SupportComponent } from './support/support.component';
import { PrivacyComponent } from './support/privacy/privacy.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalObjectService } from './global-object.service';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { translateBrowserLoaderFactory } from './translate-browser.loader';
import { CoursesNavbarComponent } from './courses-navbar/courses-navbar.component';
import { IpInfoModule } from './ipinfo-module/ipinfo.module';
import { OfertaKZComponent } from './support/oferta-kz/oferta-kz.component';
import { PrivacyKzComponent } from './support/privacy-kz/privacy-kz.component'; 
import { PayboxComponent } from './support/paybox/paybox.component';
import { ModalCountrySelectComponent } from './modal-country-select/modal-country-select.component';
import { countryService } from './services/countryService';
import { MetaService } from './services/meta.service';



export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export const firebaseConfig = {
    apiKey: 'AIzaSyB6O-vUDIJw3LOyGl_eZL4zueJpV2saP5g',
    authDomain: 'remoteeducation.firebaseapp.com',
    projectId: 'remoteeducation',
    storageBucket: 'remoteeducation.appspot.com',
    messagingSenderId: '590966757827',
    databaseURL: "https://remoteeducation.firebaseio.com",
    appId: "1:590966757827:web:821f432cae432ca8574576",
    measurementId: "G-9CTPWYC7KH"
};

registerLocaleData(localeRu, Languages[0]);
registerLocaleData(localeEN, Languages[1]);


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ErrorDialogComponent,
    FoundationRuComponent,
    FoundationEnComponent,
    PractitionerEnComponent,
    PractitionerRuComponent,
    CoursesToolbarComponent,
        CoursesFooterComponent,
        SuccessComponent,
        FailComponent,
        AdditionalServicesComponent,
        SupportComponent,
        OfertaComponent,
        PrivacyComponent,
        CoursesNavbarComponent,
        OfertaKZComponent,
        PrivacyKzComponent,
    ModalCountrySelectComponent,
    PayboxComponent
          ],
  imports: [
      BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
      HttpClientModule,
      FormsModule,
      AngularFireModule.initializeApp(firebaseConfig),
      AngularFireAnalyticsModule,
      AngularFireAuthModule,
      MatDialogModule,
      MatIconModule,
      MatProgressSpinnerModule,
      ReactiveFormsModule,
      MatProgressBarModule,
      ApiAuthorizationModule,
      BrowserAnimationsModule,
      FlexLayoutModule,
      MatToolbarModule,
      MatListModule,
      SharedModule,
      MatFormFieldModule,
      MatInputModule,
      TextFieldModule,
      MatButtonModule,
      MatSidenavModule,
      //NgxDropzoneModule,
      NgDynamicBreadcrumbModule,
      AngularFullpageModule,
      MatMenuModule,
      CarouselModule,
      MatCardModule,
      VgCoreModule,
      VgControlsModule,
      VgOverlayPlayModule,
      VgBufferingModule,
      CountdownModule,
      MatSelectModule,
    MatStepperModule,
      TransferHttpCacheModule,
      NgxMaskModule.forRoot(),
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: translateBrowserLoaderFactory,
              deps: [HttpClient, TransferState]
          }
      }),
      AppRouters,
      LayoutModule,
    NgbModule,
    IpInfoModule
  ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
        ErrorDialogService,
        DeviceDetectorService,
        GlobalObjectService,
        TranslateService,
      TranslateStore,
      countryService,
      MetaService,
      ModalCountrySelectComponent,
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: () => localStorage.getItem("lang") ? localStorage.getItem("lang") : Languages[1]
        },
      ScreenTrackingService,
    ],
  entryComponents: [ErrorDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
