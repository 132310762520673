import { Component, HostListener, Inject, Injectable, OnInit, PLATFORM_ID } from '@angular/core';
import { Languages } from '../../app.component';
import { TranslateService } from '@ngx-translate/core';
import { GlobalObjectService } from '../../global-object.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { countryService } from '../../services/countryService'; // service


@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-courses-footer',
  templateUrl: './courses-footer.component.html',
  styleUrls: ['./courses-footer.component.css']
})
export class CoursesFooterComponent implements OnInit {
  get currentCountry(): string {
    return this.countryService.get();
  }
  options = ['Казахстан', 'Россия', 'Другая'];
  static TABLET_WIDTH = 1025;
  isBrowser = false;
  desktopMenu: boolean = true;
  countrySwap: string = 'RU';
  constructor(public windowRef: GlobalObjectService,
    private countryService: countryService,
    private scroller: ViewportScroller,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private deviceService: DeviceDetectorService,) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.countrySwap = localStorage.getItem('option');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.desktopMenu = event.target.outerWidth >= CoursesFooterComponent.TABLET_WIDTH;
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.isBrowser) {
        this.desktopMenu = this.windowRef.getWindow().outerWidth >= CoursesFooterComponent.TABLET_WIDTH;
      }
    });
  }
  goDown1() {
    this.router.navigate(['/'], { fragment: 'courses' });
  }
  goDown2() {
    this.router.navigate(['/'], { fragment: 'education' });
  }
  goDown3() {
    this.router.navigate(['/'], { fragment: 'comments' });
  }
  goDown4() {
    this.router.navigate(['/'], { fragment: 'about' });
  }
  ngOnInit(): void {
  }

  onCountryChange() {

    this.countrySwap = localStorage.getItem('option');
    /*alert(this.countrySwap);*/
  }

}
