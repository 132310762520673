import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, HostListener, PLATFORM_ID, HostBinding, EventEmitter, Output, ViewEncapsulation, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizeService } from '../../../api-authorization/authorize.service';
import { Languages } from '../../app.component';
//import { animateText, onSideNavChange } from '../animations/animations.component';
//import { SidenavService } from '../sidenav.service';
import { GlobalObjectService } from '../../global-object.service';
import { countryService } from '../../services/countryService'; // service


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-courses-toolbar',
  templateUrl: './courses-toolbar.component.html',
  styleUrls: ['./courses-toolbar.component.css'],
  encapsulation: ViewEncapsulation.Emulated
  //animations: [onSideNavChange, animateText],
})


export class CoursesToolbarComponent implements OnInit {
    currentLang: string;
    firstName: string;
    lastName: string;
  fragment: string;
  options = ['Казахстан', 'Россия', 'Другая'];
  countryResult = 'RU';
  countryTest: boolean = true;
  condition: boolean = true;

  get currentCountry(): string {
    return this.countryService.get();
  }

  
    //public sideNavState = true;
  isFixedNavbar;
  langs: Array<string>;
  @HostBinding('class.navbar-opened') navbarOpened = false;
  @Output() public sidenavToggle = new EventEmitter();
  @Output() courses = new EventEmitter();
  @Output() education = new EventEmitter();
  @Output() feedback = new EventEmitter();
  @Output() aboutUs = new EventEmitter();
  @Output() public onCountryChange = new EventEmitter();
  

  constructor(
    private countryService: countryService,
    public translate: TranslateService,
    private router: Router,
      private readonly http: HttpClient,
      public readonly authService: AuthorizeService,
      public windowRef: GlobalObjectService,
      @Inject(PLATFORM_ID) private platformId: object,

  ) {
    this.langs = Languages;
      var userLang = Languages[0].substring(0, 2);
      if (isPlatformBrowser(this.platformId)) {
        userLang = this.windowRef.getWindow().navigator.language;

    }





    const savedLang = localStorage.getItem("lang");
    if (savedLang) {
      this.currentLang = savedLang;
    }
    else {
      if (!translate.currentLang) {

        if (Languages.indexOf(userLang) > -1) {
          this.currentLang = Languages[Languages.indexOf(userLang)];
        }
        else {
          this.currentLang = Languages[0];
        }
      }
      else {
        this.currentLang = translate.currentLang;
      }
    }



    //this.currentLang = translate.currentLang;

    translate.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang;
    });

      this.authService.contact.subscribe(contact => {
          console.log(contact?.firstName);
          this.firstName = contact?.firstName;
          this.lastName = contact?.lastName;
      });
  }

  

  switchLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
  }

  
  
  onClick(option: string) {
    this.countryService.set(option);

 
  }

  
  

  login() {
    this.router.navigate([`authentication/login`]);
  }

  onSidenavToggle() {
    console.log("Toggle");
    this.sidenavToggle.emit();
  
   
  };


  //onSinenavToggle() {
  //  this.sideNavState = !this.sideNavState

  //  setTimeout(() => {
  //    this.linkText = this.sideNavState;
  //  }, 200)
  //  this._sidenavService.sideNavState$.next(this.sideNavState)
  //}


  ngOnInit() {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset > 10) {
      this.isFixedNavbar = true;
    } else {
      this.isFixedNavbar = false;
    }
  }
  toggleNavbar() {
    this.navbarOpened = !this.navbarOpened;

  }

  //public onToggleSidenav = () => {
  //  this.sidenavToggle.emit();
  //}

    cabinet() {
        this.router.navigate([`cabinet`]);
    }

    logout() {
        this.authService.logout();
  }

  public slideTo(section: string) {
    console.log(section);
    switch (section) {
      case "courses": {
        this.courses.emit();
        break;
      }
      case "education": {
        this.education.emit();
        break;
      }
      case "feedback": {
        this.feedback.emit();
        break;
      }
      case "aboutUs": {
        this.aboutUs.emit();
        break;
      }
      default: {
        break;
      }
    }
  }

}
