import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../../../../api-authorization/authorize.service';

@Component({
  selector: 'shared-practitioner-en',
  templateUrl: './practitioner-en-shared.component.html',
  styleUrls: ['./practitioner-en-shared.component.css']
})
export class PractitionerEnSharedComponent implements OnInit {

  isAuthorized?: boolean = null;

  constructor(public authService: AuthorizeService) { }

  ngOnInit(): void {
    this.authService.afAuth.authState.subscribe(user => {
      this.isAuthorized = user !== null;
    });
  }

}
