import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../../../../api-authorization/authorize.service';

@Component({
  selector: 'shared-foundation-en',
  templateUrl: './foundation-en-shared.component.html',
  styleUrls: ['./foundation-en-shared.component.css']
})
export class FoundationEnSharedComponent implements OnInit {

  isAuthorized?: boolean = null;

  constructor(public authService: AuthorizeService) { }

  ngOnInit(): void {
    this.authService.afAuth.authState.subscribe(user => {
      this.isAuthorized = user !== null;
    });
  }

}
