<div class="d-sm-inline d-md-inline d-lg-none">
  <mat-toolbar class="mat-toolbar-table" style="padding-right:16px;">
    <!--<header class="navbar" [ngClass]="{'navbar-fixed': isFixedNavbar}">
    <div class="container" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="navbar-mobile-top" fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="space-around center">
        <div class="navbar-logo">
          <a href="/" class="logo-table">
            <img src="/images/logo.svg" alt="logo" style="width: 14em;" />
          </a>
          <span class="toolbar-spacer"></span>
        </div>



        <div fxHide.xs fxLayout="row" fxLayoutAlign="" style="z-index:100;">
          <button mat-mini-fab color="secondary" class="mat-body-1 mx-2 mx-lg-4 btn-primary-round" style="font-size:1.5em;" [matMenuTriggerFor]="langMenu">{{currentLang | shortLang}}</button>
          <button *ngIf="!authService.isAuthenticated || !authService.user" class="mat-body-1 b-primary" style="font-size:1.5em;" (click)="login()" color="secondary" mat-button>{{'Registration_login' | translate}}</button>


          <div *ngIf="authService.isAuthenticated && authService.user" fxLayout="row" fxLayoutAlign="end center">
            <div class="align-content-end text-right">
              <div class="name mat-body-2 ml-1">{{ firstName }}</div>
              <div class="name mat-body-2 ml-1">{{ lastName }}</div>
            </div>
            <button mat-mini-fab color="secondary" class="mat-body-1 ml-1 btn-primary-round" [matMenuTriggerFor]="contactMenu">
              <img src="/images/user-profile.svg" alt="userProfile" style="width: 24px; height: 24px;" />
            </button>
          </div>
        </div>

        <div fxHide.gt-xs fxLayout="row" dxLayoutAlign="" style="z-index:100;">
          <button mat-mini-fab color="secondary" class="mat-body-1 mx-2 mx-lg-4 btn-primary-round" style="font-size:1.5em;" [matMenuTriggerFor]="langMenu">{{currentLang | shortLang}}</button>
          <button *ngIf="!authService.isAuthenticated || !authService.user" class="mat-body-1 b-primary" style="font-size:1.5em;" (click)="login()" color="secondary" mat-button>{{'Login' | translate}}</button>
          <button *ngIf="authService.isAuthenticated && authService.user" class="mat-body-1 ml-1 btn-primary-round" [matMenuTriggerFor]="contactMenu">
            <img src="/images/user-profile.svg" alt="userProfile" style="width: 24px; height: 24px;" />
          </button>

        </div>
        <button mat-icon-button color="accent" class="navbar-toggle" (click)="toggleNavbar()">
          <mat-icon>{{!navbarOpened ? 'menu': 'close'}}</mat-icon>
        </button>
      </div>
      <div fxFlex class="top-navbar">
        <span fxFlex></span>
        <a mat-button class="navbartop-btn"><strong>About Us</strong></a>
        <a mat-button class="navbartop-btn"><strong>Services</strong></a>
        <a mat-button class="navbartop-btn"><strong>Testimonials</strong></a>
        <a mat-button class="navbartop-btn"><strong>Contact</strong></a>
      </div>
    </div>
  </header>-->
    <a href="/" class="logo-table" style="z-index:1;">
      <img src="/images/logo.svg" alt="logo" class="logo-mobile" style="" />
    </a>

    <span class="toolbar-spacer"></span>

    
    <div fxShow.gt-md="false" fxShow="true" fxLayout="row" dxLayoutAlign="end center" style="z-index:100;">
      <button *ngIf="currentCountry === 'Казахстан' || currentCountry === 'Россия'" mat-button [matMenuTriggerFor]="menu" style="font-size: 20px; padding-left: 10px; padding-right: 5px;"><img class="country-geo-img-mobile" src="/images/countryGeo.svg" alt="countryGeo" />{{currentCountry}}</button>
      <button *ngIf="currentCountry === 'Другая'" mat-button [matMenuTriggerFor]="menu">
        <img class="country-geo-img-mobile-anotherCountry " src="/images/countryGeo.svg" alt="countryGeo" /><span style="font-size: 20px;">{{currentCountry}}</span>
      </button>


      <button mat-mini-fab color="secondary" class="mat-body-1 mx-2 mx-lg-4 btn-primary-round" style="font-size:1.5em;" [matMenuTriggerFor]="langMenu">{{currentLang | shortLang}}</button>
      <button *ngIf="!authService.isAuthenticated || !authService.user" class="mat-body-1 b-primary" style="font-size:2.8em;" (click)="login()" color="secondary" mat-button>{{'Login' | translate}}</button>
      <button *ngIf="authService.isAuthenticated && authService.user" class="mat-body-1 ml-1 btn-primary-round icon-mobile" [matMenuTriggerFor]="contactMenu">
        <img src="/images/user-profile.svg" alt="userProfile" style="width: 24px; height: 24px;" />
      </button>
      <button mat-button style="padding-left: 5px;" (click)="onSidenavToggle()"><mat-icon style="color: #2E5B5F">menu</mat-icon></button>
    </div>





  </mat-toolbar>
</div>




<div class="d-none d-lg-inline">
  <mat-toolbar class="" style="position: absolute;">
    <a href="/" class="logo">
      <img src="/images/logo.svg" alt="logo" style="width: 8em;" />
    </a>

    <span class="toolbar-spacer"></span>


    <ul fxShow="true" id="menu">
      <li data-menuanchor="courses" class="mx-auto mx-lg-4">
        <a class="mat-body-1" href="/#courses">{{'Courses' | translate}}</a>
      </li>
      <li data-menuanchor="education" class="mx-auto mx-lg-4">
        <a class="mat-body-1" href="/#education">{{'Education_process' | translate}}</a>
      </li>
      <li data-menuanchor="comments" class="mx-auto mx-lg-4">
        <a class="mat-body-1" href="/#comments">{{'Comments' | translate}}</a>
      </li>
      <li data-menuanchor="about" class="mx-auto mx-lg-4">
        <a class="mat-body-1" href="/#about">{{'About_us' | translate}}</a>
      </li>

    </ul>

    <!--<select class="form-select">
    <option selected>{{selected}}</option>
    <option value="KZ" style="background: url(/images/Kazakhstan.svg) no-repeat; background-size: 20px "></option>
    <option value="RU" style="background: url(/images/Russia.svg) no-repeat; background-size: 20px "></option>
  </select>-->

    <button *ngIf="currentCountry === 'Казахстан'" mat-button [matMenuTriggerFor]="menu"><img class="country-geo-img-kz" src="/images/countryGeo.svg" alt="countryGeo" />{{currentCountry}}</button>
    <button *ngIf="currentCountry === 'Россия'" mat-button [matMenuTriggerFor]="menu"><img class="country-geo-img" src="/images/countryGeo.svg" alt="countryGeo" />{{currentCountry}}</button>
    <button *ngIf="currentCountry === 'Другая'" mat-button [matMenuTriggerFor]="menu"><img class="country-geo-img-anotherCountry" src="/images/countryGeo.svg" alt="countryGeo" />{{currentCountry}}</button>



    <div fxShow="true" fxLayout="row" fxLayoutAlign="end center">
      <button mat-mini-fab color="secondary" class="mat-body-1 mx-2 mx-lg-4 btn-primary-round" [matMenuTriggerFor]="langMenu">{{currentLang | shortLang}}</button>
      <button *ngIf="!authService.isAuthenticated || !authService.user" class="mat-body-1 b-primary" (click)="login()" color="secondary" mat-button>{{'Registration_login' | translate}}</button>


      <div *ngIf="authService.isAuthenticated && authService.user" fxLayout="row" fxLayoutAlign="end center">
        <div class="align-content-end text-right">
          <div class="name mat-body-2 ml-1">{{ firstName }}</div>
          <div class="name mat-body-2 ml-1">{{ lastName }}</div>
        </div>
        <button mat-mini-fab color="secondary" class="mat-body-1 ml-1 btn-primary-round" [matMenuTriggerFor]="contactMenu">
          <img src="/images/user-profile.svg" alt="userProfile" style="width: 24px; height: 24px;" />
        </button>
      </div>
    </div>
  </mat-toolbar>
</div>

<mat-menu #menu="matMenu">
  <button (click)="onClick(option)" *ngFor="let option of options" mat-menu-item>
    
    <span class="language-mobile">{{option}}</span>
  </button>
  <!--<button mat-menu-item value="1"><span class="language-mobile">Россия</span></button>
  <button mat-menu-item value="2"><span class="language-mobile">Казахстан</span></button>-->
</mat-menu>

<mat-menu #langMenu="matMenu" class="">
  <button mat-menu-item *ngFor="let language of langs"
          (click)="switchLang(language)">
    <span class="language-mobile">{{language | translate}}</span>
  </button>
</mat-menu>



<mat-menu #contactMenu="matMenu">
    <button mat-menu-item
            (click)="cabinet()">
        <span class="language-mobile">{{'cabinet' | translate}}</span>
    </button>
    <button mat-menu-item
            (click)="logout()">
        <span class="language-mobile">{{'logout' | translate}}</span>
    </button>
</mat-menu>
