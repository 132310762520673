import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizeService } from '../../../api-authorization/authorize.service';
import { Languages } from '../../app.component';

@Component({
  selector: 'app-foundation-en',
  templateUrl: './foundation-en.component.html',
  styleUrls: ['./foundation-en.component.css']
})
export class FoundationEnComponent implements OnInit {

  isAuthorized?: boolean = null;

    constructor(public readonly authService: AuthorizeService, private router: Router) {
    this.authService.afAuth.authState.subscribe(user => {
      this.isAuthorized = user !== null;
    });
  }

  ngOnInit(): void {
  }

    buyCourse(course: string) {
        this.router.navigate(['register', { course: course }]);
    }
}
