import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'messageDesc' })
export class MessageDescPipe implements PipeTransform {
    transform(description: string, isExpanded = false, animationDirection: boolean | null = null, length = 100) {
        if (description.length <= length) {
            return description;
        }

        if (animationDirection === false) {
            return description;
        }

        return isExpanded ? description : description.substr(0, length) + "...";
    }
}