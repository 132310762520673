import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {
    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return (String('0').repeat(2) + minutes).substr((2 * -1), 2) + ':' + (String('0').repeat(2) + (value - minutes * 60)).substr((2 * -1), 2);
    }

}
