<div *ngIf="isAuthorized != null">
<div class="row">
  <div class="col mb-4">
    <p class="course-text">
      PRINCE2® Foundation Online Training course will prepare you for the PRINCE2® Foundation Exam included on this course.
    </p>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <p class="mb-0 ptext">
      PRINCE2® is a best practice methodology and by taking this course, you will learn to effectively structure and run a project from start to finish.
    </p>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <p class="mb-0 ptext">
      By the end of the course, you will be able to understand and apply the seven key principles, processes and themes involved in managing a project using the PRINCE2® methodology.
    </p>
  </div>
</div>
<div class="row mb-4">
  <div class="col">
    <p class="mb-0 ptext">
      The PRINCE2® Foundation Online Training course allows you to take part in distance learning at your own pace, adapting to your busy schedule and allow you to complete your exam when you feel ready.
    </p>
  </div>
</div>
<div class="row mb-4">
  <div class="col">
    <p class="mb-0 ptext">
      <strong>PRINCE2® Foundation Online Training course </strong>has a duration <strong>35 hours</strong>, however you will have <strong>an access to the course</strong> for <strong> 2 months </strong>from the day of booking.
    </p>
  </div>
</div>
</div>
<div class="row mc-7" *ngIf="isAuthorized === true">
  <div class="col ">
    <p class="course-text-700 mb-3">The Course Outline</p>
  </div>
  <div class="row mc-7">
    <div class="col" style="padding-left:30px;">
      <p class="mb-2 ptext">PRINCE2® Foundation Online Training course covers the following topics:</p>
      <ul class="" style="padding-left: 20px;">
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          <strong>PRINCE2® Principles:</strong> PRINCE2® Project Management follows a set of seven principles that can all be applied to any project. These seven principles create the framework PRINCE2® is based on.
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          <strong>PRINCE2® Processes:</strong> The seven PRINCE2® processes outline the tasks that are involved in the management and delivery of a successful project. These processes cover the entire timeline of a project, from pre-project to project closure.
        </li>
        <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
          <strong>PRINCE2® Themes:</strong> These seven themes must be addressed and followed continuously for the PRINCE2® project to be carried out effectively and efficiently.
        </li>
      </ul>
    </div>

  </div>
</div>

<div class="row mc-7" *ngIf="isAuthorized !== null">
  <div class="col ">
    <p class="course-text-700 mb-3">The Course Includes</p>
    <p class="mb-2 ptext">The PRINCE2® Foundation Online Training course has the following:</p>
    <ul class="" style="padding-left: 20px;">
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        <strong>24/7 access for 2 months </strong>to the online training course enabling you to study anytime, anywhere.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Interactive and <strong>engaging online training modules</strong>, designed based on time tested methodologies for effective learning.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Easy to use online interface with navigation videos and tools to assist you in every step of your learning experience.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Personal Performance Dashboard giving you a snapshot of your real time progress.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">Training Modules /<strong> Videos</strong>.</li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext"><strong>Module Assessments </strong> to test your subject knowledge at each stage.</li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext"><strong>Mock Examination </strong> -  timed and scored like your final certification examination to provide you with a similar experience.</li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Case Studies.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        <strong>Certification Exam.</strong>
      </li>
    </ul>
  </div>

</div>
<div class="row mt-3 " *ngIf="isAuthorized === false">
  <div class="col ">
    <p class="course-text-700 mb-3">Who is this course for?</p>
    <p class="mb-2 ptext">
      The PRINCE2® Foundation Online Training course is intended for those looking to work as a project management or as part of a project team.
    </p>
    <p class="mb-2 ptext">
      The PRINCE2® Foundation Online Training course is specifically intended for those with busy schedules that require the flexibility to study in their own time.

    </p>
    <p class="mb-2 mt-3 ptext">
      This Certification is most suited for:

    </p>
    <ul class="" style="padding-left: 20px;margin-bottom: 30px;
">
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Existing project managers.

      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Any professional looking to become a project manager.

      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Project Executives or Engineers.

      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Associate or Assistant Managers that have a role in working on projects.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Software Developers.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">Team Leaders or Managers.</li>
    </ul>
    <p class="mb-2 ptext">
      By choosing  <strong>online learning </strong> instead of a classroom-based courses, it eliminates the need for time away from work,<strong> saving you valuable time </strong>and money. It is also ideal for those with tight schedules as our distance learning gives you<strong> 2 months access,</strong> meaning it can be picked up whenever you have the time. As all you need for this course is an internet connection, this course <strong>can be studied from home,</strong> meaning you can study in a more comfortable environment, also saving on travel costs.

    </p>
  </div>

</div>
<div class="row" *ngIf="isAuthorized === false">
  <div class="col">
    <p class="course-text-700 mb-3">Benefits</p>
    <p class="mb-2 ptext">
      As the standard method for project management, PRINCE2® will benefit your organization in multiple aspects. It provides a flexible approach that is highly adaptable and can suit all projects. It provides a framework that covers all areas within a project. It provides your organization with:
    </p>
    <ul class="" style="padding-left: 20px;margin-bottom: 30px;">
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Effective communication channels between the organization, project management and the project.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        An organized timescale to the project.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        A method of sharing lessons learned.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        An increase in competency of staff and project management skills.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Progress reviews against the plan and the business case.
      </li>
      <li style=" padding-top: 3px; padding-bottom: 3px;" class="ptext">
        Flexible decision points.
      </li>
    </ul>
  </div>
</div>
